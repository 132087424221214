<template>
	<v-container fluid class="admin-user-content">
		<v-layout row wrap>
			<v-flex xs12>
				<h1 class="title-layout">
					Submission status
				</h1>
				<div style="overflow-x: auto">
					<table v-if="statuses">
						<thead>
							<tr>
								<th class="company-header">
									Company
								</th>
								<th
									v-for="surveyTemplate in surveyTemplates"
									:key="surveyTemplate.name"
									:colspan="surveyTemplate.businessUnits.length"
									class="survey-template"
								>
									{{ surveyTemplate.name }}
								</th>
							</tr>
							<tr>
								<th class="company-header">
								</th>
								<template v-for="surveyTemplate in surveyTemplates">
									<th
										v-for="(businessUnit, i) in surveyTemplate.businessUnits"
										:key="`${surveyTemplate.name}-${businessUnit.name}`"
										:class="{ 'first-business-unit': i === 0 }"
										:style="{ color: getColor(i) }"
										class="business-unit"
									>
										<div>{{ businessUnit.name }}</div>
									</th>
								</template>
							</tr>
						</thead>
						<tbody style="height: calc(100vh - 200px); overflow-y: auto">
							<tr v-for="company in companies" :key="company">
								<th class="company-name">
									{{ company }}
								</th>
								<template v-for="surveyTemplate in surveyTemplates">
									<td
										v-for="(businessUnit, i) in surveyTemplate.businessUnits"
										:key="`${surveyTemplate.name}-${businessUnit.name}`"
										:class="{ 'first-business-unit': i === 0 }"
										:style="{ color: getColor(i) }"
										class="business-unit"
									>
										<div v-if="businessUnit.companies[company]">
											<span>{{ businessUnit.companies[company].numberOfSystems }}</span>
											<v-icon v-if="businessUnit.companies[company].submitted" :small="true">
												mdi-check-circle-outline
											</v-icon>
										</div>
									</td>
								</template>
							</tr>
						</tbody>
					</table>
				</div>
			</v-flex>
		</v-layout>
	</v-container>
</template>

<script>
import { mapState } from 'vuex'
import { groupBy, map, keyBy, sortBy, reverse } from 'lodash/fp'

export default {
	computed: {
		...mapState({
			statuses: state => state.surveys.submissionStatuses,
		}),
		surveyTemplates() {
			return reverse(sortBy('surveyTemplateCreatedAt')(map((statuses) => {
				return {
					name: statuses[0].surveyTemplateName,
					surveyTemplateCreatedAt: statuses[0].surveyTemplateCreatedAt,
					businessUnits: sortBy('name')(map((statuses2) => {
						return {
							name: statuses2[0].businessUnitName,
							companies: keyBy('companyName')(statuses2),
						}
					})(groupBy('businessUnitName')(statuses))),
				}
			})(groupBy('surveyTemplateName')(this.statuses))))
		},
		companies() {
			return sortBy((c) => c.toLowerCase())(Object.keys(keyBy('companyName')(this.statuses)))
		},
	},
	methods: {
		getColor(i) {
			const colors = ['black', 'green', 'orange', 'olive', 'maroon', 'crimson', 'navy', 'purple', 'teal', 'fuchsia']
			return colors[i % colors.length];
		},
	},
	created() {
		this.$store.dispatch('surveys/getSubmissionStatuses')
	},
}
</script>

<style scoped>
.business-unit div {
	width: 50px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
table tr th:first-child, table tr td:first-child, .survey-template, .first-business-unit {
	border-left: 1px solid grey;
}
table tr th:last-child, table tr td:last-child, .survey-template {
	border-right: 1px solid grey;
}
table {
	border-spacing: 0;
	border-collapse: collapse;
}
table th, table td {
	padding: 0.2em 0.5em;
}
table td i {
	position: relative;
	top: 0px;
	margin-left: 5px;
}
thead tr:last-child th, tbody tr:last-child td, tbody tr:last-child th {
	border-bottom: 1px solid grey;
}
thead tr:first-child th {
	border-top: 1px solid grey;
}
.company-header, .company-name {
	white-space: nowrap;
	position: -webkit-sticky; /* For Safari */
	position: sticky;
	background-color: white;
	left: 0;
	z-index: 1;
}
</style>
