import gql from 'graphql-tag'
import { groupBy, keyBy, mapValues, pick, sortBy, zip } from 'lodash/fp'
import graphqlClient, { removeTypeNames } from '../../../api/db'

const KEY_FIELD_NAME = 'System name'

const collectLeaves = (ontologyItems, path, leaves) => {
  ontologyItems.splice(
    0,
    ontologyItems.length,
    ...sortBy('name', ontologyItems),
  )
  ontologyItems.forEach((ontologyItem) => {
    ontologyItem.path = path.concat([ontologyItem])
    ontologyItem.fullName = ontologyItem.path
      .slice(1)
      .map((item) => item.name)
      .join(' / ')
    if (ontologyItem.children) {
      collectLeaves(ontologyItem.children, path.concat([ontologyItem]), leaves)
    } else {
      leaves.push(ontologyItem)
    }
  })
}

const processOntology = (ontologyItems) => {
  const activeOntologyItems = ontologyItems.filter((item) => item.active)
  const index = keyBy('processId', activeOntologyItems)
  const roots = []
  const leaves = []

  activeOntologyItems.forEach((ontologyItem) => {
    if (ontologyItem.parentId) {
      ontologyItem.parent = index[ontologyItem.parentId]

      if (ontologyItem.parent) {
        ontologyItem.parent.children = ontologyItem.parent.children || []
        ontologyItem.parent.children.push(ontologyItem)
      }
    } else {
      roots.push(ontologyItem)
    }
  })
  collectLeaves(roots, [], leaves)

  const depth = Math.max.apply(
    null,
    leaves.map((i) => i.path.length),
  )

  return {
    roots,
    index,
    leaves: keyBy('processId', leaves),
    leavesByBusinessUnitId: groupBy((leave) => leave.path[0].processId, leaves),
    depth,
    allOntologyItems: activeOntologyItems,
  }
}

const isBlank = (value) => {
  return value === null || value === undefined || value.match(/^\s*$/)
}

const validateSurveyItem = (surveyItem, fieldTemplates) => {
  surveyItem.validValue = {}
  surveyItem.valid = fieldTemplates.reduce(
    (allValid, { isMandatory, fieldName }) => {
      const valid = isMandatory ? !isBlank(surveyItem.values[fieldName]) : true

      surveyItem.validValue[fieldName] = valid

      return allValid && valid && surveyItem.ontologyItemProcessId != null
    },
    true,
  )
}

// Initial state
const initialState = {
  all: [],
  ontology: {},
  surveyTemplate: null,
  survey: null,
  toUpdate: [],
  toDelete: [],
  toCreate: [],
  saving: false,
  savingSubmittedStatus: false,
  currentTaxonomyVersionId: null,
}

// Getters
const getters = {
  nothingToSave(state) {
    return (
      state.toCreate.length === 0 &&
      state.toUpdate.length === 0 &&
      state.toDelete.length === 0
    )
  },
  cantSave(state, boundGetters) {
    return (
      state.surveyTemplate &&
      (boundGetters.nothingToSave ||
        state.surveyTemplate.status !== 'started' ||
        state.saving ||
        state.savingSubmittedStatus)
    )
  },
  cantChangeSubmittedStatus(state) {
    return (
      state.surveyTemplate &&
      (state.surveyTemplate.status !== 'started' ||
        state.saving ||
        state.savingSubmittedStatus)
    )
  },
  isReadOnly(state) {
    return Boolean(
      state.surveyTemplate &&
        (state.survey.hasBeenSubmitted ||
          state.surveyTemplate.status !== 'started' ||
          state.isSaving ||
          state.isSavingSubmittedStatus),
    )
  },
  startedSurveys(state) {
    if (state.all.length > 0) {
      return state.all.filter((item) => item.status === 'started')
    } else {
      return []
    }
  },
  closedSurveys(state) {
    if (state.all.length > 0) {
      return state.all.filter((item) => item.status === 'closed')
    } else {
      return []
    }
  },
}

// Actions
const actions = {
  async getAllSurveys({ commit }, { surveyDomainId }) {
    commit('setSurveys', [])
    commit('setOntology', {})

    const response = await graphqlClient.query({
      query: gql`
        query MySurveys($surveyDomainId: ID) {
          surveyTemplates(onlyOpen: true, excludeStarted: false) {
            id
            name
            status
            isDraft
            createdAt
            rootOntologyItems {
              name
              processId
            }
          }
          ontologyItems(surveyDomainId: $surveyDomainId) {
            processId
            parentId
            name
            terms
            active
            description
          }
        }
      `,
      variables: {
        surveyDomainId,
      },
    })

    commit('setSurveys', response.data.surveyTemplates)
    commit('setOntology', processOntology(response.data.ontologyItems))
  },
  async getClosedSurveys({ commit }) {
    commit('setClosedSurveys', [])

    const response = await graphqlClient.query({
      query: gql`
        query MySurveys {
          currentUser {
            companyId
          }
          surveyTemplates(onlyOpen: true, excludeStarted: true) {
            id
            name
            status
            isDraft
            createdAt
            rootOntologyItems {
              name
              processId
            }
            mySurveys {
              businessUnitId
              hasBeenSubmitted
            }
          }
        }
      `,
    })

    commit('setClosedSurveys', response.data.surveyTemplates)
  },
  async loadTaxonomyVersionForSurvey({ commit }, { id }) {
    commit('setCurrentTaxonomyVersion', null)

    const response = await graphqlClient.query({
      query: gql`
        query TaxonomyVersionFromSurveyId($id: ID!) {
          taxonomyVersionFromSurveyId(id: $id) {
            id
          }
        }
      `,
      variables: {
        id,
      },
    })

    commit(
      'setCurrentTaxonomyVersion',
      response.data.taxonomyVersionFromSurveyId.id,
    )
  },
  // eslint-disable-next-line max-statements
  async loadSurvey({ commit }, { id, businessUnitId, taxonomyVersionId }) {
    commit('resetCurrentSurvey')

    const response = await graphqlClient.query({
      query: gql`
        query MySurvey($id: String!, $taxonomyVersionId: ID) {
          ontologyItems(taxonomyVersionId: $taxonomyVersionId) {
            processId
            parentId
            name
            terms
            active
            description
          }
          surveyTemplate(id: $id) {
            id
            name
            status
            taxonomyDepth
            fieldTemplates {
              fieldName
              isStrict
              isFreeText
              isMandatory
              allowedValues
              currentValues
            }
            mySurveys(autoCreate: true, taxonomyVersionId: $taxonomyVersionId) {
              businessUnitId
              hasBeenSubmitted
              activeUsers {
                firstName
                lastName
                email
              }
              surveyItems {
                id
                ontologyItemProcessId
                businessUnitId
                surveyItemValues {
                  value
                  fieldName
                }
              }
            }
          }
        }
      `,
      variables: {
        id,
        taxonomyVersionId,
      },
    })

    const surveyTemplate = response.data.surveyTemplate
    const survey = surveyTemplate.mySurveys.find(
      (mySurvey) => mySurvey.businessUnitId === businessUnitId,
    )
    const ontology = processOntology(response.data.ontologyItems)
    const readOnlySurveys = surveyTemplate.mySurveys.filter(
      (mySurvey) => mySurvey.businessUnitId !== businessUnitId,
    )

    survey.surveyItems = readOnlySurveys.reduce(
      (list, readOnlySurvey) =>
        list.concat(
          readOnlySurvey.surveyItems.map((surveyItem) => ({
            ...surveyItem,
            readOnly: true,
          })),
        ),
      survey.surveyItems,
    )
    ontology.businessUnit = ontology.roots.filter(
      (item) => item.processId === businessUnitId,
    )[0]
    ontology.ontologyItems = ontology.leavesByBusinessUnitId[businessUnitId]

    // Labels are identical to fieldnames now. What you see is what you get.
    surveyTemplate.fieldTemplates.forEach((f) => {
      // f.label = f.fieldName.replace(/[A-Z]/g, (c) => ` ${c.toLowerCase()}`).replace(/^[a-z]/, (c) => c.toUpperCase())
      f.label = f.fieldName
    })

    Object.assign(surveyTemplate, {
      keyField: surveyTemplate.fieldTemplates.filter(
        (f) => f.fieldName === KEY_FIELD_NAME,
      )[0],
      fields: surveyTemplate.fieldTemplates.filter(
        (f) => f.fieldName !== KEY_FIELD_NAME,
      ),
    })
    survey.surveyItems.forEach((item) => {
      item.values = mapValues(
        'value',
        keyBy('fieldName', item.surveyItemValues),
      )
      item.ontologyItem = ontology.index[item.ontologyItemProcessId]

      validateSurveyItem(item, surveyTemplate.fieldTemplates)
    })

    commit('setOntology', ontology)
    commit('setCurrentSurvey', { surveyTemplate, survey })
  },

  addSurveyItem({ commit, state }, { name, ontologyItem, businessUnitId }) {
    const surveyItem = {
      ontologyItemProcessId: ontologyItem ? ontologyItem.processId : null,
      businessUnitId,
      ontologyItem,
      surveyTemplateId: state.surveyTemplate.id,
      surveyItemValues: [
        {
          fieldName: state.surveyTemplate.keyField.fieldName,
          value: name,
        },
      ].concat(
        state.surveyTemplate.fields.map((field) => ({
          fieldName: field.fieldName,
          value: '',
        })),
      ),
    }

    surveyItem.values = mapValues(
      'value',
      keyBy('fieldName', surveyItem.surveyItemValues),
    )
    validateSurveyItem(surveyItem, state.surveyTemplate.fieldTemplates)

    commit('addSurveyItems', [surveyItem])
  },

  importSurveyItems({ commit, state }, surveyItems) {
    surveyItems.forEach((surveyItem) => {
      validateSurveyItem(surveyItem, state.surveyTemplate.fieldTemplates)
    })

    commit('deleteAllSurveyItems')
    commit('addSurveyItems', surveyItems)
  },

  deleteSurveyItem({ commit }, surveyItem) {
    commit('deleteSurveyItem', surveyItem)
  },

  changeValue({ commit }, { surveyItem, field, value }) {
    commit('changeValue', { surveyItem, field, value })
  },

  changeOntologyItem({ commit }, { surveyItem, ontologyItem, businessUnitId }) {
    console.log('userSurveys/changeOntologyItem', {
      surveyItem,
      ontologyItem,
      businessUnitId,
    })
    const update = {
      ontologyItemProcessId: ontologyItem ? ontologyItem.processId : null,
      businessUnitId,
      ontologyItem,
    }

    commit('updateSurveyItem', { surveyItem, update })
  },

  async saveSurvey({ state, commit }) {
    if (
      state.toCreate.length === 0 &&
      state.toUpdate.length === 0 &&
      state.toDelete.length === 0
    ) {
      return
    }

    commit('startSaving')

    const toCreate = state.toCreate.map((item) =>
      pick(
        [
          'surveyTemplateId',
          'ontologyItemProcessId',
          'businessUnitId',
          'surveyItemValues',
        ],
        item,
      ),
    )
    const toUpdate = state.toUpdate.map((item) =>
      pick(
        ['id', 'ontologyItemProcessId', 'businessUnitId', 'surveyItemValues'],
        item,
      ),
    )

    // Build the query conditional on whether creates, edits and/or deletes are needed

    // Step 1: Define the variables
    const mutationParts = []
    const mutationVariables = []
    const variables = {}

    // Step 2: Check for data and add parts conditionally
    if (toCreate.length > 0) {
      mutationVariables.push(`
        $toCreate: [SurveyItemCreateProps!]!
      `)
      mutationParts.push(`
        createSurveyItems(items: $toCreate) {
          id
        }
      `)
      variables.toCreate = removeTypeNames(toCreate)
    }
    if (toUpdate.length > 0) {
      mutationVariables.push(`
        $toUpdate: [SurveyItemUpdateProps!]!
      `)
      mutationParts.push(`
        updateSurveyItems(items: $toUpdate)
      `)
      variables.toUpdate = removeTypeNames(toUpdate)
    }
    if (state.toDelete.length > 0) {
      mutationVariables.push(`
        $toDelete: [String!]!
      `)
      mutationParts.push(`
        deleteSurveyItems(ids: $toDelete)
      `)
      variables.toDelete = state.toDelete
    }

    // Step 3: Construct the full query
    const fullQuery = `
      mutation SaveSurvey(
        ${mutationVariables.join('\n')}
      ) {
        ${mutationParts.join('\n')}
      }
    `

    // Step 5: Perform the query
    const response = await graphqlClient.mutate({
      mutation: gql`
        ${fullQuery}
      `,
      variables,
    })

    commit(
      'saveIds',
      zip(state.toCreate, response.data.createSurveyItems).map(
        ([item, { id }]) => ({
          item,
          id,
        }),
      ),
    )
    commit('clearChanges')
  },

  async setSubmitted({ state, commit, dispatch }, { survey, submitted }) {
    commit('setSubmissionStatus', { survey, submitted })
    commit('startChangingSubmittedStatus')

    await dispatch('saveSurvey')
    await graphqlClient.mutate({
      mutation: gql`
        mutation SaveSurvey($survey: SurveyUpdateProps!) {
          updateSurvey(props: $survey)
        }
      `,
      variables: {
        survey: Object.assign(
          { surveyTemplateId: state.surveyTemplate.id },
          pick(['businessUnitId', 'hasBeenSubmitted'], survey),
        ),
      },
    })

    commit('stopChangingSubmittedStatus')
  },
}

// Mutations
const mutations = {
  setSurveys(state, surveys) {
    state.all = surveys
  },
  setClosedSurveys(state, surveys) {
    state.closed = surveys
  },
  setOntology(state, ontology) {
    state.ontology = ontology
  },
  setCurrentTaxonomyVersion(state, taxonomyVersionId) {
    state.currentTaxonomyVersionId = taxonomyVersionId
  },
  setCurrentSurvey(state, { surveyTemplate, survey }) {
    state.surveyTemplate = surveyTemplate
    state.survey = survey
  },
  resetCurrentSurvey(state) {
    Object.assign(state, {
      ontology: {},
      surveyTemplate: null,
      survey: null,
      toUpdate: [],
      toDelete: [],
      toCreate: [],
      saving: false,
      savingSubmittedStatus: false,
      currentTaxonomyVersionId: null,
    })
  },
  addSurveyItems(state, surveyItems) {
    const businessUnitSurveyItems = state.survey.surveyItems.filter(
      (item) => item.businessUnitId === state.ontology.businessUnit.processId,
    )
    const otherSurveyItems = state.survey.surveyItems.filter(
      (item) => item.businessUnitId !== state.ontology.businessUnit.processId,
    )

    state.survey.surveyItems = businessUnitSurveyItems
      .concat(surveyItems)
      .concat(otherSurveyItems)
    state.toCreate = state.toCreate.concat(surveyItems)
  },
  updateSurveyItem(state, { surveyItem: surveyItemToUpdate, update }) {
    state.survey.surveyItems.forEach((surveyItem) => {
      if (
        surveyItem === surveyItemToUpdate &&
        surveyItem.ontologyItemProcessId !== update.ontologyItemProcessId
      ) {
        Object.assign(surveyItem, update)
        validateSurveyItem(surveyItem, state.surveyTemplate.fieldTemplates)

        if (surveyItem.id && state.toUpdate.indexOf(surveyItem) === -1) {
          state.toUpdate.push(surveyItem)
        }
      }
    })
  },
  deleteAllSurveyItems(state) {
    state.survey.surveyItems.forEach((surveyItem) => {
      if (surveyItem.id) {
        state.toDelete.push(surveyItem.id)
      } else {
        const createIndex = state.toCreate.indexOf(surveyItem)
  
        if (createIndex !== -1) {
          state.toCreate.splice(createIndex, 1)
        }
      }
    })
    state.survey.surveyItems = []
  },

  deleteSurveyItem(state, surveyItem) {
    const surveyItems = state.survey.surveyItems
    const index = surveyItems.indexOf(surveyItem)

    if (index !== -1) {
      surveyItems.splice(index, 1)
    }

    if (surveyItem.id) {
      state.toDelete.push(surveyItem.id)
    } else {
      const createIndex = state.toCreate.indexOf(surveyItem)

      if (createIndex !== -1) {
        state.toCreate.splice(createIndex, 1)
      }
    }
  },
  changeValue(state, { surveyItem, field, value }) {
    if (surveyItem.values[field.fieldName] !== value) {
      surveyItem.values[field.fieldName] = value
      surveyItem.surveyItemValues
        .filter((itemValue) => itemValue.fieldName === field.fieldName)
        .forEach((itemValue) => {
          itemValue.value = value
        })
      validateSurveyItem(surveyItem, state.surveyTemplate.fieldTemplates)

      if (surveyItem.id && state.toUpdate.indexOf(surveyItem) === -1) {
        state.toUpdate.push(surveyItem)
      }
    }
  },
  startSaving(state) {
    state.saving = true
  },
  saveIds(state, list) {
    list.forEach(({ item, id }) => {
      item.id = id
    })
  },
  clearChanges(state) {
    state.toUpdate = []
    state.toCreate = []
    state.toDelete = []
    state.saving = false
  },
  setSubmissionStatus(state, { survey, submitted }) {
    survey.hasBeenSubmitted = submitted
  },
  startChangingSubmittedStatus(state) {
    state.savingSubmittedStatus = true
  },
  stopChangingSubmittedStatus(state) {
    state.savingSubmittedStatus = false
  },
}

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
}
