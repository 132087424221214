// @ts-check

import * as CSV from 'csv-string'

/**
 * Convert the body of a CSV file to a 2D array of sanitized strings
 *
 * @param {string} body
 * @returns {string[][]}
 */
export function csvToData(body) {
  // Convert to 2D array
  const csv = CSV.parse(body)

  // Trim all cells
  for (const row of csv) {
    for (const [i, cell] of row.entries()) {
      row[i] = cell.trim()
    }
  }

  return csv
}

/**
 * Get the groups of filled in cells in a CSV row
 *
 * @param {string[]} row
 * @returns {{index: number; items: string[]}[]}
 */
export function getCsvRowGroups(row) {
  const groups = []

  let iStart = 0
  for (const [i, cell] of row.entries()) {
    if (!cell.length || i === row.length - 1) {
      if (i > iStart) {
        groups.push({
          index: iStart,
          items: row.slice(iStart, i),
        })
      } else if (i === row.length - 1 && cell.length) {
        groups.push({
          index: i,
          items: [cell],
        })
      }
      iStart = i + 1
    }
  }

  return groups
}

/**
 * Generate the body of a CSV file from a 2D array of data
 *
 * Don't forget to call window.URL.revokeObjectURL(url) afterwards
 *
 * @param {({toString(): string} | null)[][]} data
 * @returns {Blob} CSV body
 */
export function arrayToCsvBlob(data) {
  const body = data
    .map((row) =>
      row
        .map((v) => `"${v?.toString()?.replaceAll('"', '""') ?? ''}"`)
        .join(','),
    )
    .join('\n')
  return new Blob([body], { type: 'text/csv', endings: 'native' })
}

/**
 * Download a CSV file from a 2D array of data
 *
 * @param {string} filename
 * @param {({toString(): string} | null)[][]} data
 */
export function downloadCsv(filename, data) {
  const a = document.createElement('a')
  a.href = window.URL.createObjectURL(arrayToCsvBlob(data))
  a.download = filename
  a.click()
  a.remove()
  // window.URL.revokeObjectURL(a.href)
}
