import gql from 'graphql-tag'
import { omit } from 'lodash/fp'
import Vue from 'vue'
import { api } from '../../../api/api-service'
import graphqlClient, { removeTypeNames } from '../../../api/db'
import { createValidator } from '../../../api/validations'
import { createStoreUtils } from '../../../lib/vuex'

/**
 * @typedef {Store.Surveys.LocalState} State
 * @typedef {typeof getters} Getters
 * @typedef {typeof mutations} Mutations
 * @typedef {typeof actions} Actions
 */

/**
 * @template {any} Payload [Payload=void]
 * @typedef {MutationFor<State, Payload>} Mutate
 */

/**
 * @template {any} Payload [Payload=void]
 * @typedef {ActionFor<Mutations, State, Payload, void>} Act
 */

/** @type {State}  */
const initialState = {
  templates: [],
  currentTemplate: null,
  currentSurveyId: null,
  saving: false,
  surveyValidator: null,
  allowedValuesValidator: null,
  serverSurveyErrors: null,
  serverError: null,
  submissionStatuses: null,
  selectedSurveyDomain: {
    id: '5428d752-0770-49ad-a426-21b418202590',
    name: 'Software Survey',
    active: true,
  },
}

/** @satisfies {GettersFor<State>} */
const getters = {
  getCurrentSurvey: (state) =>
    state.templates.filter((s) => s.id === state.currentSurveyId)[0],
  surveyDomainId: (state) => state.selectedSurveyDomain.id,
}

/** @satisfies {ActionsFor<Mutations, State>} */
const actions = {
  /** @type {Act<void>} */
  async getAllSurveys({ commit }) {
    const { surveyTemplates, surveyTemplateValidations } =
      await api.getAllSurveys()
    commit('setSurveys', surveyTemplates)
    commit(
      'setSurveyValidator',
      createValidator(removeTypeNames(surveyTemplateValidations)),
    )
    commit(
      'setAllowedValuesValidator',
      createValidator(
        removeTypeNames(
          surveyTemplateValidations.Object.fieldTemplates.Array.Object
            .allowedValues,
        ),
      ),
    )
  },
  /** @type {Act<void>} */
  async getSurveysAndCompanies({ commit }) {
    const { surveyTemplates } = await api.getSurveysAndCompanies()
    // TODO: instead of replacing state - check into merging states (https://github.com/richardtallent/vue-object-merge)
    commit('setSurveys', surveyTemplates)
  },
  /** @type {Act<Model.Survey>} */
  async selectSurvey({ commit }, survey) {
    commit('selectSurvey', survey)
  },
  /** @type {Act<void>} */
  async newTemplate({ commit }) {
    commit('setCurrentTemplate', { name: '', fieldTemplates: [] })
  },
  /** @type {Act<unknown>} */
  async initFields({ commit }, template) {
    commit('initFields', template)
  },
  /** @type {Act<unknown>} */
  async addField({ commit }, payload) {
    commit('addField', payload)
  },
  /** @type {Act<unknown>} */
  async removeField({ commit }, payload) {
    commit('removeField', payload)
  },
  /** @type {Act<unknown>} */
  async updateFields({ commit }, payload) {
    commit('updateFields', payload)
  },
  /** @type {Act<{field: unknown, value: unknown}>} */
  async addValueToField({ commit }, { field, value }) {
    commit('addValueToField', { field, value })
  },
  /** @type {Act<unknown>} */
  async removeFieldValue({ commit }, { field, index }) {
    commit('removeFieldValue', { field, index })
  },
  /** @type {Act<unknown>} */
  async save({ commit }, vars) {
    commit('setSaving', true)
    commit('setServerError', false)
    commit('setServerSurveyErrors', null)

    const template = removeTypeNames({
      ...vars.template,
      fieldTemplates: vars.template.fieldTemplates.map((t) =>
        omit(['isNewField', 'isStandard', 'currentValues'], t),
      ),
    })

    return graphqlClient
      .mutate({
        mutation: gql`
          mutation CreateSurvey(
            $template: SurveyTemplateCreateProps!
            $fromDraft: Boolean!
          ) {
            createSurveyTemplate(props: $template, fromDraft: $fromDraft) {
              id
            }
          }
        `,
        variables: {
          template,
          fromDraft: vars.createFromDraftTaxonomy,
        },
      })
      .catch((e) => {
        if (e.graphQLErrors) {
          const error = e.graphQLErrors[0]

          if (error.validationErrors) {
            commit('setServerSurveyErrors', error.validationErrors)
          } else {
            commit('setServerError', true)
          }
        } else {
          commit('setServerError', true)
        }

        commit('setSaving', false)
        throw e
      })
      .then((response) => {
        commit('setSaving', false)
        commit('setServerError', false)
        commit('addSurvey', {
          id: response.data.createSurveyTemplate.id,
          ...surveyTemplate,
        })
      })
  },
  /** @type {Act<Model.SurveyTemplate>} */
  async openSurvey(_context, surveyTemplate) {
    surveyTemplate.status = 'started'

    await graphqlClient.mutate({
      mutation: gql`
        mutation UpdateSurvey($template: SurveyTemplateUpdateProps!) {
          updateSurveyTemplate(props: $template) {
            id
          }
        }
      `,
      variables: {
        template: {
          id: surveyTemplate.id,
          status: surveyTemplate.status,
        },
      },
    })
  },

  /** @type {Act<Model.SurveyTemplate>} */
  async closeSurvey(_context, surveyTemplate) {
    surveyTemplate.status = 'closed'

    await graphqlClient.mutate({
      mutation: gql`
        mutation UpdateSurvey($template: SurveyTemplateUpdateProps!) {
          updateSurveyTemplate(props: $template) {
            id
          }
        }
      `,
      variables: {
        template: {
          id: surveyTemplate.id,
          status: surveyTemplate.status,
        },
      },
    })
  },

  /** @type {Act<void>} */
  async getSubmissionStatuses({ commit }) {
    const submissionStatuses = await api.getSubmissionStatuses()

    commit('setSubmissionStatuses', submissionStatuses)
  },
}

/** @satisfies {MutationsFor<State>} */
const mutations = {
  /** @type {Mutate<Model.Survey[]>} */
  setSurveys(state, surveys) {
    state.templates = surveys
  },
  /** @type {Mutate<unknown>} */
  setSurveyValidator(state, validator) {
    state.surveyValidator = validator
  },
  /** @type {Mutate<unknown>} */
  setAllowedValuesValidator(state, validator) {
    state.allowedValuesValidator = validator
  },
  /** @type {Mutate<Model.Survey>} */
  selectSurvey(state, survey) {
    state.currentSurveyId = survey.id
  },
  /** @type {Mutate<Model.SurveyTemplate>} */
  setCurrentTemplate(state, template) {
    state.currentTemplate = template
  },
  /** @type {Mutate<Model.SurveyTemplate>} */
  initFields(state, template) {
    state.currentTemplate.fieldTemplates = template.fieldTemplates.map(
      (field) =>
        field.fieldName === 'System name'
          ? { ...field, isStandard: true }
          : field,
    )
    state.currentTemplate.parentId = template.id
  },
  /** @type {Mutate<Model.FieldTemplate[]>} */
  updateFields(state, updatedFields) {
    state.currentTemplate.fieldTemplates = updatedFields
  },
  /** @type {Mutate<Model.FieldTemplate>} */
  addField(state, field) {
    state.currentTemplate.fieldTemplates.push(field)
  },
  /** @type {Mutate<unknown>} */
  removeField(state, field) {
    const index = state.currentTemplate.fieldTemplates.indexOf(field)
    state.currentTemplate.fieldTemplates.splice(index, 1)
  },
  /** @type {Mutate<{field: unknown, value: unknown}>} */
  addValueToField(state, { field, value }) {
    const index = state.currentTemplate.fieldTemplates.indexOf(field)

    if (
      typeof state.currentTemplate.fieldTemplates[index].allowedValues !=
        'undefined' &&
      state.currentTemplate.fieldTemplates[index].allowedValues instanceof Array
    ) {
      state.currentTemplate.fieldTemplates[index].allowedValues.push(value)
    } else {
      Vue.set(state.currentTemplate.fieldTemplates[index], 'values', [value])
    }
  },
  /** @type {Mutate<{field: unknown, index: number}>} */
  removeFieldValue(state, { field, index }) {
    const fidx = state.currentTemplate.fieldTemplates.indexOf(field)

    state.currentTemplate.fieldTemplates[fidx].allowedValues.splice(index, 1)
  },
  /** @type {Mutate<Model.Survey>} */
  addSurvey(state, survey) {
    state.templates.push(survey)
    state.currentSurveyId = survey.id
  },
  /** @type {Mutate<boolean>} */
  setSaving(state, saving) {
    state.saving = saving
  },
  /** @type {Mutate<boolean>} */
  setServerError(state, error) {
    state.serverError = error
  },
  /** @type {Mutate<unknown[]>} */
  setServerSurveyErrors(state, errors) {
    state.serverSurveyErrors = errors
  },
  /** @type {Mutate<unknown[]>} */
  setSubmissionStatuses(state, submissionStatuses) {
    state.submissionStatuses = submissionStatuses
  },
}

export const surveysStore = {
  namespaced: true,
  ...createStoreUtils('surveys', {
    state: initialState,
    getters,
    actions,
    mutations,
  }),
}

export default surveysStore
