<template>
  <v-container fluid>
    <edit-ontology-item-dialog></edit-ontology-item-dialog>
    <download-taxonomy-version-dialog></download-taxonomy-version-dialog>
    <download-taxonomy-version-mapping-dialog></download-taxonomy-version-mapping-dialog>
    <create-new-taxonomy-version-dialog></create-new-taxonomy-version-dialog>
    <delete-taxonomy-version-dialog></delete-taxonomy-version-dialog>
    <accept-draft-taxonomy-version-dialog></accept-draft-taxonomy-version-dialog>

    <header class="header">
      <h1 class="title-layout header-title">Business Capability Taxonomy</h1>
      <div class="header-spacing"></div>
      <div class="header-version">
        <taxonomy-version-selector></taxonomy-version-selector>
      </div>
    </header>

    <div class="actions">
      <div class="actions-group">
        <v-btn
          class="button"
          @click="
            downloadTaxonomyVersion({
              taxonomyVersion: selectedTaxonomyVersion,
            })
          "
          :disabled="!selectedTaxonomyVersion"
        >
          Download taxonomy
        </v-btn>
        <v-btn
          class="button"
          @click="
            downloadTaxonomyVersionMapping({
              toTaxonomyVersion: selectedTaxonomyVersion,
            })
          "
          :disabled="!selectedTaxonomyVersion || !previousTaxonomyVersionId"
        >
          Download version mapping
        </v-btn>
      </div>
      <div class="actions-group">
        <v-btn
          class="button"
          @click="
            deleteTaxonomyVersion({ taxonomyVersion: selectedTaxonomyVersion })
          "
          :disabled="!canDeleteTaxonomyVersion"
        >
          Delete version
        </v-btn>
        <v-btn
          v-if="!canAcceptDraftTaxonomyVersion"
          class="button"
          @click="createNewTaxonomyVersion"
          :disabled="!taxonomyVersionsLoaded || currentTaxonomyVersionHasDraft"
        >
          Create new version
        </v-btn>
        <v-btn
          v-else="canAcceptDraftTaxonomyVersion"
          class="button"
          @click="acceptDraftTaxonomyVersion()"
          :disabled="!canDeleteTaxonomyVersion"
        >
          Accept draft version
        </v-btn>
      </div>
    </div>

    <section class="taxonomy-wrapper">
      <v-form class="search" @submit.prevent="search()">
        <div class="search-row">
          <v-text-field
            v-model="searchQuery"
            class="search-input"
            placeholder="Search for an item ..."
            @input="searchResults = []"
          />
          <v-btn class="button ontology-search-button" @click="search()">
            Search
          </v-btn>
        </div>
      </v-form>
      <div v-show="searchResults.length" class="ontology-search-results">
        <div class="ontology-search-results-list">
          <v-btn
            v-for="item in searchResults"
            :key="item.processId"
            class="ontology-search-result"
            @click="
              expandToItem(item, { scroll: true })
              closeSearch()
            "
          >
            {{ item.name }}
          </v-btn>
        </div>
      </div>

      <div class="taxonomy">
        <template v-for="level in levels">
          <div class="taxonomy-level">
            <header class="taxonomy-level-header">
              <h3>
                {{ level.title }}
              </h3>
            </header>
            <div class="taxonomy-level-body">
              <div class="taxonomy-level-items">
                <div
                  v-for="item in level.items"
                  :key="item.processId"
                  class="taxonomy-item"
                  v-bind:class="{
                    active: taxonomyPath[level.number] === item.processId,
                    inactive: !item.active || !level.active,
                  }"
                  :id="`taxonomy-item-id-${item.processId}`"
                  @click="expandToItem(item, { scroll: false })"
                >
                  <div class="taxonomy-item-label">
                    <span class="taxonomy-item-name">{{ item.name }}</span>
                    <span class="taxonomy-item-child-count">
                      ({{ item.totalSurveyItemCount }})
                    </span>
                  </div>
                  <div class="taxonomy-item-actions">
                    <v-icon
                      class="taxonomy-item-action"
                      @click.stop="editOntologyItem({ ontologyItem: item })"
                    >
                      mdi-pencil
                    </v-icon>
                  </div>
                </div>
              </div>
              <div class="taxonomy-level-actions">
                <v-form
                  class="ontology-add-option"
                  @submit.prevent="addItem(level.number)"
                >
                  <v-text-field
                    v-model="addItemForms[level.number]"
                    :disabled="level.number > taxonomyPath.length"
                    :error-messages="
                      addItemForms[level.number]
                        ? addItemFormErrors[level.number]
                        : []
                    "
                  />
                  <v-btn
                    :disabled="
                      level.number > taxonomyPath.length ||
                      !addItemForms[level.number] ||
                      addItemFormErrors[level.number]?.length > 0
                    "
                    class="button"
                    @click="addItem(level.number)"
                  >
                    Add
                  </v-btn>
                </v-form>
              </div>
            </div>
          </div>
        </template>
      </div>
    </section>
  </v-container>
</template>

<script>
import { lookupError } from '../../api/validations'
import { acceptDraftTaxonomyVersionStore } from '../../store/modules/admin/dialog/accept-draft-taxonomy-version'
import { createNewTaxonomyVersionStore } from '../../store/modules/admin/dialog/create-new-taxonomy-version'
import { deleteTaxonomyVersionStore } from '../../store/modules/admin/dialog/delete-taxonomy-version'
import { downloadTaxonomyVersionStore } from '../../store/modules/admin/dialog/download-taxonomy-version'
import { downloadTaxonomyVersionMappingStore } from '../../store/modules/admin/dialog/download-taxonomy-version-mapping'
import ontologyStore from '../../store/modules/admin/ontology'
import surveyDomainStore from '../../store/modules/surveydomain'
import TaxonomyVersionSelector from './components/TaxonomyVersionSelector.vue'
import AcceptDraftTaxonomyVersionDialog from './dialogs/AcceptDraftTaxonomyVersionDialog.vue'
import CreateNewTaxonomyVersionDialog from './dialogs/CreateNewTaxonomyVersionDialog.vue'
import DeleteTaxonomyVersionDialog from './dialogs/DeleteTaxonomyVersionDialog.vue'
import DownloadTaxonomyVersionDialog from './dialogs/DownloadTaxonomyVersionDialog.vue'
import DownloadTaxonomyVersionMappingDialog from './dialogs/DownloadTaxonomyVersionMappingDialog.vue'
import EditOntologyItemDialog from './dialogs/EditOntologyItemDialog.vue'

export default {
  name: 'AdminOntology',
  components: {
    DownloadTaxonomyVersionDialog,
    DownloadTaxonomyVersionMappingDialog,
    CreateNewTaxonomyVersionDialog,
    DeleteTaxonomyVersionDialog,
    EditOntologyItemDialog,
    TaxonomyVersionSelector,
    AcceptDraftTaxonomyVersionDialog,
  },
  data() {
    return {
      /** @type {number[]} ProcessId */
      taxonomyPath: [],
      /**	@type {string} */
      searchQuery: '',
      /** @type {Model.OntologyItem[]} */
      searchResults: [],
      /** @type {boolean} */
      showDialog: false,
      /** @type {Record<number, string>} ontologyItem.name */
      addItemForms: {}, // object with level number indices
      // downloadUrl: buildUrl('/ontology'),
    }
  },
  computed: {
    ...ontologyStore.mapState({
      ontologyItems: (state) => state.all,
      ontologyItemsByProcessId: (state) => state.allByProcessId,
      ontologyItemValidator: (state) => state.ontologyItemValidator,
    }),
    ...surveyDomainStore.mapState({
      surveyDomains: (state) => state.all,
    }),
    ...ontologyStore.mapGetters({
      rootOntologyItems: 'rootOntologyItems',
      selectedTaxonomyVersion: 'selectedTaxonomyVersion',
      taxonomyVersionsLoaded: 'taxonomyVersionsLoaded',
      currentTaxonomyVersionHasDraft: 'currentTaxonomyVersionHasDraft',
    }),
    ...downloadTaxonomyVersionMappingStore.mapGetters({
      previousTaxonomyVersionId: 'previousTaxonomyVersionId',
    }),
    ...deleteTaxonomyVersionStore.mapGetters({
      canDeleteTaxonomyVersion: 'canDeleteBasedOnCache',
    }),
    ...acceptDraftTaxonomyVersionStore.mapGetters({
      canAcceptDraftTaxonomyVersion: 'canSubmit',
    }),
    levels() {
      return Array.from({
        length: this.selectedTaxonomyVersion?.depth ?? 0,
      }).map((_, i) => ({
        number: i,
        title: i === 0 ? 'Business capability level 1' : `Business capability level ${i + 1}`,
        items: this.taxonomyLevelItems(i),
        active: this.taxonomyLevelIsActive(i),
      }))
    },
    addItemFormErrors() {
      return Object.fromEntries(
        Object.entries(this.addItemForms).map(([level, name]) => [
          level,
          lookupError(this.ontologyItemValidator({ name }, false), 'name'),
        ]),
      )
    },
  },
  created() {
    this.$store.dispatch('surveyDomain/loadSurveyDomains').then(() => {
      this.$store.dispatch('ontology/loadOntology', {
        surveyDomainId: this.surveyDomains[0].id,
        taxonomyVersionId: this.selectedTaxonomyVersion?.id,
      })
    })
  },

  methods: {
    ...ontologyStore.mapActions({
      createOntologyItem: 'createOntologyItem',
      editOntologyItem: 'openEditOntologyItemDialog',
    }),
    ...createNewTaxonomyVersionStore.mapActions({
      createNewTaxonomyVersion: 'openDialog',
    }),
    ...downloadTaxonomyVersionMappingStore.mapActions({
      downloadTaxonomyVersionMapping: 'openDialog',
    }),
    ...downloadTaxonomyVersionStore.mapActions({
      downloadTaxonomyVersion: 'openDialog',
    }),
    ...deleteTaxonomyVersionStore.mapActions({
      deleteTaxonomyVersion: 'openDialog',
    }),
    ...acceptDraftTaxonomyVersionStore.mapActions({
      acceptDraftTaxonomyVersion: 'openDialog',
    }),
    taxonomyLevelItems(level) {
      if (level === 0) {
        return this.rootOntologyItems
      } else {
        return (
          this.ontologyItemsByProcessId[this.taxonomyPath[level - 1]]
            ?.children ?? []
        )
      }
    },
    expandToItem(item, { scroll = false }) {
      // Highlight the item in the UI and expand levels accordingly
      this.taxonomyPath = [item.processId]
      let parent = item.parent
      while (parent) {
        this.taxonomyPath.unshift(parent.processId)
        parent = parent.parent
      }

      // Scroll into view
      if (scroll) {
        const elementId = `taxonomy-item-id-${item.processId}`
        setTimeout(() =>
          setTimeout(() => {
            console.log(
              'scrolling to',
              elementId,
              document.getElementById(elementId),
            )
            document.getElementById(elementId).scrollIntoView()
          }),
        )
      }
    },

    closeSearch() {
      this.searchResults = []
    },

    selectedTaxonomyItem(level) {
      return this.ontologyItemsByProcessId[this.taxonomyPath[level]] ?? null
    },

    taxonomyLevelIsActive(level) {
      let active = true
      while (level > 0) {
        level--
        const item = this.selectedTaxonomyItem(level)
        if (item && !item.active) {
          active = false
          break
        }
      }
      return active
    },

    search() {
      if (this.searchQuery.length < 1) {
        return this.closeSearch()
      }
      const qry = this.searchQuery.toLowerCase()
      this.searchResults = this.ontologyItems.filter(
        (item) =>
          item.name.toLowerCase().includes(qry) ||
          item.terms.some((term) => term.toLowerCase().includes(qry)),
      )
    },
    downloadTaxonomy() {
      this.showDownloadTaxonomyDialog = true
    },
    async addItem(level) {
      const newOntologyItems = await this.createOntologyItem({
        level,
        ontologyItem: {
          name: this.addItemForms[level],
          parentId: this.taxonomyPath[level - 1],
          terms: [],
        },
      })
      setTimeout(() => {
        const ontologyItem = newOntologyItems[0]
        this.expandToItem(ontologyItem, { scroll: true })
        this.editOntologyItem({ ontologyItem })
      })
      this.addItemForms[level] = ''
    },
  },
}
</script>
<style lang="scss">
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-items: center;
  flex-wrap: wrap;

  &-spacing {
    flex-grow: 99999;
  }

  &-title {
    flex-grow: 1;
  }

  &-version {
    flex-grow: 1;
    flex-basis: 250px;
  }
}

.actions {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;

  &-group {
    display: flex;
    flex-direction: column;
    flex-basis: 250px;
    flex-grow: 1;
    gap: 6px;
  }
}

.search {
  &-row {
    display: flex;
    flex-direction: column;
  }

  &-input {
    margin-bottom: -20px;
  }
}

.taxonomy {
  display: flex;
  margin-top: 20px;
  overflow-x: scroll;

  &-wrapper {
    position: relative;
    flex-direction: column;
    // height: calc(100vh - 30px - 24px); // 30px v-fluid padding; 24px footer
  }

  &-level {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 250px;

    &:not(:first-child) {
      // border-left: 2px solid rgba($blue, 0.4);
      // border-right: 2px solid transparent;
    }

    &-header {
      // top: 0px;
      // position: sticky;
      color: $blue;
      // padding: 0 20px;
      margin: 0;
      // text-align: center;
      margin-bottom: 10px;
    }

    &-body {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }

    &-items {
      flex-grow: 1;
      overflow-y: auto;
      height: 500px;
    }

    &-actions {
    }
  }

  &-item {
    display: flex;
    padding: 10px;
    cursor: pointer;
    align-items: center;
    color: rgba($blue, 0.4);

    &.active {
      color: $blue;
    }

    &:hover {
      background-color: rgba($blue, 0.05);

      & .taxonomy-item-actions {
        opacity: 1; // visibility has a lag?.. wth
      }
    }

    &.inactive {
      cursor: text;
      color: $blue;
    }

    & .inactive {
      text-decoration: line-through;
    }

    &-label {
      flex-grow: 1;
    }

    &-name {
    }

    &-child-count {
    }

    &-actions {
      opacity: 0;

      &:hover {
        background-color: rgba($blue, 0.05);
      }
    }

    &-action {
      display: flex;
      align-items: center;
      justify-content: center;
      justify-items: center;
      text-align: center;
      width: 30px;
      height: 30px;
      color: $blue;
    }
  }
}

.ontology {
  margin: 0 -20px;

  &-search {
    display: flex;
    justify-content: center;

    &-text-field {
      flex: none;
      width: 100%;
      max-width: 300px;
      margin-right: 20px;
    }

    &-button {
      margin-top: 9px !important;
    }

    &-results {
      &-list {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
      }
    }

    &-result {
      margin-right: 20px;
      margin-bottom: 10px;
      color: rgba($blue, 0.4);
      transition: color 0.3s ease-in-out;
      cursor: pointer;

      &:hover {
        color: $blue;
      }
    }
  }

  &-add-option {
    display: flex;
    padding-right: 20px;

    .button {
      margin-top: 9px !important;
      margin-left: 10px !important;
    }
  }
}
</style>
