<template>
	<v-container fluid class="login">
		<v-layout row wrap>
			<div class="login-content">
				<div class="login-logo" />
				<div v-if="error" class="login-check-token">
					<p :class="{'error-message': !!error}" class="login-check-token-message">
						Invalid token (e.g., expired)
					</p>
					<router-link to="/login" class="button-link">
						Back to Login
					</router-link>
				</div>
				<div v-else class="login-check-token">
					<v-progress-circular
						:size="70"
						:width="7"
						color="primary"
						indeterminate
						class="login-check-token-animation"
					/>
					<p class="login-check-token-message">
						Checking token...
					</p>
					<router-link to="/login" class="button-link">
						Cancel
					</router-link>
				</div>
			</div>
		</v-layout>
	</v-container>
</template>

<script>
import { mapActions } from 'vuex'

export default {
	props: {
		token: { type: String, required: true },
	},
	data: () => ({
		error: false,
	}),
	created() {
		this.doLoginByEmail(this.token).then((success) => {
			if (success) {
				this.$router.replace('/')
			} else {
				this.error = true
			}
		})
	},
	methods: {
		...mapActions('login', {
			doLoginByEmail: 'loginByEmail',
		}),
	},
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/pages/login';
</style>
