<template>
	<v-container fluid class="login">
		<v-layout row wrap>
			<div class="login-content">
				<div class="login-logo" />
				<v-text-field
					v-model="credentials.email"
					label="email"
					class="login-input-field"
					@keyup.enter.native="loginWithEmail"
				/>
				<v-progress-circular
					v-if="processing"
					:size="70"
					:width="7"
					color="primary"
					indeterminate
					class="login-loading"
				/>
				<v-alert
					v-model="loginEmailFailedWarning"
					class="login-warning"
					type="warning"
				>
					Invalid email
				</v-alert>
				<v-alert v-model="loginEmailAlert" class="login-warning" type="info">
					You have been sent an email
				</v-alert>
				<v-btn
					v-if="!processing"
					color="primary"
					dark
					class="mb-2 button"
					flat
					@click.native="loginWithEmail"
				>
					Log in
				</v-btn>
				<v-dialog
					v-model="loginWithPasswordDialog"
					max-width="500px"
					persistent
				>
					<v-btn slot="activator" color="primary" class="login-password-button">
						Unable to receive login email?
					</v-btn>
					<v-card>
						<v-card-title>
							<span class="headline">Log in with password</span>
						</v-card-title>
						<v-card-text>
							<span class="heading 6"><strong>! Only use this log in option when you are unable to receive
								emails from the application !</strong></span>
							<v-container grid-list-md>
								<v-layout wrap="">
									<v-flex xs12>
										<v-text-field v-model="credentials.email" label="email" />
										<v-text-field
											v-model="credentials.password"
											:append-icon="
												showPassword ? 'visibility_off' : 'visibility'
											"
											:type="showPassword ? 'text' : 'password'"
											label="password"
											@click:append="showPassword = !showPassword"
											@keyup.enter.native="loginWithPassword"
										/>
									</v-flex>
								</v-layout>
							</v-container>
							<span class="heading 6">Obtain a password by contacting support at
								<a href="prismesupport@xaop.com">prismesupport@xaop.com</a></span>
						</v-card-text>
						<v-card-actions>
							<v-spacer />
							<v-btn
								color="blue darken-1"
								flat
								@click.native="closeLoginWithPassword"
							>
								Cancel
							</v-btn>
							<v-btn
								color="blue darken-1"
								flat
								@click.native="loginWithPassword"
							>
								Log in
							</v-btn>
						</v-card-actions>
					</v-card>
					<v-alert v-model="loginPasswordFailedWarning" type="warning">
						Invalid username or password
					</v-alert>
				</v-dialog>
				<!-- <v-dialog v-model="loginWithEmailDialog" max-width="500px" style="margin-left: 20px; " persistent>
					<v-btn slot="activator" color="primary" dark class="mb-2 button">Log in by email</v-btn>
					<v-card>
						<v-card-title>
							<span class="headline">Log in by email</span>
						</v-card-title>
						<v-card-text>
							<v-container grid-list-md>
								<v-layout wrap="">
									<v-flex xs12>
										<v-text-field
											v-model="credentials.email"
											label="email"
											@keyup.enter.native = "loginWithEmail"
										/>
									</v-flex>
								</v-layout>
							</v-container>
						</v-card-text>
						<v-card-actions>
							<v-spacer/>
							<v-btn color="blue darken-1" flat @click.native="closeLoginWithEmail">Cancel</v-btn>
							<v-btn color="blue darken-1" flat @click.native="loginWithEmail">Log in</v-btn>
						</v-card-actions>
					</v-card>
					<v-alert
						v-model="loginEmailFailedWarning"
						type="warning"
					>
						Invalid email
					</v-alert>
				</v-dialog> -->
				<!-- <v-dialog v-model="resetPasswordDialog" max-width="500px" style="margin-left: 20px; " persistent>
					<v-btn slot="activator" color="primary" dark class="mb-2 button">Reset password</v-btn>
					<v-card>
						<v-card-title>
							<span class="headline">Reset password</span>
						</v-card-title>
						<v-card-text>
							<v-container grid-list-md>
								<v-layout wrap="">
									<v-flex xs12>
										<v-text-field
											v-model="credentials.email"
											label="email"
											@keyup.enter.native = "resetPassword"
										/>
									</v-flex>
								</v-layout>
							</v-container>
						</v-card-text>
						<v-card-actions>
							<v-spacer/>
							<v-btn color="blue darken-1" flat @click.native="closeResetPassword">Cancel</v-btn>
							<v-btn color="blue darken-1" flat @click.native="resetPassword">Email password reset</v-btn>
						</v-card-actions>
					</v-card>
					<v-alert
						v-model="resetPasswordFailedWarning"
						type="warning"
					>
						Invalid email
					</v-alert>
				</v-dialog> -->
				<!-- <v-alert
					v-model="loginEmailAlert"
					type="info"
				>
					You have been sent an email
				</v-alert> -->
			</div>
		</v-layout>
	</v-container>
</template>

<script>
import { mapActions } from 'vuex'

export default {
	name: 'LoginForm',
	data() {
		return {
			credentials: {},
			loginEmailFailedWarning: false,
			loginEmailAlert: false,
			loginPasswordFailedWarning: false,
			showPassword: false,
			loginWithPasswordDialog: false,

			// The processing state is used to prevent sending requests to the backend on consecutively clicking e.g. "login"
			processing: false,
		}
	},
	methods: {
		...mapActions('login', {
			doPasswordLogin: 'loginWithPassword',
			doSendLoginEmail: 'sendLoginEmail',
			doSendResetPassword: 'sendResetPassword',
		}),

		// closeResetPassword() {
		// 	this.resetPasswordDialog = false
		// 	this.resetPasswordFailedWarning = false
		// },

		closeLoginWithPassword() {
			this.loginWithPasswordDialog = false
			this.loginPasswordFailedWarning = false
		},

		loginWithPassword() {
			if (this.processing) {
				return
			}

			if (
				this.credentials.password === undefined
				|| this.credentials.email === undefined
			) {
				this.loginPasswordFailedWarning = true

				return
			}

			this.processing = true
			this.doPasswordLogin(this.credentials).then((success) => {
				if (success) {
					this.$router.replace('/')
					this.processing = false
				} else {
					this.loginPasswordFailedWarning = true
					this.processing = false
				}
			})
		},

		loginWithEmail() {
			if (this.processing) {
				return
			}

			if (
				this.credentials.email === undefined
				|| this.credentials.email === ''
			) {
				this.loginEmailFailedWarning = true

				return
			}

			this.loginEmailAlert = false
			this.loginEmailFailedWarning = false
			this.processing = true
			this.doSendLoginEmail(this.credentials.email.toLowerCase()).then(
				(success) => {
					this.processing = false

					if (success) {
						this.loginEmailAlert = true
					} else {
						this.loginEmailFailedWarning = true
					}
				},
			)
		},

		resetPassword() {
			if (this.processing) {
				return
			}

			if (this.credentials.email === undefined) {
				this.resetPasswordFailedWarning = true

				return
			}

			this.processing = true
			this.doSendResetPassword(this.credentials.email.toLowerCase()).then(
				(success) => {
					if (success) {
						this.loginEmailAlert = true
						this.resetPasswordDialog = false
						this.processing = false
					} else {
						this.resetPasswordFailedWarning = true
						this.processing = false
					}
				},
			)
		},
	},
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/pages/login';
</style>
