import gql from 'graphql-tag'

export const allFieldValidations = gql`
	fragment allFieldValidations on FieldValidation {
		length {
			min
			max
		}
		format {
			re
			mesg
			desc
		}
		unique {
			over
		}
		uniquenessConstraint {
			listKey
		}
		trim
		lowercase
	}
`
