var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bar-chart-bar-holder",style:({
		left: `${((_vm.index + 0.5) / _vm.count) * 100}%`,
		height: `calc(${(_vm.dataset.count / _vm.maxValue) * 100}% - ${
			(_vm.dataset.count / _vm.maxValue) * 10
		}px)`,
		width: `${80 / _vm.count}%`,
	}),on:{"mouseover":_vm.mouseover,"mouseout":_vm.mouseout}},[_c('div',{staticClass:"bar-chart-bar",class:_vm.highlightClass,style:({ background: _vm.color }),on:{"click":function($event){return _vm.$emit('click')}}}),_c('div',{staticClass:"bar-chart-bar-label",class:{ hideLabel: _vm.hideLabel, [_vm.highlightClass]: true }},[_vm._t("default",function(){return [_vm._v(" "+_vm._s(_vm.dataset.count)+" ")]},{"dataset":_vm.dataset})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }