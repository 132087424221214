// @ts-check

import { keyBy } from 'lodash'
import { csvToData, getCsvRowGroups } from './csv.js'

/**
 * Converts a flat list of ontology items into a tree structure and caches the
 * amount of survey items in its subtree.
 *
 * Fields are added in memory:
 *  - parent
 *  - children
 *  - totalSurveyItemCount
 *
 * @param {{byId: Record<string, Model.OntologyItem>}} options
 * @returns {}
 */
export function treeifyOntologyItems({ byId }) {
  for (const item of Object.values(byId)) {
    item.children = []
  }
  for (const item of Object.values(byId)) {
    item.totalSurveyItemCount = item.surveyItemCounts.reduce(
      (sum, counts) => sum + counts.count,
      0,
    )

    if (item.parentId) {
      let parent = byId[item.parentId]
      item.parent = parent
      parent.totalSurveyItemCount += item.totalSurveyItemCount
      parent.children.push(item)
      while (parent.parentId) {
        parent = byId[parent.parentId]
        parent.totalSurveyItemCount += item.totalSurveyItemCount
      }
    }
  }
}

/**
 * Attach survey templates to ontology item surveyItemCounts
 *
 * This adapts the ontology items in place, but returns with an altered typing.
 *
 * @param {{ontologyItems: Model.OntologyItem[]; surveyTemplates: Model.SurveyTemplate[]}} opts
 * @returns {Model.OntologyItem[]}
 */
export function attachSurveyTemplatesToOntologyItems({
  ontologyItems,
  surveyTemplates,
}) {
  const surveyTemplateById = keyBy(surveyTemplates, 'id')
  for (const item of ontologyItems) {
    for (const surveyItemCount of item.surveyItemCounts) {
      const surveyTemplate =
        surveyTemplateById[surveyItemCount.surveyTemplateId]
      if (surveyTemplate) {
        surveyItemCount.surveyTemplate = surveyTemplate
      } else {
        throw new Error(
          `Survey template ${surveyItemCount.surveyTemplateId} not found`,
        )
      }
    }
  }

  return ontologyItems
}

/**
 * Generates a unique identifier from a list of names.
 *
 * @param {string[]} lineage
 */
export function toLineageId(lineage) {
  return lineage.join('█')
}

/**
 * Updates the tree structure after adding a new ontology item.
 *
 * @param {Model.OntologyItem} item
 * @param {{byId: Record<string, Model.OntologyItem>}} options
 */
export function updateTreeForNewOntologyItem(item, { byId }) {
  // Assume totalSurveyItemCount is 0
  if (item.parentId) {
    const parent = byId[item.parentId]
    if (!parent.children) {
      parent.children = []
    }
    parent.children.push(item)
    item.parent = parent
  }
}

/**
 * Get the names of all ancestors of the given item.
 *
 * @param {Model.OntologyItem} item
 * @returns {string[]}
 */
export function getOntologyItemLineage(item) {
  const items = [item.name]
  while (item.parent) {
    item = item.parent
    items.unshift(item.name)
  }
  return items
}

/**
 * Validate a taxonomy version mapping CSV file and generate errors
 *
 * @param {string} body
 * @param {{ oldDepth: number; oldOntologyItemsById: Record<string, Model.OntologyItem> }} options
 * @returns {{feedback: TaxonomyVersionMappingFeedback[], mappings: TaxonomyVersionMapping[] | null}}
 */
export function parseVersionMappingCsv(
  body,
  { oldDepth, oldOntologyItemsById },
) {
  /** @type {TaxonomyVersionMappingFeedback[]} */
  const feedback = []
  /** @type {TaxonomyVersionMapping[]} */
  const mappings = []

  const result = () => ({
    feedback: feedback.sort((a, b) => (a.row ?? -1) - (b.row ?? -1)),
    mappings: feedback.some((error) => error.severity === 'error')
      ? null
      : mappings,
  })
  const hasErrors = () => feedback.some((error) => error.severity === 'error')

  try {
    var csv = csvToData(body)
  } catch (error) {
    console.error(error)
    feedback.push({
      row: null,
      message: `Failed to interpret file as CSV: ${error?.message ?? error}`,
      severity: 'error',
    })
    return result()
  }

  const [header, ...rows] = csv

  // DOCUMENT
  if (!csv.length) {
    feedback.push({
      row: null,
      message: 'No CSV rows to process',
      severity: 'error',
    })
    return result()
  }

  // HEADER
  const headerGroups = getCsvRowGroups(header)
  if (headerGroups.length !== 3) {
    feedback.push({
      row: 0,
      message: 'Must contain 3 groups of columns',
      severity: 'error',
    })
    return result()
  }

  if (headerGroups[0].items.length !== oldDepth + 1) {
    feedback.push({
      row: 0,
      message: `Must start with a group of ${
        oldDepth + 1
      } adjacent columns for the old process ID and taxonomy, found ${
        headerGroups[0].items.length
      }`,
      severity: 'error',
    })
  }

  const newDepth = headerGroups[1].items.length

  if (headerGroups[2].items.length !== 1) {
    feedback.push({
      row: 0,
      message: `Must find a Description header separately as third group, found ${headerGroups[2].items.length}`,
      severity: 'error',
    })
  }

  // Emit errors intermediately
  if (hasErrors()) {
    return result()
  }

  // Create helper functions based on the header
  const getOldLineage = (
    /** @type {{index: number, items: string[]}[]} */ groups,
  ) => {
    const group = groups.find((group) => group.index === headerGroups[0].index)
    if (group) {
      const lineage = group.items.slice(1)
      return {
        oldProcessId: group.items[0] || null,
        oldLineage: lineage.length ? lineage : null,
      }
    } else {
      return { oldProcessId: null, oldLineage: null }
    }
  }

  // BODY
  if (rows.length === 0) {
    feedback.push({
      row: 1,
      message: 'No document rows to process',
      severity: 'error',
    })
  }

  const rowsGroups = rows.map(getCsvRowGroups)
  /** @type {Map<string, {row: number}[]>} */
  const oldTaxonomyReferences = new Map()
  /** @type {Map<string, {row: number}[]>} */
  const newTaxonomyReferences = new Map()
  for (let [row, groups] of rowsGroups.entries()) {
    row = row + 2
    if (groups.length === 0) {
      feedback.push({
        row,
        message: `Empty row found`,
        severity: 'warning',
      })
      continue
    }

    const { oldProcessId, oldLineage } = getOldLineage(groups)
    const newTaxonomyGroup =
      groups.find((group) => group.index === headerGroups[1].index) ?? null
    const descriptionGroup =
      groups.find((group) => group.index === headerGroups[2].index) ?? null

    if (groups.length > 1 && !newTaxonomyGroup && !descriptionGroup) {
      feedback.push({
        row,
        message: `Failed to interpret a taxonomy mapping`,
        severity: 'error',
      })
      continue
    }

    // The user forgot the ID if 1) there is no old process ID and 2) there are old business levels defined
    // The second part can be checked by seeing if some group index starts do not align with the header. This happens when e.g. when the user forgets the ID.
    const forgotOldId = !oldProcessId && groups.find((group) => !headerGroups.some(headerGroup => group.index === headerGroup.index))
    if (forgotOldId) {
      feedback.push({
        row,
        message: `Specified taxonomy mapping but no old Process ID was given`,
        severity: 'error',
      })
      continue
      
    }

    if (oldProcessId && oldLineage) {
      const oldLineageId = toLineageId(oldLineage)
      const references = oldTaxonomyReferences.get(oldLineageId) || []
      references.push({ row })
      oldTaxonomyReferences.set(oldLineageId, references)

      if (oldLineage.length > oldDepth) {
        feedback.push({
          row,
          message: `Old taxonomy overlaps into the new taxonomy columns`,
          severity: 'error',
        })
        continue
      }

      const process = oldOntologyItemsById[oldProcessId]
      if (!process) {
        feedback.push({
          row,
          message: `Process ID ${oldProcessId} not found`,
          severity: 'error',
        })
        continue
      }

      const lineage = getOntologyItemLineage(process)
      if (oldLineage.length !== lineage.length) {
        feedback.push({
          row,
          message: `Old taxonomy for ID ${oldProcessId} is ${oldLineage.length} deep, expected ${lineage.length}`,
          severity: 'warning',
        })
      }

      let match = true
      for (const [i, name] of oldLineage.entries()) {
        if (name !== lineage[i]) {
          match = false
          break
        }
      }
      if (!match) {
        feedback.push({
          row,
          message: `Old taxonomy for ID ${oldProcessId} is altered, expected \n${lineage.join(
            ' > ',
          )}`,
          severity: 'warning',
        })
      }
    } else if (!oldProcessId && !oldLineage) {
      feedback.push({
        row,
        message: `Creating a new taxonomy item without history`,
        severity: 'info',
      })
    }

    if (!newTaxonomyGroup) {
      feedback.push({
        row,
        message: `New taxonomy is not filled in correctly or missing.`,
        severity: 'error',
      })
      continue
    } else {
      const newLineageId = toLineageId(newTaxonomyGroup.items)
      /** @type {{row: number}[]} */
      // @ts-ignore
      const references = newTaxonomyReferences.get(newLineageId) || []
      references.push({ row })
      newTaxonomyReferences.set(newLineageId, references)

      if (newTaxonomyGroup.items.length > newDepth) {
        feedback.push({
          row,
          message: `New taxonomy is ${newTaxonomyGroup.items.length} deep, expected at most ${newDepth}`,
          severity: 'error',
        })
        continue
      }
    }

    if (descriptionGroup) {
      if (descriptionGroup.items.length !== 1) {
        feedback.push({
          row,
          message: `Description must be exactly 1 column wide, found ${descriptionGroup.items.length}`,
          severity: 'error',
        })
        continue
      }
    }

    mappings.push({
      oldProcessId: oldProcessId,
      oldTaxonomy: oldLineage,
      newTaxonomy: newTaxonomyGroup?.items ?? null,
      description: descriptionGroup?.items[0] || null,
    })
  }

  for (const references of oldTaxonomyReferences.values()) {
    if (references.length > 1) {
      for (const { row } of references) {
        feedback.push({
          row,
          message: `Taxonomy history is split into ${
            references.length
          } items (rows: ${references.map((r) => r.row).join(', ')})`,
          severity: 'info',
        })
      }
    }
  }

  for (const references of newTaxonomyReferences.values()) {
    if (references.length > 1) {
      for (const { row } of references) {
        feedback.push({
          row,
          message: `Taxonomy history is merged from ${
            references.length
          } items (rows ${references.map((r) => r.row).join(', ')})`,
          severity: 'info',
        })
      }
    }
  }

  return result()
}

/**
 * Generate a CSV template for taxonomy version mmapping.
 *
 * The CSV data is structured as follows:
 * - ProcessID
 * - Old business capability level 1 names...
 * - EMPTY
 * - New business capability level 1 names...
 * - EMPTY
 * - Description
 *
 * Intermediary nodes are included, so the oldDepth is not always filled,
 * nor is the newDepth.
 *
 * @param {Model.OntologyItem[]} ontologyItems
 * @param {{oldDepth: number, newDepth: number}} opts
 * @returns {(string | null)[][]}
 */
export function generateTaxonomyVersionMappingTemplateCsvData(
  ontologyItems,
  { oldDepth, newDepth },
) {
  const headers = [
    'Old Process ID',
    ...Array.from({ length: oldDepth }).map(
      (v, i) => `Old business capability level ${i + 1}`,
    ),
    null,
    ...Array.from({ length: newDepth }).map(
      (v, i) => `New business capability level ${i + 1}`,
    ),
    null,
    'Description',
  ]

  // Generate CSV rows
  // Not only leaves, but also intermediate nodes are included
  const rows = ontologyItems
    // Gather lineage and create a key to sort by
    .map((ontologyItem) => {
      const lineage = getOntologyItemLineage(ontologyItem)
      return { item: ontologyItem, lineage, key: lineage.join() }
    })
    // Sort by lineage
    .sort((a, b) => a.key.localeCompare(b.key))
    // Fill the CSV record with lineage and description
    .map(({ item: { processId, description }, lineage }) => {
      /** @type {(string | null)[]} */
      const row = Array(oldDepth + newDepth + 4).fill(null)
      // Process ID for the node as first column
      row[0] = processId
      lineage.forEach((name, index) => {
        // Immediately followed by old lineage on the left
        row[index + 1] = name
        // New lineage on the right, can be shorter than old lineage
        if (index < newDepth) {
          row[oldDepth + 1 + index + 1] = name
        }
      })
      // Description as the last column
      row[row.length - 1] = description
      return row
    })

  return [headers, ...rows]
}

/**
 * @param {{mappings: Api.GetVersionMappings.TaxonomyVersionMappingResult[], oldOntologyItemsByProcessId: Record<string, Model.TreeOntologyItem>, newOntologyItemsByProcessId: Record<string, Model.TreeOntologyItem>, taxonomyVersions: any}} param0
 */
export function generateTaxonomyVersionMappingCsvData({
  mappings,
  oldOntologyItemsByProcessId,
  newOntologyItemsByProcessId,
  taxonomyVersions
}) {
  let oldDepth = 0
  let newDepth = 0
  const records = mappings.map((mapping) => {
    const oldOntologyItem = oldOntologyItemsByProcessId[mapping.processIdOld]
    const newOntologyItem = newOntologyItemsByProcessId[mapping.processIdNew]

    if (!oldOntologyItem) {
      throw new Error(`Old process ID ${mapping.processIdOld} not found`)
    }
    const oldLineage = getOntologyItemLineage(oldOntologyItem)
    const newLineage = getOntologyItemLineage(newOntologyItem)
    oldDepth = Math.max(oldDepth, oldLineage.length)
    newDepth = Math.max(newDepth, newLineage.length)
    return {
      oldLineage,
      newLineage,
    }
  })

  // Sort the taxonomyVersions on their createdAt property
  taxonomyVersions.sort((a, b) => a.createdAt - b.createdAt)
  const oldTaxonomyVersion = taxonomyVersions[0]
  const newTaxonomyVersion = taxonomyVersions[1]

  const headers = [
    ...Array.from({ length: oldDepth }).map(
      (v, i) => `${oldTaxonomyVersion.name} business level ${i}`,
    ),
    null,
    ...Array.from({ length: newDepth }).map(
      (v, i) => `${newTaxonomyVersion.name} business level ${i}`,
    ),
  ]
  const body = records.map(({ oldLineage, newLineage }) => {
    const row = Array.from({ length: oldDepth + newDepth + 1 }).fill(null)
    for (let i = 0; i < oldLineage.length; i++) {
      row[i] = oldLineage[i]
    }
    for (let i = 0; i < newLineage.length; i++) {
      row[oldDepth + 1 + i] = newLineage[i]
    }
    return row
  })

  return [headers, ...body]
}

/**
 * @param {{taxonomyVersion: Model.TaxonomyVersion, ontologyItems: Model.TreeOntologyItem[], surveyTemplates: Model.SurveyTemplate[]}} opts
 */
export function generateTaxonomyVersionCsvData({
  taxonomyVersion,
  ontologyItems,
  surveyTemplates,
}) {
  const headers = [
    ...Array.from({ length: taxonomyVersion.depth}).map(
      (v, i) => `Business capability level ${i + 1}`,
    ),
    'Description',
    ...Array.from({ length: surveyTemplates.length }).map(
      (v, i) => `Survey ${surveyTemplates[i].name}`,
    ),
  ]

  const body = ontologyItems.map((item) => {
    const row = Array.from({
      // +1 for the description
      length: taxonomyVersion.depth + surveyTemplates.length + 1,
    }).fill(null)
    const lineage = getOntologyItemLineage(item)
    for (let i = 0; i < lineage.length; i++) {
      row[i] = lineage[i]
    }
    row[taxonomyVersion.depth] = item.description
    for (const surveyTemplate of surveyTemplates) {
      const surveyItemCount = item.surveyItemCounts.find(
        (surveyItemCount) =>
          surveyItemCount.surveyTemplateId === surveyTemplate.id,
      )
      if (surveyItemCount) {
        row[taxonomyVersion.depth + 1 + surveyTemplates.indexOf(surveyTemplate)] =
          surveyItemCount.count
      }
    }
    return row
  })

  return [headers, ...body]
}
