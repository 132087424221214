<template>
	<div class="chart-filter-list">
		<div v-if="showControls && !single" class="chart-filter-list-controls">
			<span :class="{ disabled: isFull }" @click="selectAll">select all</span>
			<span :class="{ disabled: isFull }" @click="toggleAll">toggle</span>
		</div>
		<div
			v-for="value in values"
			:key="JSON.stringify(value)"
			:class="{ disabled: isDisabled(value), inactive: isInactive(value), horizontal: inline, all: value === ALL }"
			:style="{ 'background-color': colorFor(value) }"
			class="chart-filter-list-item"
			@click="toggle(value)"
		>
			<span v-if="value === ALL">all</span>
			<span v-else-if="!value" class="chart-filter-empty">empty</span>
			<span v-else>{{ value }}</span>
		</div>
	</div>
</template>

<script>
import { Dataset } from '../../../lib/dataset'
import { ColorCache } from '../../../lib/color_cache'

export default {
	props: {
		dataset: {
			type: Dataset,
			required: true,
		},
		colorCache: {
			type: ColorCache,
			default: null,
		},
		property: {
			type: String,
			required: true,
		},
		filter: {
			type: Object,
			default: null,
		},
		inline: {
			type: Boolean,
			default: false,
		},
		single: {
			type: Boolean,
			default: false,
		},
		includeAll: {
			type: Boolean,
			default: false,
		},
		forceAll: {
			type: Boolean,
			default: false,
		},
		defaultNotAll: {
			type: Boolean,
			default: false,
		},
		sort: {
			type: Function,
			default: null,
		},
		showControls: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			ALL: Dataset.ALL,
		}
	},
	computed: {
		values() {
			return (this.single && this.includeAll ? [this.ALL] : []).concat(this.forceAll ? [] : this.sortValues(this.dataset.uniqueValuesFor(this.property)))
		},
		isSingleton() {
			return this.filter === this.ALL ? true : this.filter ? this.values.filter((v) => this.filter[v]).length === 1 : this.values.length === 1
		},
		isFull() {
			return !this.single && (!this.filter || this.values.every((value) => this.filter[value]))
		},
		initialSelection() {
			if (this.single) {
				if (this.includeAll) {
					if (this.defaultNotAll) {
						return this.values[1] ? { [this.values[1]]: true } : this.ALL
					} else {
						return this.ALL
					}
				} else {
					return { [this.values[0]]: true }
				}
			} else {
				return this.values.reduce((h, v) => {
					h[v] = true

					return h
				}, {})
			}
		},
	},
	watch: {
		dataset() {
			if (!this.values.filter((value) => !this.filter || this.filter === this.ALL && value === this.ALL || this.filter[value])[0]) {
				this.emitInitialSelection()
			}
		},
	},
	created() {
		this.emitInitialSelection()
	},
	methods: {
		colorFor(value) {
			return this.colorCache.colorFor(this.property, value)
		},
		isDisabled(value) {
			if (this.filter === this.ALL) {
				return value !== this.ALL
			} else {
				return this.filter && !this.filter[value]
			}
		},
		isInactive(value) {
			if (this.isSingleton) {
				return !this.isDisabled(value)
			} else {
				return false
			}
		},
		emitInitialSelection() {
			const newSelection = this.initialSelection
			const oldSelection = this.filter || {}
			let same = true

			Object.keys(newSelection).forEach((value) => {
				if (newSelection[value] !== oldSelection[value]) {
					same = false
				}
			})
			Object.keys(oldSelection).forEach((value) => {
				if (newSelection[value] !== oldSelection[value]) {
					same = false
				}
			})

			if (!same) {
				this.$emit('update', newSelection)
			}
		},
		toggle(value) {
			if (this.single) {
				if (value === this.ALL) {
					this.$emit('update', this.ALL)
				} else {
					this.$emit('update', { [value]: !this.filter[value] })
				}
			} else {
				this.$emit('update', Object.assign({}, this.filter || {}, { [value]: !this.filter[value] }))
			}
		},
		sortValues(values) {
			return this.sort ? this.sort(values) : values
		},
		selectAll() {
			this.$emit('update', this.values.reduce((h, v) => {
				h[v] = true

				return h
			}, {}))
		},
		toggleAll() {
			this.$emit('update', this.values.reduce((h, v) => {
				h[v] = this.filter && !this.filter[v]

				return h
			}, {}))
		},
	},
}
</script>
