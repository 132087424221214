<template>
	<div ref="element" :class="{ active: fullscreen }" class="graphs-overview-fullscreen-toggle" @click="toggleFullScreen" />
</template>

<script>
const fullScreenOn = () => {
	return document.fullscreenElement
        || document.mozFullscreenElement
        || document.webkitFullscreenElement
        || document.msFullscreenElement
}

const openFullscreen = (elem) => {
	if (elem.requestFullscreen) {
		elem.requestFullscreen()
	} else if (elem.mozRequestFullScreen) { /* Firefox */
		elem.mozRequestFullScreen()
	} else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
		elem.webkitRequestFullscreen()
	} else if (elem.msRequestFullscreen) { /* IE/Edge */
		elem.msRequestFullscreen()
	}
}

const closeFullscreen = () => {
	if (document.exitFullscreen) {
		document.exitFullscreen()
	} else if (document.mozCancelFullScreen) { /* Firefox */
		document.mozCancelFullScreen()
	} else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
		document.webkitExitFullscreen()
	} else if (document.msExitFullscreen) { /* IE/Edge */
		document.msExitFullscreen()
	}
}

export default {
	data() {
		return {
			fullscreen: fullScreenOn(),
		}
	},
	created() {
		document.documentElement.addEventListener('fullscreenchange', this.fullScreenChange)
	},
	destroyed() {
		document.documentElement.removeEventListener('fullscreenchange', this.fullScreenChange)
	},
	methods: {
		toggleFullScreen() {
			if (this.fullscreen) {
				closeFullscreen()
			} else {
				openFullscreen(this.$refs.element.parentNode)
			}
		},
		fullScreenChange() {
			this.fullscreen = fullScreenOn()
		},
	},
}
</script>
