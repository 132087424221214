import Vue from 'vue'
import Vuex from 'vuex'
import companies from './modules/admin/companies'
import users from './modules/admin/users'
import ontology from './modules/admin/ontology'
import surveys from './modules/admin/surveys'
import userSurveys from './modules/user/surveys'
import surveyDomain from './modules/surveydomain'
import userGraphs from './modules/user/graphs'
import login from './modules/login'

Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		users,
		companies,
		ontology,
		surveys,
		login,
		userSurveys,
		userGraphs,
		surveyDomain
	},
	state: {

	},
	mutations: {

	},
	actions: {

	},
})
