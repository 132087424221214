<template>
	<div class="chart-container">
		<h1 v-if="slotHasContent('caption')">
			<slot :count="groups.length" name="caption" />
		</h1>
		<div v-if="filterDefs" class="block-chart-filters">
			<dataset-filters
				:dataset="dataset"
				:filter-defs="filterDefs"
				:color-cache="colorCache"
				:show-labels="false"
				:horizontal="horizontalFilterDefs"
				inline
				@update="updateDataset($event)"
			/>
		</div>
		<div v-if="slotHasContent('header')" class="block-chart-header">
			<slot name="header" />
		</div>
		<div class="block-chart">
			<div v-if="blocks.length === 0" class="block-chart-no-data" />
			<div
				v-for="block in blocks"
				:key="block.dataset.key"
				:class="{ [highlightClass(block)]: true, clickable: hasSelectListener }"
				:style="blockStyle(block)"
				class="block-chart-block"
				@mouseover="mouseover(block)"
				@mouseout="mouseout(block)"
				@click="$emit('select', block.dataset.key)"
			>
				<div class="block-chart-block-label">
					<div class="block-chart-block-label-content">
						<slot :dataset="block.dataset" name="label">
							<template v-if="block.dataset.key">
								{{ block.dataset.key }}
							</template>
							<template v-else>
								<i>empty</i>
							</template>
						</slot>
					</div>
					<div class="block-chart-block-count">
						<slot :dataset="block.dataset" name="label-extra">
							{{ block.dataset.count }}
						</slot>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { Dataset } from '../../../lib/dataset'
import { ColorCache } from '../../../lib/color_cache'
import DatasetFilters from './DatasetFilters.vue'

const INFINITY = 1e300
const PERCENT_BASE = 100
const ASPECT_RATIO = 1 / 3 // eslint-disable-line no-magic-numbers
const OPTIMIZATION_FACTOR = 1.2 // The closer to 1, the faster

export default {
	components: { DatasetFilters },
	props: {
		filterDefs: {
			type: Array,
			default: null,
		},
		dataset: {
			type: Dataset,
			required: true,
		},
		quantity: {
			type: String,
			default: null,
		},
		property: {
			type: String,
			required: true,
		},
		includeEmpty: {
			type: Boolean,
			default: false,
		},
		colorCache: {
			type: ColorCache,
			default: null,
		},
		horizontalFilterDefs: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			highlight: null,
			filteredDataset: this.dataset,
		}
	},
	computed: {
		cleanedDataset() {
			let dataset = this.filteredDataset

			if (!this.includeEmpty) {
				dataset = dataset.filterMissing(this.property)
			}

			return dataset
		},
		groups() {
			return this.cleanedDataset.groupBy(this.property, 'DESC')
		},
		blocks() { // eslint-disable-line max-statements, complexity
			if (this.groups.length) {
				const counts = this.groups.map((g) => g.count)
				const totalCount = counts.reduce((a, b) => a + b)
				const fractions = counts.map((c) => c / totalCount)
				const costPower = 10 // The higher this is, the more a non-square is penalized
				const dp = [{ step: null, cost: 0 }] // Dynamic programming

				for (let i = 1; i <= fractions.length; i++) {
					let bestStep = null
					let bestCost = INFINITY
					let bestStepCost = INFINITY

					for (let step = 1; step <= i; step++) {
						const selectedFractions = fractions.slice(i - step, i)
						const width = selectedFractions.reduce((a, b) => a + b)

						// The idea of the step cost is that it tries to get all blocks to be as square as possible
						// eslint-disable-next-line no-magic-numbers, no-loop-func
						const stepCost = selectedFractions.map((fraction) => (fraction / ASPECT_RATIO / Math.min(width / ASPECT_RATIO, fraction / width) ** 2) ** costPower).reduce((a, b) => a + b)
						const cost = stepCost + dp[i - step].cost

						if (stepCost < bestStepCost) {
							bestStepCost = stepCost
						}

						if (cost < bestCost) {
							bestCost = cost
							bestStep = step
						} else if (stepCost > OPTIMIZATION_FACTOR * bestStepCost) {
							break
						}
					}

					dp[i] = { step: bestStep, cost: bestCost }
				}

				const steps = []
				let currentPos = dp.length - 1

				while (currentPos) {
					steps.push(dp[currentPos].step)
					currentPos -= dp[currentPos].step
				}

				steps.reverse()

				const blocks = []
				let currentOffset = 0
				let currentLeft = 0

				steps.forEach((step) => {
					const groupsInStep = this.groups.slice(currentOffset, currentOffset + step)
					const sum = groupsInStep.map((g) => g.count).reduce((a, b) => a + b)
					let currentTop = 0
					const width = sum / totalCount

					groupsInStep.forEach((group) => {
						const height = group.count / sum

						blocks.push({
							dataset: group,
							left: currentLeft,
							top: currentTop,
							width,
							height,
						})
						currentTop += height
					})

					currentLeft += width
					currentOffset += step
				})

				return blocks.sort((b1, b2) => b1.dataset.key < b2.dataset.key ? -1 : 1)
			} else {
				return []
			}
		},
		hasSelectListener() {
			return this.$listeners && this.$listeners.select
		},
	},
	watch: {
		dataset() {
			this.filteredDataset = this.dataset
		},
	},
	methods: {
		colorFor(property, value) {
			return this.colorCache.colorFor(property, value)
		},
		mouseover(block) {
			this.highlight = block.dataset.key
		},
		mouseout() {
			this.highlight = null
		},
		highlightClass(block) {
			if (this.highlight !== null) {
				return this.highlight === block.dataset.key ? 'selected' : 'deselected'
			} else {
				return ''
			}
		},
		blockStyle(block) {
			return {
				left: `${block.left * PERCENT_BASE}%`,
				top: `${block.top * PERCENT_BASE}%`,
				right: `${(1 - block.left - block.width) * PERCENT_BASE}%`,
				bottom: `${(1 - block.top - block.height) * PERCENT_BASE}%`,
				backgroundColor: this.colorFor(this.property, block.dataset.key),
			}
		},
		updateDataset(dataset) {
			this.filteredDataset = dataset
		},
		slotHasContent(slot) {
			return this.$slots[slot] || this.$scopedSlots[slot]
		},
		splitName(vendorName) {
			return vendorName.replace(' ', '/br')
		},
	},
}
</script>

<style lang="scss">
@import '../../../assets/scss/pages/user/charts/block';
</style>
