<template>
	<div :class="{ horizontal }" class="chart-filters">
		<!-- <h1 v-if="!inline">FILTERING</h1> -->
		<div v-for="(filterDef, i) in filterDefs" :key="filterDef.property" class="chart-filter">
			<h2 v-if="showLabels">
				{{ filterDef.property }}
			</h2>
			<dataset-filter
				:unfiltered-dataset="dataset"
				:full-filter="filter"
				:color-cache="colorCache"
				:filter-def="filterDef"
				:inline="inline"
				:preceding-properties="precedingProperties(i)"
				@update="updateFilter($event)"
			/>
		</div>
	</div>
</template>

<script>
import { Dataset } from '../../../lib/dataset'
import { ColorCache } from '../../../lib/color_cache'
import DatasetFilter from './DatasetFilter'

export default {
	components: { DatasetFilter },
	props: {
		filterDefs: {
			type: Array,
			required: true,
		},
		dataset: {
			type: Dataset,
			required: true,
		},
		colorCache: {
			type: ColorCache,
			default: null,
		},
		showLabels: {
			type: Boolean,
			default: true,
		},
		inline: {
			type: Boolean,
			default: false,
		},
		horizontal: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			filter: {},
		}
	},
	watch: {
		dataset() {
			this.$emit('update', this.dataset.applyFilter(this.filter))
		},
	},
	methods: {
		updateFilter(filter) {
			this.filter = Object.assign({}, this.filter, filter)
			this.$emit('update', this.dataset.applyFilter(this.filter))
		},
		precedingProperties(i) {
			return this.filterDefs.slice(0, i).map((filterDef) => filterDef.property)
		},
	},
}
</script>

<style lang="scss">
@import '../../../assets/scss/pages/user/charts/filter';
</style>
