import Vue from 'vue'
import Router from 'vue-router'
import { config } from './config'
import store from './store'
import AdminDashboard from './views/admin/AdminDashboard.vue'
import AdminOntology from './views/admin/AdminOntology.vue'
import AdminSubmissionStatus from './views/admin/AdminSubmissionStatus.vue'
import AdminSurveys from './views/admin/AdminSurveys.vue'
import AdminUsers from './views/admin/AdminUsers.vue'
import AdminView from './views/admin/AdminView.vue'
import LoginByEmail from './views/login/LoginByEmail.vue'
import LoginForm from './views/login/LoginForm.vue'
import LoginLoading from './views/login/LoginLoading.vue'
import LoginResetPassword from './views/login/ResetPassword.vue'
import GraphsView from './views/user/GraphsView.vue'
import HelpPage from './views/user/HelpPage.vue'
import OntologyView from './views/user/OntologyView.vue'
import SurveyReloader from './views/user/SurveyReloader.vue'
import SurveysView from './views/user/SurveysView.vue'

Vue.use(Router)

export default new Router({
	mode: 'history',
	base: config.baseUrl,
	routes: [
		{
			path: '/loading',
			name: 'loading',
			component: LoginLoading,
		},
		{
			path: '/login',
			name: 'login',
			component: LoginForm,
		},
		{
			path: '/reset_password/:token',
			name: 'reset_password',
			component: LoginResetPassword,
			props: true,
		},
		{
			path: '/login_by_email/:token',
			name: 'login_by_email',
			component: LoginByEmail,
			props: true,
		},
		{
			path: '/surveys/:id/:selectedBusinessUnitId',
			name: 'userSurvey',
			component: SurveyReloader,
			props: true,
			beforeEnter(to, from, next) {
				if (!store.state.login.currentUser) {
					return next({
						replace: true,
						path: '/loading',
						query: { redirect: to.fullPath },
					})
				} else {
					return next()
				}
			},
		},
		{
			path: '/',
			name: 'userSurveys',
			component: SurveysView,
			beforeEnter(to, from, next) {
				if (!store.state.login.currentUser) {
					return next({
						replace: true,
						path: '/loading',
						query: { redirect: to.fullPath },
					})
				} else {
					return next()
				}
			},
		},
		{
			path: '/ontology',
			name: 'userOntology',
			component: OntologyView,
			beforeEnter(to, from, next) {
				if (!store.state.login.currentUser) {
					return next({
						replace: true,
						path: '/loading',
						query: { redirect: to.fullPath },
					})
				} else {
					return next()
				}
			},
		},
		{
			path: '/graphs',
			name: 'userGraphs',
			component: GraphsView,
			beforeEnter(to, from, next) {
				if (!store.state.login.currentUser) {
					return next({
						replace: true,
						path: '/loading',
						query: { redirect: to.fullPath },
					})
				} else {
					return next()
				}
			},
		},
		{
			path: '/help',
			name: 'help',
			component: HelpPage,
			beforeEnter(to, from, next) {
				if (!store.state.login.currentUser) {
					return next({
						replace: true,
						path: '/loading',
						query: { redirect: to.fullPath },
					})
				} else {
					return next()
				}
			},
		},
		{
			path: '/admin',
			component: AdminView,
			beforeEnter(to, from, next) {
				if (!store.state.login.currentUser) {
					return next({
						replace: true,
						path: '/loading',
						query: { redirect: to.fullPath },
					})
				} else if (!store.state.login.currentUser.isAdmin) {
					return next({
						replace: true,
						path: '/',
					})
				} else {
					return next()
				}
			},
			children: [
				{ path: '', redirect: 'surveys' },
				{
					path: 'surveys',
					name: 'surveys',
					meta: { navMatch: 'surveys' },
					component: AdminDashboard,
				},
				{
					path: 'surveys/create',
					name: 'createSurvey',
					meta: { navMatch: 'surveys' },
					component: AdminSurveys,
				},
				{
					path: 'users',
					name: 'users',
					meta: { navMatch: 'users' },
					component: AdminUsers,
				},
				{
					path: 'ontology',
					name: 'ontology',
					meta: { navMatch: 'ontology' },
					component: AdminOntology,
				},
				{
					path: 'submissions',
					name: 'submissions',
					meta: { navMatch: 'submissions' },
					component: AdminSubmissionStatus,
				},
			],
		},
	],
})
