<template>
  <v-container fluid class="admin-user-content">
    <v-layout v-if="template" row wrap>
      <v-flex xs12>
        <h1 class="title-layout">Create Survey</h1>

        <!-- Section: Choose Survey Name -->
        <v-flex xs6>
          <h2 class="subtitle-layout">Choose Survey name</h2>
          <!-- Input: survey name -->
          <v-text-field
            v-model="template.name"
            :error-messages="lookupMultiError(surveyErrors, 'name')"
            :disabled="saving"
            type="text"
            placeholder="Year 2019"
            class="survey-create-text-field"
          />
        </v-flex>

        <!-- Section: Select Survey Template -->
        <div class="survey-create-templates">
          <v-layout row wrap>
            <h2 slot="activator" class="subtitle-layout">
              Select Survey Template
            </h2>
            <v-tooltip bottom>
              <v-icon
                slot="activator"
                class="survey-create-templates-icon"
                small
              >
                mdi-help-circle-outline
              </v-icon>
              <span>
                Select a previous survey to prefill survey fields and associated
                data
              </span>
            </v-tooltip>
          </v-layout>
          <div class="survey-create-templates-buttons">
            <!-- Survey template selection buttons: [{survey name}]  ... -->
            <v-btn
              v-for="survey in sortedSurveys"
              :key="survey.id"
              :loading="!templatesAreLoaded(survey)"
              :disabled="saving"
              :color="survey === selectedTemplate ? 'active' : 'none'"
              :class="{ draft: survey.isDraft }"
              class="button survey-create-button"
              @click="select(survey)"
            >
              {{ survey.name }}
            </v-btn>
          </div>
        </div>
      </v-flex>

      <!-- Section: Configure Survey Fields -->
      <div v-if="selectedTemplate" class="survey-create-config">
        <div class="survey-create-wrapper">
          <h2 class="subtitle-layout" style="padding-top: 5px">
            Configure Survey Fields
          </h2>
          <h3 class="survey-create-note" style="padding-top: 5px">
            Note that you can drag the fields to determine the order in the
            survey
          </h3>
          <div class="survey-create-create">
            <v-checkbox
              v-if="taxonomyVersions && taxonomyVersions.some((tv) => tv.draft)"
              v-model="createFromDraftTaxonomy"
              label="Use draft taxononmy version"
            >
            </v-checkbox>
            <v-tooltip
              :disabled="!(hasMultiError(surveyErrors) || saving)"
              bottom
            >
              <v-btn
                v-if="selectedTemplate"
                slot="activator"
                :loading="saving"
                :disabled="hasMultiError(surveyErrors) || saving"
                readonly
                color="success"
                @click="createSurvey()"
              >
                <span v-if="template.id">Save</span>
                <span v-else>Create survey</span>
              </v-btn>
              <span> Check survey name and survey items for errors </span>
            </v-tooltip>
          </div>
        </div>
        <!-- Grid of Field Template components -->
        <draggable
          v-model="selectedFields"
          class="survey-create-config-content"
        >
          <FieldTemplate
            v-for="(field, index) in selectedFields"
            :key="index"
            :disabled="saving"
            :field.sync="field"
            :errors="
              lookupPartialMultiError(surveyErrors, 'fieldTemplates', index)
            "
            @removeField="removeField"
            @addValue="addValue($event, field)"
            @deleteValue="deleteValue($event, field)"
          />
        </draggable>
        <div
          :class="{ 'generic-disabled': saving }"
          class="survey-create-column-add"
          @click="saving ? '' : addField()"
        >
          <v-card class="survey-create-column-add-content">
            <v-icon color="primary"> mdi-plus-circle-outline </v-icon>
            <p class="survey-create-column-add-text">Add survey field</p>
          </v-card>
        </div>
      </div>
    </v-layout>

    <!-- Dialog: Survey Confirmation -->
    <v-dialog v-model="SurveyConfirmationDialog" persistent max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline"
            >Are you sure you want to create this survey?</span
          >
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap="">
              <v-flex xs12>
                This will create and open up the survey for all users.<br /><br />
                Note that created surveys cannot be deleted
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="blue darken-1" flat @click.native="cancelSubmission()">
            cancel
          </v-btn>
          <v-btn
            color="blue darken-1"
            flat
            @click.native="acceptSubmission(template, createFromDraftTaxonomy)"
          >
            create survey
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import draggable from 'vuedraggable'
import { mapState } from 'vuex'

import {
  hasMultiError,
  lookupMultiError,
  lookupPartialMultiError,
} from '../../api/validations'
import FieldTemplate from '../../components/admin/FieldTemplate.vue'
import ontologyStore from '../../store/modules/admin/ontology'
import surveysStore from '../../store/modules/admin/surveys'

export default {
  name: 'AdminSurveys',
  components: {
    FieldTemplate,
    draggable,
  },
  data() {
    return {
      createFromDraftTaxonomy: false,
      SurveyConfirmationDialog: false,
      selectedTemplate: null,
    }
  },
  computed: {
    ...surveysStore.mapState({
      surveys: (state) => state.templates,
      template: (state) => state.currentTemplate,
      surveyValidator: (state) => state.surveyValidator,
      saving: (state) => state.saving,
      serverSurveyErrors: (state) => state.serverSurveyErrors,
      serverError: (state) => state.serverError,
    }),
    ...ontologyStore.mapState({
      taxonomyVersions: (state) => state.taxonomyVersions,
    }),
    ...mapState({
      surveyDomains: (state) => state.surveyDomain.all,
    }),
    selectedFields: {
      get() {
        return this.$store.state.surveys.currentTemplate.fieldTemplates
      },
      set(value) {
        this.$store.dispatch('surveys/updateFields', value)
      },
    },
    isDynamic(strict) {
      return !strict
    },
    sortedSurveys() {
      return this.surveys.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
    },
    surveyErrors() {
      if (this.surveyValidator) {
        const surveyNames = this.surveys
          .filter((c) => c.id !== this.template.id)
          .map((c) => c.name)

        return [
          this.surveyValidator(this.template, false, { surveyNames }),
          this.serverSurveyErrors,
        ]
      } else {
        return []
      }
    },
  },
  async created() {
    const surveyDomainsLoaded = await this.$store.dispatch(
      'surveyDomain/loadSurveyDomains',
    )
    this.$store.dispatch('surveys/getAllSurveys')
    this.$store.dispatch('surveys/newTemplate')
    this.$store.dispatch(
      'surveys/addField',
      Object.assign(
        {},
        {
          fieldName: 'System name',
          isStrict: false,
          isFreeText: true,
          allowedValues: [],
          isNewField: false,
          isMandatory: true,
          prefilled: true,
          isStandard: true,
        },
      ),
    )
    await surveyDomainsLoaded
    this.$store.dispatch('ontology/loadOntology', {
      surveyDomainId: this.surveyDomains[0].id,
    })
  },

  methods: {
    select(survey) {
      this.selectedTemplate = this.selectedTemplate === survey ? null : survey

      if (this.selectedTemplate) {
        this.$store.dispatch('surveys/initFields', survey)
      } else {
        const surveyWithEmptyTemplates = Object.assign({}, survey, {
          fieldTemplates: [],
        })

        this.$store.dispatch('surveys/initFields', surveyWithEmptyTemplates)
      }
    },
    cancelSubmission() {
      this.SurveyConfirmationDialog = false
    },
    acceptSubmission(template, createFromDraftTaxonomy) {
      this.SurveyConfirmationDialog = false
      this.save(template, createFromDraftTaxonomy)
      // TODO: actually wait for the save to finish
      // Wait for one second and then reload the page
      setTimeout(() => {
        this.$router.push('/admin/surveys').catch(() => {})
      }, 1000)
    },
    createSurvey() {
      this.SurveyConfirmationDialog = true
    },
    removeField(field) {
      this.$store.dispatch('surveys/removeField', field)
    },
    templatesAreLoaded(survey) {
      return survey.fieldTemplates !== undefined
    },
    addField() {
      const newField = {
        fieldName: '',
        isStrict: false,
        isFreeText: true,
        allowedValues: [],
        isNewField: true,
        isMandatory: false,
        prefilled: false,
      }

      this.$store.dispatch('surveys/addField', Object.assign({}, newField))
    },
    addValue(value, field) {
      this.$store.dispatch('surveys/addValueToField', { field, value })
    },
    deleteValue(index, field) {
      this.$store.dispatch('surveys/removeFieldValue', { field, index })
    },
    save(template, createFromDraftTaxonomy) {
      template.status = 'started'
      this.$store
        .dispatch('surveys/save', { template, createFromDraftTaxonomy })
        .then(() => {
          this.$router.push('/admin/surveys').catch(() => {})
        })
        .catch(() => {})
    },
    lookupMultiError,
    hasMultiError,
    lookupPartialMultiError,
  },
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/pages/admin/survey';
</style>
