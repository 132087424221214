import { keyBy, omit } from 'lodash'
import { api } from '../../../../api/api-service'
import { downloadCsv } from '../../../../lib/csv'
import {
  attachSurveyTemplatesToOntologyItems,
  generateTaxonomyVersionCsvData,
  treeifyOntologyItems,
} from '../../../../lib/ontology'
import { createStoreUtils } from '../../../../lib/vuex'

/**
 * @typedef {Store.Ontology.DownloadTaxonomyVersion.LocalState} State
 * @typedef {typeof getters} Getters
 * @typedef {typeof mutations} Mutations
 * @typedef {typeof actions} Actions
 */

/**
 * @template {any} Payload [Payload=void]
 * @typedef {MutationFor<State, Payload>} Mutate
 */

/**
 * @template {any} Payload [Payload=void]
 * @typedef {ActionFor<Mutations, State, Payload, void>} Act
 */

/** @type {State}  */
const initialState = {
  isOpen: false,
  reference: null,
  error: null,
}

/** @satisfies {GettersFor<State>} */
const getters = {
  error(state) {
    return state.error
  },
  // Accessors
  dialogIsOpen(state) {
    return state.isOpen
  },
}

/** @satisfies {MutationsFor<State>} */
const mutations = {
  /** @type {Mutate<{taxonomyVersion: Model.TaxonomyVersion, ontologyItems: Model.OntologyItem[], surveyTemplates: Model.SurveyTemplate[]}>} */
  storeData(state, { ontologyItems, taxonomyVersion, surveyTemplates }) {
    try {
      const byId = keyBy(ontologyItems, 'processId')
      treeifyOntologyItems({ byId })

      state.reference = {
        taxonomyVersion: omit(taxonomyVersion, 'ontologyItems'),
        ontologyItems: attachSurveyTemplatesToOntologyItems({
          ontologyItems,
          surveyTemplates: surveyTemplates.sort(
            (a, b) => new Date(a).getTime() - new Date(b).getTime(),
          ),
        }),
        surveyTemplates,
      }
    } catch (error) {
      console.error(error)
      state.error = error.toString() || error?.message
    }
  },
  /** @type {Mutate<void>} */
  resetState(state) {
    state.isOpen = false
    state.reference = null
    state.error = null
  },

  // Accessors
  /** @type {Mutate<boolean>} */
  setDialogIsOpen(state, isOpen) {
    state.isOpen = isOpen
  },
}

/** @satisfies {ActionsFor<Mutations, State>} */
const actions = {
  /** @type {Act<{toTaxonomyVersion: Model.TaxonomyVersion}>} */
  async openDialog({ commit, dispatch }, { toTaxonomyVersion }) {
    commit('setDialogIsOpen', true)
    dispatch('loadData')
  },
  /** @type {Act<void>} */
  async loadData({ commit, rootState }) {
    const selectedTaxonomyVersion = rootState.ontology.selectedTaxonomyVersion
    if (!selectedTaxonomyVersion) {
      throw new Error('No selected taxonomy version')
    }

    const { taxonomyVersion, surveyTemplates } = await api.getTaxonomyForExport(
      { taxonomyVersionId: selectedTaxonomyVersion.id },
    )
    commit('storeData', {
      taxonomyVersion,
      ontologyItems: taxonomyVersion.ontologyItems,
      surveyTemplates,
    })
  },
  /** @type {Act<void>} */
  async downloadTaxonomyVersionCsv({ state }) {
    if (!state.reference) {
      throw new Error('No data to download')
    }
    const csvData = generateTaxonomyVersionCsvData(state.reference)
    downloadCsv('taxonomy-version.csv', csvData)
  },

  // Accessors
  /** @type {Act<boolean>} */
  async setDialogIsOpen({ commit }, isOpen) {
    if (isOpen) {
      commit('setDialogIsOpen', isOpen)
    } else {
      commit('resetState', undefined)
    }
  },
}

export const downloadTaxonomyVersionStore = {
  namespaced: true,
  ...createStoreUtils('ontology/downloadTaxonomyVersion', {
    state: initialState,
    getters,
    mutations,
    actions,
  }),
}
