<template>
	<v-container fluid class="admin-user-content">
		<v-layout row wrap="">
			<div class="table-header">
				<h1 class="title-layout">
					Companies
				</h1>
				<v-dialog v-model="companyDialog" max-width="500px" persistent>
					<v-btn
						slot="activator"
						color="primary"
						dark
						class="mb-2 button"
						@click="editingCompany = false"
					>
						New Company
					</v-btn>
					<v-card>
						<v-card-title>
							<span class="headline">{{ editingCompany? 'Edit Company' : 'Create Company' }}</span>
						</v-card-title>
						<v-card-text>
							<v-container grid-list-md>
								<v-layout wrap="">
									<v-flex xs12>
										<v-alert :value="serverErrorForUser" color="error" icon="warning" outline>
											Server error.
										</v-alert>
									</v-flex>
									<v-flex xs12 sm6 md4>
										<v-text-field
											v-model="editedCompany.name"
											:disabled="savingCompany"
											:error-messages="lookupMultiError(companyErrors, 'name')"
											label="Company name"
											@keyup.enter.native="hasMultiError(companyErrors) || savingCompany ? '' : saveCompany()"
										/>
									</v-flex>
									<v-flex xs12 sm6 md4>
										<v-text-field
											v-model="editedCompany.address"
											:disabled="savingCompany"
											:error-messages="lookupMultiError(companyErrors, 'address')"
											label="Address"
											@keyup.enter.native="hasMultiError(companyErrors) || savingCompany ? '' : saveCompany()"
										/>
									</v-flex>
								</v-layout>
							</v-container>
						</v-card-text>
						<v-card-actions>
							<v-spacer />
							<v-btn color="blue darken-1" flat @click.native="close">
								Cancel
							</v-btn>
							<v-btn
								:loading="savingCompany"
								:disabled="hasMultiError(companyErrors) || savingCompany"
								color="blue darken-1"
								flat
								@click.native="saveCompany"
							>
								Save
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
			</div>
			<v-flex xs12 class="table">
				<v-data-table :headers="companyHeaders" :items="companies" :rows-per-page-items="perPage">
					<template slot="items" slot-scope="companyProps">
						<tr>
							<td @click="companyProps.expanded = !companyProps.expanded">
								{{ companyProps.item.name }}
							</td>
							<td @click="companyProps.expanded = !companyProps.expanded">
								{{ companyProps.item.address }}
							</td>
							<td @click="companyProps.expanded = !companyProps.expanded">
								<font :color="companyProps.item.active ? '' : 'red'">
									{{ companyProps.item.active? "Active" : "Inactive" }}
								</font>
							</td>
							<td class="justify-center layout px-0">
								<v-icon small class="mr-2" @click="editCompany(companyProps.item)">
									edit
								</v-icon>
								<v-icon v-if="companyProps.item.active === true && currentUser.company.name !== companyProps.item.name" small @click="inactivateCompany(companyProps.item)">
									delete
								</v-icon>
								<v-icon v-if="companyProps.item.active === false" small @click="reactivateCompany(companyProps.item)">
									redo
								</v-icon>
							</td>
						</tr>
					</template>
					<template slot="expand" slot-scope="companyProps">
						<v-layout row wrap="">
							<div class="subtable-header">
								<h1 class="subsubtitle-layout">
									Users for company {{ companyProps.item.name }}
								</h1>
								<v-dialog v-model="userDialog" :disabled="!companyProps.item.active" max-width="500px" persistent>
									<v-btn
										slot="activator"
										:disabled="!companyProps.item.active"
										color="primary"
										dark
										class="mb-2 button"
										@click="editingUser = false"
									>
										New User
									</v-btn>
									<v-card>
										<v-card-title>
											<span class="headline"> {{ editingUser? 'Edit user' : 'Create User' }}</span>
										</v-card-title>
										<v-card-text>
											<span class="heading 6"> Only set password if user explicitly asked for it</span>
											<v-container grid-list-md>
												<v-layout wrap="">
													<v-flex xs12>
														<v-alert :value="serverErrorForUser" color="error" icon="warning" outline>
															Server error.
														</v-alert>
													</v-flex>
													<v-flex xs12 sm6 md4>
														<v-text-field
															v-model="editedUser.firstName"
															:disabled="savingUser"
															:error-messages="lookupMultiError(userErrors, 'firstName')"
															label="Firstname"
															@keyup.enter.native="hasMultiError(userErrors) || savingUser? null:saveUser(companyProps.item)"
														/>
													</v-flex>
													<v-flex xs12 sm6 md4>
														<v-text-field
															v-model="editedUser.lastName"
															:disabled="savingUser"
															:error-messages="lookupMultiError(userErrors, 'lastName')"
															label="Lastname"
															@keyup.enter.native="hasMultiError(userErrors) || savingUser? null:saveUser(companyProps.item)"
														/>
													</v-flex>
													<v-flex xs12 sm6 md4>
														<v-text-field
															v-model="editedUser.email"
															:disabled="editedUser.email === currentUser.email || savingUser"
															:error-messages="lookupMultiError(userErrors, 'email')"
															label="Email"
															@keyup.enter.native="hasMultiError(userErrors) || savingUser? null:saveUser(companyProps.item)"
														/>
													</v-flex>
													<v-flex xs12 sm6 md4>
														<v-text-field
															v-model="editedUser.password"
															:disabled="savingUser"
															:error-messages="lookupMultiError(userErrors, 'password')"
															label="password"
															@keyup.enter.native="hasMultiError(userErrors) || savingUser? null:saveUser(companyProps.item)"
														/>
													</v-flex>
													<v-checkbox v-model="editedUser.isAdmin" :disabled="editedUser.email === currentUser.email" label="admin" />
												</v-layout>
											</v-container>
										</v-card-text>
										<v-card-actions>
											<v-spacer />
											<v-btn color="blue darken-1" flat @click.native="close">
												Cancel
											</v-btn>
											<v-btn
												:loading="savingUser"
												:disabled="hasMultiError(userErrors) || savingUser"
												color="blue darken-1"
												flat
												@click.native="saveUser(companyProps.item)"
											>
												Save
											</v-btn>
										</v-card-actions>
									</v-card>
								</v-dialog>
							</div>
							<v-flex xs12>
								<v-data-table
									:headers="userHeaders"
									:items="companyUsers(companyProps.item.id)"
									hide-actions
									class="subtable"
								>
									<template slot="items" slot-scope="userProps">
										<td>{{ userProps.item.firstName }}</td>
										<td>{{ userProps.item.lastName }}</td>
										<td>{{ userProps.item.email }}</td>
										<td>{{ userProps.item.hasPassword? 'set' : 'not set' }}</td>
										<td>{{ userProps.item.isAdmin? 'Admin' : 'User' }}</td>
										<td class="justify-center layout px-0">
											<v-icon small class="mr-2" @click="editUser(userProps.item)">
												edit
											</v-icon>
											<v-icon v-if="userProps.item.active === true && currentUser.email !== userProps.item.email" small class="mr-2" @click="deleteUser(userProps.item)">
												delete
											</v-icon>
											<v-icon v-if="userProps.item.active === false & companyProps.item.active === true" small class="mr-2" @click="reactivateUser(userProps.item)">
												redo
											</v-icon>
										</td>
									</template>
								</v-data-table>
							</v-flex>
						</v-layout>
					</template>
				</v-data-table>
			</v-flex>
		</v-layout>
	</v-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { hasMultiError, lookupMultiError } from '../../api/validations'

const ON_CLOSE_EDITED_ITEM_CLEAR_TIMEOUT = 300

export default {
	name: 'AdminUsers',
	data() {
		return {
			userDialog: false,
			companyDialog: false,
			editingUser: false,
			editingCompany: false,
			dialogError: null,
			// eslint-disable-next-line no-magic-numbers
			perPage: [10, 20, { text: '$vuetify.dataIterator.rowsPerPageAll', value: -1 }],
			companyHeaders: [
				{
					text: 'Company name',
					align: 'left',
					value: 'name',
				},
				{ text: 'Address', value: 'address' },
				{ text: 'Status', value: 'active' },
			],
			userHeaders: [
				{ text: 'Firstname', value: 'firstName'	},
				{ text: 'Lastname', value: 'lastName' },
				{ text: 'Email', value: 'email' },
				{ text: 'password', value: 'password' },
				{ text: 'Role', value: 'isAdmin' },

			],
			editedUser: { firstName: '', lastName: '', email: '', active: true, password: null, isAdmin: false },
			editedCompany: { name: '', address: '', active: true },
		}
	},
	computed: {
		...mapState({
			companies: state => state.companies.all,
			users: state => state.users.all,
			userValidator: state => state.users.userValidator,
			companyValidator: state => state.companies.companyValidator,
			savingCompany: state => state.companies.saving,
			savingUser: state => state.users.saving,
			serverCompanyErrors: state => state.companies.serverCompanyErrors,
			serverUserErrors: state => state.users.serverUserErrors,
			serverErrorForCompany: state => state.companies.serverError,
			serverErrorForUser: state => state.users.serverError,
			currentUser: state => state.login.currentUser,
		}),
		...mapGetters('users', {
			companyUsers: 'getUsersFromCompany',
		}),
		userErrors() {
			if (this.userValidator) {
				const userEmails = this.users.filter((c) => c.id !== this.editedUser.id).map(c => c.email)

				return [this.userValidator(this.editedUser, false, { userEmails }), this.serverUserErrors]
			} else {
				return []
			}
		},
		companyErrors() {
			if (this.companyValidator) {
				const companyNames = this.companies.filter((c) => c.id !== this.editedCompany.id).map(c => c.name)

				return [this.companyValidator(this.editedCompany, false, { companyNames }), this.serverCompanyErrors]
			} else {
				return []
			}
		},
	},
	created() {
		this.$store.dispatch('companies/getAllCompanies', true)
		this.$store.dispatch('users/getAllUsers', true)
	},

	methods: {
		...mapActions('users', {
			doCreateUser: 'createUser',
			doUpdateUser: 'updateUser',
			doDeleteUser: 'deleteUser',
			disableCompanyUsers: 'disableCompany',
			doSendNotificationEmail: 'sendNotificationEmail',
		}),
		...mapActions('companies', {
			doCreateCompany: 'createCompany',
			doUpdateCompany: 'updateCompany',
		}),

		close() {
			this.userDialog = false
			this.companyDialog = false
			setTimeout(() => {
				this.editedUser = { firstName: '', lastName: '', email: '', password: null, isAdmin: false }
				this.editedCompany = { name: '', address: '' }
			}, ON_CLOSE_EDITED_ITEM_CLEAR_TIMEOUT)
		},

		editUser(user) {
			this.editedUser = Object.assign({}, user)
			this.userDialog = true
			this.editingUser = true
		},
		editCompany(company) {
			this.editedCompany = Object.assign({}, company)
			this.companyDialog = true
			this.editingCompany = true
		},

		saveUser(company) {
			this.editedUser.email = this.editedUser.email.toLowerCase()

			const saved = this.editedUser.id
				? this.doUpdateUser(this.editedUser)
				: this.doCreateUser(Object.assign(this.editedUser, { companyId: company.id, active: company.active }))

			saved
				.then(() => {
					this.close()

					if (!this.editedUser.id) { this.doSendNotificationEmail(this.editedUser.email) }
				})
				.catch(() => {
					this.dialogError = 'error saving user' // TODO: display this error
				})
		},
		saveCompany() {
			const saved = this.editedCompany.id
				? this.doUpdateCompany(this.editedCompany)
				: this.doCreateCompany(Object.assign(this.editedCompany))

			saved
				.then(() => this.close())
				.catch(() => {
					this.dialogError = 'error saving company'
				})
		},
		inactivateCompany(company) {
			if (confirm("Are you sure you want to inactivate this company? It's users will be deleted")) {
				this.disableCompanyUsers(company)
				this.doUpdateCompany({ ...company, active: false })
			}
		},
		reactivateCompany(company) {
			if (confirm('Are you sure you want to reactivate this company?')) {
				this.doUpdateCompany({ ...company, active: true })
			}
		},
		inactivateUser(item) {
			if (confirm("Are you sure you want to inactivate this user? He/she won't have access to the application anymore")) {
				this.doUpdateUser({ ...item, active: false })
			}
		},
		deleteUser(item) {
			if (confirm("Are you sure you want to delete this user? He/she won't have access to the application anymore")) {
				this.doDeleteUser({ ...item })
			}
		},
		reactivateUser(item) {
			if (confirm('Are you sure you want to reactivate this user? He/she will gain access to the application again')) {
				this.doUpdateUser({ ...item, active: true })
			}
		},
		lookupMultiError,
		hasMultiError,
	},
}


</script>

<style lang="scss" scoped>
.admin-user-content {
  padding: 10px 30px 20px 30px;
}
.table {
  border: 1px solid $blue;
  &-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}
.subtable-header{
	margin-top: 10px;
	margin-left: 30px;
}
.subtable{
  border: 1px solid $blue;
  margin-left: 20px;
  margin-bottom: 10px;
  &-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
}
</style>
