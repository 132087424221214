<template>
	<v-container fill-height text-xs-center>
		<v-layout row align-center>
			<v-flex>
				<v-progress-circular
					:size="70"
					:width="7"
					color="purple"
					indeterminate
				/>
			</v-flex>
		</v-layout>
	</v-container>
</template>

<script>
export default {
	created() {
		this.$store.dispatch('login/getCurrentUser').then((user) => {
			if (user) {
				this.$router.replace(this.$route.query.redirect || '/')
			} else {
				this.$router.replace({ path: '/login', query: this.$route.query })
			}
		})
	},
}
</script>

<style>

</style>
