<template>
	<div :class="{ readonly: readOnly }" class="check-box" @click="toggle">
		<div :class="{ checked: value }" class="mark" /><slot />
	</div>
</template>

<script>
export default {
	props: {
		value: {
			type: Boolean,
			required: true,
		},
		readOnly: {
			type: Boolean,
			default: false,
		},
	},
	methods: {
		toggle() {
			this.$emit('input', !this.value)
		},
	},
}
</script>

<style lang="scss" scoped>
.check-box {
	cursor: pointer;
	display: inline-block;
	line-height: 20px;
	position: relative;
	padding-left: 28px;
	user-select: none;
	margin-right: 25px;
	vertical-align: top;
	&.readonly {
		opacity: 0.25;
		pointer-events: none;
	}
}
// .check-box + .check-box {
// 	margin-top: 5px;
// }
.mark {
	width: 20px;
	height: 20px;
	border: 3px solid grey;
	position: absolute;
	left: 0;
	top: 0;
	&.checked {
		&::before {
			content: "";
			border-top: 7px solid white;
			border-left: 7px solid white;
			width: 8px;
			height: 20px;
			position: absolute;
			bottom: -1px;
			left: 1.5px;
			z-index: 1;
			transform: translateX(4px) translateY(-5px) rotate(-135deg) skewX(20deg) skewY(10deg) translateZ(0);
			pointer-events: none;
		}
		&::after {
			content: "";
			border-top: 3px solid rgb(151, 182, 40);
			border-left: 3px solid rgb(151, 182, 40);
			width: 8px;
			height: 20px;
			position: absolute;
			bottom: 0;
			left: 0;
			z-index: 1;
			transform: translateX(4px) translateY(-5px) rotate(-135deg) skewX(20deg) skewY(10deg) translateZ(0);
			pointer-events: none;
		}
	}
}
</style>
