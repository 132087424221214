<template>
  <v-dialog v-model="isOpen" max-width="500px">
    <v-card v-if="taxonomyVersion">
      <v-card-title>
        <span class="headline">Download Taxonomy Version</span>
      </v-card-title>
      <v-card-text v-if="taxonomyVersion">
        <p>Version: {{ taxonomyVersion.name }}</p>
        <p v-if="error">
          {{ error }}
        </p>
        <div class="actions">
          <v-btn @click="showDialog(false)">Cancel</v-btn>
          <v-btn @click="download" :disabled="!canSubmit">Download</v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { downloadTaxonomyVersionStore } from '../../../store/modules/admin/dialog/download-taxonomy-version'
import ontologyStore from '../../../store/modules/admin/ontology'

export default {
  name: 'DownloadTaxonomyVersionDialog',
  computed: {
    ...downloadTaxonomyVersionStore.mapState({
      canSubmit: (state) => state.reference !== null && !state.error,
      error: (state) => state.error,
    }),
    ...downloadTaxonomyVersionStore.mapAccessors({
      isOpen: 'dialogIsOpen',
    }),
    ...ontologyStore.mapGetters({
      taxonomyVersion: 'selectedTaxonomyVersion',
    }),
  },
  methods: {
    ...downloadTaxonomyVersionStore.mapActions({
      showDialog: 'setDialogIsOpen',
      download: 'downloadTaxonomyVersionCsv',
    }),
  },
}
</script>

<style>
.actions {
  display: flex;
  justify-content: space-between;
}
</style>
