<template>
	<v-container fluid class="help-screencasts">
		<survey-header :cant-save="true" />
		<div class="help-screencasts-content">
			<div class="help-screencasts-content-list">
				<div class="actions">
					<div class="actions-back">
						<a href="#" @click="goBack($event)">go back</a>
					</div>
					<div class="actions-FAQ">
						<a :href="FAQLocation" download>Download the FAQ</a>
					</div>
					<div class="actions-taxonomy">
						<a :href="businessTaxonomyLocation" download>Download the Business Taxonomy</a>
					</div>
					<div v-if="currentUser.isAdmin" class="actions-admin-doc">
						<a :href="adminDocumentationLocation" download>Download the Admin documentation </a>
					</div>
				</div>
				<h1 class="help-screencasts-content-list-title">
					Select a topic to learn more
				</h1>
				<v-layout v-for="video in videos" :key="video" row wrap>
					<a
						:class="{ active: video === currentVideo }"
						class="help-screencasts-content-list-element"
						@click="switchVideo(video)"
					>{{ video }}</a>
				</v-layout>
			</div>
			<div class="help-screencasts-content-line" />
			<div class="help-screencasts-content-videocontainer">
				<video
					:src="videoURL"
					controls
					class="help-screencasts-content-videocontainer-video"
				/>
			</div>
		</div>
	</v-container>
</template>

<script>
/* eslint-disable global-require */
import SurveyHeader from '../../components/user/survey/SurveyHeader.vue'
import { api } from '../../api/api-service'
import { mapState } from 'vuex'

export default {
	name: 'HelpPage',
	components: { SurveyHeader },
	data() {
		return {
			currentVideo: 'Introduction and Home Page.mp4',
			videoURL: '',
			videos: [],
			FAQLocation: '/documents/Software Survey Web Application FAQ.pdf',
			adminDocumentationLocation: '/documents/Software Survey Web Application - Admin documentation.pdf',
			businessTaxonomyLocation: '/documents/2024 Business taxonomy.xlsx',
		}
	},
	computed: {
		...mapState({
			currentUser: (state) => state.login.currentUser
		})
	},
	created() {
		this.videos = [
			'Introduction and Home Page.mp4',
			'User Interface.mp4',
			'Business Capability Taxonomy.mp4',
			'Downloading Survey Data.mp4',
			'Adding a System.mp4',
			'Reclassifying a System.mp4',
			'Deleting a System.mp4',
			'Editing System Attributes.mp4',
			'Saving and Submitting Data.mp4',
			'Importing Systems.mp4',
		]
	},
	methods: {
		async getVideoURL() {
			const signedVideoURL = await api.getPreSignedUrl({
        		asset: this.currentVideo,
      		})
			this.videoURL = signedVideoURL
		},
		switchVideo(video) {
			this.currentVideo = video
			this.getVideoURL()
		},
		goBack(event) {
			event.preventDefault()
			history.back()
		}
	},
	mounted() {
    	this.getVideoURL();
  	}
}
</script>

<style lang="scss">
@import '../../assets/scss/pages/user/help/screencasts';
.active {
	font-weight: bold;
	font-size: 110%;
}
.actions {
	margin-bottom: 2em;
	display: flex;
	justify-content: space-between;
	&-back {
		font-size: 100%;
	}
	&-FAQ {
		font-size: 100%;
		margin-right: 20px;
		font-weight: bold;
	}
	&-admin-doc, &-taxonomy {
		font-size: 100%;
		margin-right: 20px;
		margin-left: auto;
		font-weight: bold;
		text-align: right;
	}
}
</style>
