<template>
	<div
		:style="{
			left: `${((index + 0.5) / count) * 100}%`,
			height: `calc(${(dataset.count / maxValue) * 100}% - ${
				(dataset.count / maxValue) * 10
			}px)`,
			width: `${80 / count}%`,
		}"
		class="bar-chart-bar-holder"
		@mouseover="mouseover"
		@mouseout="mouseout"
	>
		<div
			:style="{ background: color }"
			:class="highlightClass"
			class="bar-chart-bar"
			@click="$emit('click')"
		/>
		<div
			:class="{ hideLabel, [highlightClass]: true }"
			class="bar-chart-bar-label"
		>
			<slot :dataset="dataset">
				{{ dataset.count }}
			</slot>
		</div>
	</div>
</template>

<script>
import { Dataset } from '../../../lib/dataset'

const CHART_HEIGHT = 340
const MIN_LABEL_HEIGHT = 30

export default {
	name: 'BarChartBar',
	props: {
		index: {
			type: Number,
			required: true,
		},
		count: {
			type: Number,
			required: true,
		},
		dataset: {
			type: Dataset,
			required: true,
		},
		maxValue: {
			type: Number,
			required: true,
		},
		color: {
			type: String,
			default: 'grey',
		},
		highlight: {
			type: String,
			default: null,
		},
	},
	computed: {
		hideLabel() {
			return (
				(CHART_HEIGHT / this.maxValue) * this.dataset.count < MIN_LABEL_HEIGHT
			)
		},
		highlightClass() {
			if (this.highlight) {
				return this.highlight === this.dataset.key ? 'selected' : 'deselected'
			} else {
				return ''
			}
		},
	},
	methods: {
		mouseover() {
			this.$emit('select', this.dataset.key)
		},
		mouseout() {
			this.$emit('deselect', this.dataset.key)
		},
	},
}
</script>
