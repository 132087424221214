import { render, staticRenderFns } from "./AdminSurveys.vue?vue&type=template&id=12bd89f6&scoped=true"
import script from "./AdminSurveys.vue?vue&type=script&lang=js"
export * from "./AdminSurveys.vue?vue&type=script&lang=js"
import style0 from "./AdminSurveys.vue?vue&type=style&index=0&id=12bd89f6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12bd89f6",
  null
  
)

export default component.exports