<template>
	<div
		:style="{ left: `${(index + 0.5) / count * 100}%`, height: `calc(${dataset.count / maxValue * 100}% - ${dataset.count / maxValue * 10}px)`, width: `${80 / count}%` }"
		class="bar-chart-bar-holder"
	>
		<div
			:class="highlightClass"
			class="bar-chart-bar"
		>
			<div
				v-for="stack in stacks"
				:key="stack.key"
				:style="{ background: colorFor(stack.key), height: `${100 * stack.count / dataset.count}%`, top: `${100 * stack.offset / dataset.count}%` }"
				:class="highlightClassForStack(stack.key)"
				class="bar-chart-stacked-bar"
				@mouseover="mouseover(stack.key)"
				@mouseout="mouseout(stack.key)"
				@click="$emit('click')"
			/>
		</div>
		<div
			v-for="stack in stacks"
			:key="stack.key"
			:class="{ hidden: hideLabel(stack.count), [highlightClassForStackLabel(stack.key)]: true }"
			:style="{ top: `${100 * (stack.offset + stack.count / 2) / dataset.count}%` }"
			class="bar-chart-bar-label"
		>
			<slot :dataset="stack">
				{{ stack.count }}
			</slot>
		</div>
	</div>
</template>

<script>
const CHART_HEIGHT = 340
const MIN_LABEL_HEIGHT = 30

import { Dataset } from '../../../lib/dataset'

export default {
	props: {
		index: {
			type: Number,
			required: true,
		},
		count: {
			type: Number,
			required: true,
		},
		dataset: {
			type: Dataset,
			required: true,
		},
		maxValue: {
			type: Number,
			required: true,
		},
		property: {
			type: String,
			required: true,
		},
		colorFor: {
			type: Function,
			default: () => 'grey',
		},
		highlight: {
			type: Array,
			default: null,
		},
	},
	computed: {
		stacks() {
			return this.dataset.groupBy(this.property)
		},
		highlightClass() {
			if (this.highlight && this.highlight[0] !== null) {
				return this.highlight[0] === this.dataset.key ? 'selected' : 'deselected'
			} else {
				return ''
			}
		},
	},
	methods: {
		hideLabel(value) {
			return CHART_HEIGHT / this.maxValue * value < MIN_LABEL_HEIGHT
		},
		highlightClassForStack(value) {
			if (this.highlight && this.highlight[0] === null) {
				return (this.highlight[0] === null || this.highlight[0] === this.dataset.key) && this.highlight[1] === value ? 'selected' : 'deselected'
			} else {
				return ''
			}
		},
		highlightClassForStackLabel(value) {
			if (this.highlight) {
				return (this.highlight[0] === null || this.highlight[0] === this.dataset.key) && this.highlight[1] === value ? 'selected' : 'deselected'
			} else {
				return ''
			}
		},
		mouseover(value) {
			this.$emit('select', value)
		},
		mouseout(value) {
			this.$emit('deselect', value)
		},
	},
}
</script>
