<template>
  <v-select
    :items="taxonomyVersions"
    :value="selectedTaxonomyVersion"
    item-text="label"
    item-value="id"
    @input="pickTaxonomyVersion($event)"
    return-object
  >
  </v-select>
</template>

<script>
import { mapActions } from 'vuex'
import ontologyStore from '../../../store/modules/admin/ontology'

export default {
  name: 'TaxonomyVersionSelector',
  computed: {
    ...ontologyStore.mapState({
      taxonomyVersions: (state) => state.taxonomyVersions
        .sort((a, b) => a.createdAt - b.createdAt)
        .map(label),
      selectedTaxonomyVersion: (state) =>
        state.selectedTaxonomyVersion
          ? label(state.selectedTaxonomyVersion)
          : null,
    }),
  },
  watch: {
    selectedTaxonomyVersion: {
      handler(newVal, oldVal) {
        if (newVal !== null && oldVal === null) {
          this.pickTaxonomyVersion(newVal)
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('ontology', {
      pickTaxonomyVersion: 'pickTaxonomyVersion',
    }),
  },
}

/** @param {Model.TaxonomyVersion} taxonomyVersion */
function label(taxonomyVersion) {
  return {
    ...taxonomyVersion,
    label: taxonomyVersion?.name + (taxonomyVersion?.draft ? ' (draft)' : ''),
  }
}
</script>
