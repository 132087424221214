<template>
	<v-dialog
		v-model="newSystemDialog"
		:disabled="isReadOnly"
		max-width="500px"
		style="margin-left: 20px; "
		persistent
		@keydown.esc="cancelNewSystem"
	>
		<v-btn
			slot="activator"
			:disabled="isReadOnly"
			:class="{ disabled: isReadOnly }"
			color="primary"
			dark
			class="button"
			style="position: relative; top: -1px;"
		>
			Add system
		</v-btn>
		<v-card>
			<v-card-title>
				<span class="headline">Add system</span>
			</v-card-title>
			<v-card-text>
				<v-container grid-list-md>
					<v-layout wrap="">
						<v-flex xs12>
							<v-combobox
								v-if="nameValues"
								ref="newSystemCombobox"
								v-model="newSurveyItem.name"
								:items="nameValues"
								:menu-props="{ contentClass: showComboMenu ? '' : 'hide' }"
								:error-messages="checkEmpty ? '' : 'system name cannot be blank'"
								style="margin-bottom: 0.5em;"
								class="survey-input-newSys combobox"
								label="system name"
								@input.native="checkEmpty=$event.srcElement.value"
								@keyup="keyup($event)"
								@click.stop=""
							/>
							<v-text-field
								v-else
								v-model="newSurveyItem.name"
								style="margin-bottom: 0.5em;"
								label="system name"
								@keyup.enter.native="addNewSystem"
							/>
							<ontology-select
								v-model="newSurveyItem.ontologyItem"
								:ontology="ontology"
								:systems-by-process-id="systemsByProcessId"
								:closed="true"
								:open="openOntologySelect"
								:hide-select-button="Boolean(newSurveyItem.ontologyItem)"
								@toggleOpen="openOntologySelect = $event"
							/>
						</v-flex>
					</v-layout>
				</v-container>
			</v-card-text>
			<v-card-actions>
				<v-spacer />
				<v-btn v-if="newSurveyItem.ontologyItem" color="blue darken-1" flat @click.native="openOntologySelect = true">
					Reselect business capability
				</v-btn>
				<v-btn color="blue darken-1" flat @click.native="cancelNewSystem">
					Cancel
				</v-btn>
				<v-btn :disabled="Boolean(!checkEmpty || !newSurveyItem.ontologyItem)" color="blue darken-1" flat @click.native="addNewSystem">
					Add
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import OntologySelect from './OntologySelect'

export default {
	components: { OntologySelect },
	props: {
		nameValues: {
			type: Array,
			required: false,
			default: null,
		},
		selectedOntologyItem: {
			type: Object,
			required: true,
			default: null,
		},
		ontology: {
			type: Object,
			required: true,
			default: null,
		},
		systemsByProcessId: {
			type: Object,
			required: true,
		},
		businessUnit: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			newSurveyItem: {
				name: '',
				ontologyItem: null,
			},
			newSystemDialog: false,
			showComboMenu: false,
			checkEmpty: '',
			openOntologySelect: false,
		}
	},
	computed: {
		...mapGetters('userSurveys', {
			isReadOnly: 'isReadOnly',
		}),
	},
	watch: {
		// selectedOntologyItem(ontologyItem) {
		// 	this.newSurveyItem.ontologyItem = ontologyItem
		// },
		newSystemDialog(value) {
			if (value) {
				requestAnimationFrame(() => {
					return this.$refs.newSystemCombobox.focus()
				})
			}
		},
	},
	methods: {
		addNewSystem() {
			this.newSystemDialog = false
			this.showComboMenu = false
			this.checkEmpty = ''

			if (this.newSurveyItem.name == null) {
				this.newSurveyItem.name = ''
			}

			setTimeout(() => {
				this.$emit('new', this.newSurveyItem)
				this.newSurveyItem = { name: '', ontologyItem: null }
			// eslint-disable-next-line no-magic-numbers
			}, 100)
		},
		cancelNewSystem() {
			this.newSystemDialog = false
			this.showComboMenu = false
			this.checkEmpty = ''

			setTimeout(() => {
				this.newSurveyItem = { name: '', ontologyItem: null }
			// eslint-disable-next-line no-magic-numbers
			}, 100)
		},
		keyup(e) {
			if (!this.showComboMenu && e.key === 'Enter' && Boolean(this.checkEmpty)) {
				this.addNewSystem()
			}

			this.showComboMenu = e.target.value !== this.newSurveyItem.name && Boolean(e.target.value)
		},

	},
}
</script>

<style>

</style>
