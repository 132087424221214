<template>
	<v-container fluid class="survey-overview">
		<survey-header :cant-save="true" />
		<div
			v-if="closedSurveysLoading || allSurveysLoading"
			class="survey-overview-content-loading"
		>
			<v-progress-circular
				:size="70"
				:width="7"
				color="primary"
				indeterminate
				class="survey-overview-loading"
			/>
		</div>
		<div v-else class="survey-overview-content">
			<div class="survey-overview-current">
				<h1 class="survey-overview-title">
					Open surveys
				</h1>
				<div class="survey-overview-current-content">
					<v-layout v-for="survey in startedSurveys" :key="survey.id" row wrap>
						<v-flex v-if="survey.status === 'started' && showDraft(survey)" xs12>
							<h2 class="subtitle-layout">
								{{ survey.name }}<span v-if="survey.isDraft"> (draft)</span>
							</h2>
							<div class="survey-overview-current-businessunits">
								<v-select
									:items="survey.rootOntologyItems"
									class="survey-overview-current-businessunits-select"
									item-text="name"
									v-model="selectedSurveyBusinessUnit"
									item-value="processId"
									placeholder="Select a capability level 1 to open"
									@change="navigateToBusinessUnit(selectedSurveyBusinessUnit, survey.id)"
								></v-select>
							</div>
						</v-flex>
					</v-layout>
					<p
						v-if="startedSurveys.length <= 0"
						class="survey-overview-no-content"
					>
						No open surveys found
					</p>
				</div>
				<h1 class="survey-overview-title">
					Past surveys
				</h1>
				<div v-if="selectableClosedSurveys.length > 0" class="survey-overview-past-content">
					<div class="survey-overview-past-surveyTemplates">
						<v-select
							:items="selectableClosedSurveys"
							class="survey-overview-past-surveyTemplates-select"
							item-text="name"
							v-model="selectedPastSurveyId"
							item-value="id"
							placeholder="Select a past survey"
							clearable
						></v-select>
					</div>
					<div v-if="selectedPastSurveyId" class="survey-overview-past-businessunits">
						<v-select
							:items="pastSurveyIdBusinessUnits"
							class="survey-overview-past-businessunits-select"
							item-text="name"
							v-model="selectedPastSurveyBusinessUnit"
							item-value="processId"
							placeholder="Select a business capability level 1 to open"
							@change="navigateToBusinessUnit(selectedPastSurveyBusinessUnit, selectedPastSurveyId)"
						></v-select>
					</div>
					<p
						v-if="selectableClosedSurveys.length <= 0"
						class="survey-overview-no-content"
					>
						No past surveys found
					</p>
				</div>
			</div>
			<div class="survey-overview-line" />
			<div class="survey-overview-download">
				<div class="survey-overview-header">
					<h1 class="survey-overview-title">
						Download data
					</h1>
					<div class="survey-overview-header-checkbox">
						<v-checkbox
							v-if="currentUser.isAdmin"
							v-model="downloadForCurrentTaxVersion"
							label="Map to current taxonomy version"
							color="primary"
							class="survey-overview-checkbox"
							@change="clickedCurrentTaxVersionCheckBox()"
						/>
					</div>
				</div>
				<v-layout row wrap>
					<v-flex xs12>
						<h2 class="subtitle-layout" style="margin-bottom: 20px">
							Select past surveys for download
						</h2>
						<h3 class="survey-overview-note">
							Note you will get data from all companies
						</h3>
						<div class="survey-overview-checkbox-group">
							<v-checkbox
								v-if="showDraft(survey)"
								v-for="survey in selectableClosedSurveys"
								:key="survey.id"
								v-model="surveyClosedDownloadSelections"
								:label="survey.name"
								:value="survey.name"
								color="primary"
								class="survey-overview-checkbox"
								@change="toggleClosedSurveyId(survey)"
							/>
						</div>
						<h2 class="subtitle-layout" style="margin-bottom: 20px">
							Select current surveys for download
						</h2>
						<h3 class="survey-overview-note">
							Note you will only get data for your company
						</h3>
						<div class="survey-overview-checkbox-group">
							<v-checkbox
								v-if="showDraft(survey)"
								v-for="survey in openSurveys"
								:key="survey.id"
								v-model="surveyOpenDownloadSelections"
								:label="survey.name"
								:value="survey.name"
								color="primary"
								class="survey-overview-checkbox"
								@change="toggleOpenSurveyId(survey)"
							/>
						</div>
						<div>
							<v-btn
								v-if= "canDownload()"
								:href="downloadUrl"
								:disabled="downloaded"
								:class="{ disabled: downloaded }"
								class="button"
								@click="downloaded = true"
							>
								Download
							</v-btn>
						</div>
					</v-flex>
					<v-flex
						v-if="
							selectedClosedSurveyIds.length == 0 &&
								selectedOpenSurveyIds.length == 0
						"
					>
						<!-- We disabled this for now as it does not work with the taxonomy versions-->
						<!-- <div>
							<h1 class="survey-overview-title" style="margin-top: 10px">
								Visualize data
							</h1>
						</div>
						<div>
							<router-link to="/graphs" class="inline-link survey-header-link">
								To Visualization page
							</router-link>
						</div> -->
					</v-flex>
				</v-layout>
			</div>
		</div>
	</v-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { buildUrl } from '../../api/api_helper.js'
import SurveyHeader from '../../components/user/survey/SurveyHeader.vue'

export default {
	name: 'SurveysView',
	components: { SurveyHeader },
	data() {
		return {
			selectedClosedSurveyIds: [],
			selectedOpenSurveyIds: [],
			downloaded: false,
			allSurveysLoading: false,
			closedSurveysLoading: false,
			surveyOpenDownloadSelections: [],
			surveyClosedDownloadSelections: [],
			selectedSurveyBusinessUnit: null,
			selectedPastSurveyBusinessUnit: null,
			selectedPastSurveyId: null,
			downloadForCurrentTaxVersion: false,
		}
	},
	computed: {
		...mapState({
			surveys: (state) => state.userSurveys.all,
			currentCompany: (state) => state.login.currentUser.company,
			currentUser: (state) => state.login.currentUser,
			surveyDomains: (state) => state.surveyDomain.all,
			closedSurveys: (state) => state.userSurveys.closed,
		}),
		...mapGetters({
			startedSurveys: 'userSurveys/startedSurveys'
		}),
		downloadUrl() {
			const params = {
				surveyTemplateIds:
					this.selectedClosedSurveyIds.length > 0
						? this.selectedClosedSurveyIds
						: this.selectedOpenSurveyIds,
				businessUnitId: 'all',
				companyIds:
					this.selectedOpenSurveyIds.length > 0
						? [this.currentCompany.id]
						: 'all',
				mapToCurrentTaxVersion: this.downloadForCurrentTaxVersion,
			}

			return buildUrl('/export', params)
		},
		selectableClosedSurveys() {
			if (this.closedSurveys){
				if (this.currentUser.isAdmin) {
					return this.closedSurveys.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
				} else {
					return this.closedSurveys.filter((surveyTemplate) => {
						const submitted = surveyTemplate.mySurveys.filter(
							(survey) =>
								survey.hasBeenSubmitted
						)[0]
						return submitted
					}).sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
				}
			}
			else {
				return []
			}
		},
		openSurveys() {
			return this.surveys
				.filter((survey) => survey.status === 'started')
				.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
		},
		pastSurveyIdBusinessUnits(){
			if (this.selectedPastSurveyId){
				const survey = this.closedSurveys.find(survey => survey.id === this.selectedPastSurveyId)
				return survey.rootOntologyItems
			}
			else {
				return []
			}
		},
		draftSurveys() {
			return this.surveys.filter((survey) => survey.isDraft)
		}
	},
	created() {
		this.allSurveysLoading = true
		this.closedSurveysLoading = true
		this.loadSurveyViewData()
	},
	methods: {
		toggleClosedSurveyId(survey) {
			this.downloaded = false
			this.selectedOpenSurveyIds = []
			this.surveyOpenDownloadSelections = []

			if (this.selectedClosedSurveyIds.indexOf(survey.id) > -1) {
				this.selectedClosedSurveyIds = this.selectedClosedSurveyIds.filter(
					(element) => element !== survey.id,
				)
			} else {
				this.selectedClosedSurveyIds.push(survey.id)
			}
		},
		toggleOpenSurveyId(survey) {
			this.downloaded = false
			this.selectedClosedSurveyIds = []
			this.surveyClosedDownloadSelections = []

			if (this.selectedOpenSurveyIds.indexOf(survey.id) > -1) {
				this.selectedOpenSurveyIds = this.selectedOpenSurveyIds.filter(
					(element) => element !== survey.id,
				)
			} else {
				this.selectedOpenSurveyIds.push(survey.id)
			}
		},
		showDraft(survey) {
			if (this.currentUser.isAdmin) {
				return true
			} else if (survey.isDraft) {
				return false
			} else {
				return true
			}
		},
		navigateToBusinessUnit(businessUnit, surveyId) {
    		this.$router.push(`/surveys/${surveyId}/${businessUnit}`);
  		},
		clickedCurrentTaxVersionCheckBox() {
			this.downloaded = false
		},
		canDownload() {
			// Cannot download when there is nothing selected or when a draft version is selected and you want to download for the current tax version.
			// This is because we do not support forward mapping of taxonomy versions
			if (this.downloadForCurrentTaxVersion) {
				const selectedClosedSurveyDraft = this.selectedClosedSurveyIds.some(
					(surveyId) =>
						this.draftSurveys.find((survey) => survey.id === surveyId)
				)
				const slectedOpenSurveyDraft = this.selectedOpenSurveyIds.some(
					(surveyId) =>
						this.draftSurveys.find((survey) => survey.id === surveyId)
				)
				return (this.selectedClosedSurveyIds.length > 0 || this.selectedOpenSurveyIds.length > 0) && !selectedClosedSurveyDraft && !slectedOpenSurveyDraft
			} else {
				return this.selectedClosedSurveyIds.length > 0 || this.selectedOpenSurveyIds.length > 0
			}
		},
		async loadSurveyViewData() {
			try {
				await this.$store.dispatch('surveyDomain/loadSurveyDomains')
				await this.$store.dispatch('userSurveys/getAllSurveys', {
					surveyDomainId: this.surveyDomains[0].id,
				})
				await this.$store.dispatch('userSurveys/getClosedSurveys')
			} catch (error) {
				console.error("Failed to load survey view data:", error);
			} finally {
				this.allSurveysLoading = false
				this.closedSurveysLoading = false
			}
		},
	},
}
</script>

<style lang="scss">
@import '../../assets/scss/pages/user/survey/overview';
</style>
