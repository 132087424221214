import gql from 'graphql-tag'
import graphqlClient from '../../api/db'
import { createStoreUtils } from '../../lib/vuex'

/**
 * @typedef {Store.SurveyDomain.State} State
 * @typedef {typeof getters} Getters
 * @typedef {typeof mutations} Mutations
 * @typedef {typeof actions} Actions
 */

/**
 * @template {any} Payload [Payload=void]
 * @typedef {MutationFor<State, Payload>} Mutate
 */

/**
 * @template {any} Payload [Payload=void]
 * @typedef {ActionFor<Mutations, State, Payload, void>} Act
 */

/** @type {Store.SurveyDomain.LocalState}  */
const initialState = {
  all: [],
}

/** @satisfies {GettersFor<State>} */
const getters = {
  surveyDomains(state) {
    return state.all
  },
}

/** @satisfies {MutationsFor<State>} */
const mutations = {
  /** @type {Mutate<{surveyDomains: Model.SurveyDomain[]}>}} */
  storeSurveyDomains(state, { surveyDomains }) {
    state.all = surveyDomains
  },
}

/** @satisfies {ActionsFor<Mutations, State>} */
const actions = {
  /** @type {Act<void>} */
  async loadSurveyDomains({ commit }) {
    const response = await graphqlClient
      .query({
        query: gql`
          query SurveyDomains {
            surveyDomains {
              id
              name
            }
          }
        `,
      })
      .catch((e) => {
        console.error(e)
      })

    commit('storeSurveyDomains', {
      surveyDomains: response.data.surveyDomains,
    })
  },
}

export const surveyDomainStore = {
  namespaced: true,
  ...createStoreUtils('surveyDomain', {
    state: initialState,
    getters,
    actions,
    mutations,
  }),
}

export default surveyDomainStore
