<template>
	<v-dialog
		v-model="open"
		height="90%"
		persistent
		@keydown.esc="open = false"
	>
		<v-btn
			slot="activator"
			color="primary"
			dark
			class="button"
			style="position: relative; top: -1px;"
			@click.native="openWindow()"
		>
			Navigate business capability taxonomy
		</v-btn>
		<v-card>
			<v-card-title>
				<span class="headline">Business Capability Taxonomy Browser</span>
			</v-card-title>
			<v-card-text>
				<v-container grid-list-md>
					<v-layout wrap="">
						<v-flex xs12>
							<ontology-browser :ontology="ontology" :systems-by-process-id="systemsByProcessId" :closed="!open" size="medium" />
						</v-flex>
					</v-layout>
				</v-container>
			</v-card-text>
			<v-card-actions>
				<v-spacer />
				<v-btn color="blue darken-1" flat @click.native="closeWindow()">
					Close
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import OntologyBrowser from './OntologyBrowser'

export default {
	components: { OntologyBrowser },
	props: {
		ontology: {
			type: Object,
			required: true,
			default: null,
		},
		systemsByProcessId: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			open: false,
		}
	},
	methods: {
		closeWindow() {
			this.open = false
		},
		openWindow() {
			this.closed = false
		},
	},
}
</script>

<style>

</style>
