<template>
  <v-select
    :items="taxonomyVersions"
    v-model="selectedTaxonomyVersion"
    :label="label || 'Taxonomy Version'"
    item-text="name"
    item-value="id"
    return-object
  >
  </v-select>
</template>

<script>
import ontologyStore from '../../../store/modules/admin/ontology'

export default {
  name: 'TaxonomyVersionSelector2',
  props: ['selectedTaxonomyVersion', 'label'],
  data() {
    return {
      /** @type {null | Model.TaxonomyVersion} */
      selectedTaxonomyVersion: null,
    }
  },
  computed: {
    ...ontologyStore.mapState({
      taxonomyVersions: (state) => state.taxonomyVersions,
    }),
  },
}
</script>
