<template>
	<v-container fluid class="reset_password">
		<v-layout row wrap>
			<div class="table-header">
				<div v-if="validating">
					Validating token...
				</div>
				<div v-if="!validating && !user">
					Invalid token
				</div>
				<v-dialog v-if="user" v-model="passwordDialog" max-width="500px">
					<v-btn slot="activator" color="primary" dark class="mb-2 button">
						set password for {{ user.email }}
					</v-btn>
					<v-card>
						<v-card-title>
							<span class="headline">Set password for {{ user.email }}</span>
						</v-card-title>
						<v-card-text>
							<v-container grid-list-md>
								<v-layout wrap="">
									<v-flex xs12>
										<v-text-field
											v-model="password"
											:append-icon="show1 ? 'visibility_off' : 'visibility'"
											:type="show1 ? 'text' : 'password'"
											label="password"
											@click:append="show1 = !show1"
										/>
									</v-flex>
								</v-layout>
							</v-container>
						</v-card-text>
						<v-card-actions>
							<v-spacer />
							<v-btn color="blue darken-1" flat @click.native="close">
								Cancel
							</v-btn>
							<v-btn color="blue darken-1" flat @click.native="resetPassword">
								Set password
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
			</div>
		</v-layout>
	</v-container>
</template>

<script>
import { mapActions } from 'vuex'

export default {
	name: 'ResetPassword',
	props: {
		token: { type: String, required: true },
	},
	data() {
		return {
			passwordDialog: false,
			password: null,
			show1: false,
			validating: true,
			user: null,
		}
	},
	created() {
		this.doValidateToken(this.token).then((user) => {
			this.validating = false
			this.user = user
		})
	},
	methods: {
		...mapActions('login', {
			doResetPassword: 'resetPassword',
			doValidateToken: 'validateToken',
		}),

		close() {
			this.passwordDialog = false
		},

		resetPassword() {
			this.doResetPassword({ token: this.token, password: this.password }).then((success) => {
				if (success) {
					this.$router.replace('/')
				}
			})
		},
	},
}

</script>
