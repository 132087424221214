<template>
	<v-container fluid class="graphs-overview">
		<survey-header :cant-save="true" />
		<div v-if="loading" class="graphs-overview-content-loading">
			<v-progress-circular
				:size="70"
				:width="7"
				color="primary"
				indeterminate
				class="graph-loading"
				style="margin: auto; margin-bottom: 25px"
			/>
			<h1 class="title-layout">
				Loading data
			</h1>
			<h1 class="title-layout">
				This can take several minutes
			</h1>
		</div>
		<div v-else class="graphs-overview-content">
			<full-screen-toggle />
			<div v-if="filterDefs" class="graphs-overview-filter-container">
				<div class="chart-filter">
					<check-box v-model="usePercentages">
						Use percentages
					</check-box>
				</div>
				<dataset-filters
					:dataset="dataset"
					:filter-defs="filterDefs"
					:color-cache="colorCache"
					@update="updateDataset($event)"
				/>
			</div>
			<div class="graphs-overview-graphs-container">
				<!-- When adding new graphs, the following header should be reworked into a selection fielf -->
				<h1 class="title-layout">
					Vendor details
				</h1>
				<div v-if="extraFilterDefs" class="graphs-overview-extra-filter-container">
					<dataset-filters
						:dataset="filteredDataset"
						:filter-defs="extraFilterDefs"
						:color-cache="colorCache"
						:show-labels="false"
						inline
						@update="updateExtraDataset($event)"
					/>
				</div>
				<div class="graphs-overview-graphs">
					<block-chart
						:dataset="extraFilteredDataset"
						:color-cache="colorCache"
						:filter-defs="blockFilterDefs"
						horizontal-filter-defs
						property="Vendor name"
						@select="selectVendor($event)"
					>
						<template slot="caption" slot-scope="{ count }">
							{{ count }} vendor{{ count === 1 ? "" : "s" }} in selection
						</template>
						<template slot="label" slot-scope="{ dataset }">
							<template v-if="dataset.key">
								<span>{{ splitName(dataset.key) }}</span>
							</template>
							<template v-else>
								<i>unspecified</i>
							</template>
						</template>
						<template slot="label-extra" slot-scope="{ dataset }">
							<span v-if="usePercentages">{{ formatPercentage(dataset.count, dataset.total) }} of systems</span>
							<span v-else>{{ dataset.count }} system{{ dataset.count === 1 ? "" : "s" }}</span>
						</template>
					</block-chart>
					<bar-chart
						v-if="selectedVendor !== null"
						:dataset="datasetFilteredByVendor"
						:color-cache="colorCache"
						:stack-property="stackProperty"
						:bar-property="barProperty"
					>
						<!-- eslint-disable-next-line vue/no-unused-vars -->
						<template slot="caption" slot-scope="{ count }">
							{{ barProperty }} for <span v-if="selectedVendor">{{ selectedVendor }}</span><i v-else>unspecified vendor</i>
							<!-- {{ count }} {{ pluralizeProperty(count, barProperty) }} for <span v-if="selectedVendor">{{ selectedVendor }}</span><i v-else>unspecified vendor</i> -->
							({{ datasetFilteredByVendor.count }} system{{
								datasetFilteredByVendor.count === 1 ? "" : "s"
							}}
							cross business levels)
						</template>
						<template slot="header">
							<combo-box v-model="barProperty" :values="barProperties" :sort="sort" />
							<check-box
								:value="stackProperty ? useStacked : false"
								:read-only="barProperty === 'Business capability level 4'"
								@input="useStacked = $event"
							>
								Stacked bars with deeper business capability level
							</check-box>
						</template>
						<template slot="tick" slot-scope="{ value, total }">
							{{
								usePercentages
									? formatPercentage(
										value,
										usePercOfTotal ? fullDatasetFilteredByVendor.count : total
									)
									: value
							}}
						</template>
						<template slot="value" slot-scope="{ dataset }">
							{{
								usePercentages
									? formatPercentage(
										dataset.count,
										usePercOfTotal ? fullDatasetFilteredByVendor.count : dataset.total
									)
									: dataset.count
							}}
						</template>
					</bar-chart>
				</div>
			</div>
		</div>
	</v-container>
</template>

<script>
import { mapState } from 'vuex'
import BarChart from '../../components/user/graphs/BarChart.vue'
import BlockChart from '../../components/user/graphs/BlockChart.vue'
import CheckBox from '../../components/user/graphs/CheckBox.vue'
import ComboBox from '../../components/user/graphs/ComboBox.vue'
import DatasetFilters from '../../components/user/graphs/DatasetFilters.vue'
import FullScreenToggle from '../../components/user/graphs/FullScreenToggle.vue'
import SurveyHeader from '../../components/user/survey/SurveyHeader.vue'
import { ColorCache } from '../../lib/color_cache'
import { formatPercentage, sortAs } from '../../lib/util'

export default {
	name: 'GraphsView',
	components: {
		SurveyHeader,
		BarChart,
		BlockChart,
		DatasetFilters,
		FullScreenToggle,
		CheckBox,
		ComboBox,
	},
	data() {
		const levels = 4; // Define the number of levels. TO-DO get this from the BE on a per-survey basis
    	const properties = Array.from({ length: levels }, (_, i) => `Business capability level ${i + 1}`)
		const blockFilterDefs = [
			{
				type: 'previousButton',
				properties: properties,
			},
			...properties.map((property, index) => ({
				type: 'dropdown',
				property: property,
				includeAll: true,
				forceAllProperty: index > 0 ? properties[index - 1] : undefined,
				defaultNotAll: true,
			})),
			{
				type: 'nextButton',
				properties: properties,
			},
		]
		return {
			levels,
			extraFilterDefs: null,
			filteredDataset: null,
			extraFilteredDataset: null,
			selectedVendor: null,
			blockFilterDefs,
			useStacked: true,
			usePercentages: false,
			usePercOfTotal: false,
			barProperty: 'Business capability level 1',
			barProperties: properties
		}
	},
	computed: {
		...mapState({
			loading: (state) => state.userGraphs.loading,
			dataset: (state) => state.userGraphs.dataset,
			surveyTemplates: (state) => state.userGraphs.surveyTemplates,
		}),
		surveyNames() {
			return this.surveyTemplates
				? this.surveyTemplates
					.slice(0)
					.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))
					.map((s) => s.name)
				: []
		},
		colorCache() {
			return new ColorCache(this.dataset)
		},
		datasetFilteredByVendor() {
			return this.extraFilteredDataset.applyFilter({
				'Vendor name': { [this.selectedVendor]: true },
			})
		},
		fullDatasetFilteredByVendor() {
			return this.filteredDataset.applyFilter({
				'Vendor name': { [this.selectedVendor]: true },
			})
		},
		sort() {
			return sortAs(this.barProperties)
		},
		stackProperty() {
			if (this.useStacked) {
				const levelMappings = {};
				for (let i = 1; i < this.levels; i++) {
					levelMappings[`Business capability level ${i}`] = `Business capability level ${i + 1}`;
				}
				return levelMappings[this.barProperty];
			} else {
				return null
			}
		},
		filterDefs() {
			return [
				{
					type: 'dropdown',
					property: 'Survey',
					single: true,
					sort: sortAs(this.surveyNames),
				},
				{
					type: 'list',
					property: 'Company',
					showControls: true,
				},
			]
		},
	},
	watch: {
		dataset(dataset) {
			this.filteredDataset = dataset
			this.extraFilteredDataset = dataset
		},
	},
	created() {
		this.$store.dispatch('userGraphs/loadData')
	},
	methods: {
		updateDataset(dataset) {
			this.filteredDataset = dataset
			this.extraFilteredDataset = dataset
		},
		updateExtraDataset(dataset) {
			this.extraFilteredDataset = dataset
		},
		selectVendor(vendor) {
			this.selectedVendor = vendor
		},
		formatPercentage(...a) {
			return formatPercentage(...a)
		},
		splitName(vendorName) {
			return vendorName.replace(/ /g, '<br>')
		},
	},
}
</script>

<style lang="scss">
@import "../../assets/scss/pages/user/graphs/index";
</style>
