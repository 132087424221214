import { keyBy, omit } from 'lodash'
import { api } from '../../../../api/api-service'
import { removeTypeNames } from '../../../../api/db'
import { downloadCsv } from '../../../../lib/csv'
import {
  generateTaxonomyVersionMappingTemplateCsvData,
  parseVersionMappingCsv,
  treeifyOntologyItems,
} from '../../../../lib/ontology'
import { createStoreUtils } from '../../../../lib/vuex'
import ontologyStore from '../ontology'

/**
 * @typedef {Store.Ontology.CreateNewTaxonomyVersion.LocalState} State
 * @typedef {typeof getters} Getters
 * @typedef {typeof mutations} Mutations
 * @typedef {typeof actions} Actions
 */

/**
 * @template {any} Payload [Payload=void]
 * @typedef {MutationFor<State, Payload>} Mutate
 */

/**
 * @template {any} Payload [Payload=void]
 * @typedef {ActionFor<Mutations, State, Payload, void>} Act
 */

/** @type {State}  */
const initialState = {
  depth: null,
  feedback: null,
  isOpen: false,
  mappings: null,
  name: null,
  reference: null,
}

/** @satisfies {GettersFor<State>} */
const getters = {
  // Accessors
  depth: (state) => state.depth,
  dialogIsOpen: (state) => state.isOpen,
  name: (state) => state.name,
}

/** @satisfies {MutationsFor<State>} */
const mutations = {
  /** @type {Mutate<Api.GetTaxonomyVersion.Result>} */
  storeReferenceTaxonomyVersion(state, taxonomyVersion) {
    state.reference = {
      ontologyItemsByProcessId: keyBy(
        taxonomyVersion.ontologyItems,
        'processId',
      ),
      ontologyItems: taxonomyVersion.ontologyItems,
      taxonomyVersion: omit(taxonomyVersion, 'ontologyItems'),
    }
    treeifyOntologyItems({ byId: state.reference.ontologyItemsByProcessId })
  },
  /** @type {Mutate<{feedback: TaxonomyVersionMappingFeedback[] | null; mappings: TaxonomyVersionMapping[] | null}>} */
  storeTaxonomyVersionMapping(state, { feedback, mappings }) {
    state.feedback = feedback
    state.mappings = mappings
  },

  // Accessors
  /** @type {Mutate<boolean>} */
  setDialogIsOpen(state, isOpen) {
    state.isOpen = isOpen
    if (!isOpen) {
      state.depth = null
      state.feedback = null
      state.mappings = null
      state.name = null
      state.reference = null
    }
  },
  /** @type {Mutate<string | null>} */
  setName(state, name) {
    state.name = name
  },
  /** @type {Mutate<number | string | null>} */
  setDepth(state, depth) {
    state.depth = depth ? Number(depth) : null
  },
}

/** @satisfies {ActionsFor<Mutations, State>} */
const actions = {
  /** @type {Act<void>} */
  async openDialog({ commit, dispatch }) {
    commit('setDialogIsOpen', true)
    dispatch('loadData')
  },

  /** @type {Act<void>} */
  async loadData({ commit, rootState }) {
    const currentTaxonomyVersion = ontologyStore.getters.currentTaxonomyVersion(
      rootState.ontology,
    )
    const reference = await api.getTaxonomyVersion({
      taxonomyVersionId: currentTaxonomyVersion.id,
    })
    commit('storeReferenceTaxonomyVersion', reference)
    //commit('setName', reference.name + ' (Copy)')
    commit('setDepth', reference.depth)
  },

  /** @type {Act<void>} */
  async downloadVersionMappingCsv({ state }) {
    if (!state.reference) {
      throw new Error('Reference taxonomy version not loaded')
    }
    if (!state.depth) {
      throw new Error('Depth not set')
    }

    const csvData = generateTaxonomyVersionMappingTemplateCsvData(
      state.reference.ontologyItems,
      {
        oldDepth: state.reference.taxonomyVersion.depth,
        newDepth: state.depth,
      },
    )
    const sanitizedName = state.reference.taxonomyVersion.name
      .replace(/[^a-z0-9]/gi, '-')
      .replaceAll(/-+/g, '-')
      .replaceAll(/^-|-$/g, '')
      .toLowerCase()

    downloadCsv(`version-mapping-from-${sanitizedName}.csv`, csvData)
  },

  /** @type {Act<{body: string}>} */
  async validateVersionMappingCsv({ commit, state }, { body }) {
    if (!state.reference) {
      throw new Error('Reference taxonomy version not loaded')
    }

    const { feedback, mappings } = parseVersionMappingCsv(body, {
      oldOntologyItemsById: state.reference.ontologyItemsByProcessId,
      oldDepth: state.reference.taxonomyVersion.depth,
    })
    commit('storeTaxonomyVersionMapping', { feedback, mappings })
  },

  /** @type {Act<void>} */
  async createTaxonomyVersion(store) {
    const { commit, state } = store
    if (!state.reference) {
      throw new Error('Reference taxonomy version not loaded')
    }
    if (!state.depth) {
      throw new Error('Depth not set')
    }
    if (!state.mappings) {
      throw new Error('Mappings not loaded')
    }
    if (!state.name) {
      throw new Error('Name not set')
    }

    try {
      const newTaxonomyVersion = await api.createTaxonomyVersion({
        name: state.name,
        mappings: state.mappings.map(
          ({ description, newTaxonomy, oldProcessId }) => ({
            oldProcessId: oldProcessId ? Number(oldProcessId) : null,
            description,
            lineage: newTaxonomy,
          }),
        ),
        parentTaxonomyVersionId: state.reference.taxonomyVersion.id,
      })

      const strippedNewTaxonomyVersion = omit(
        removeTypeNames(newTaxonomyVersion),
        'ontologyItems',
      )

      console.log('strippedNewTaxonomyVersion', strippedNewTaxonomyVersion)

      commit('ontology/storeNewTaxonomyVersion', strippedNewTaxonomyVersion, {
        root: true,
      })
      commit(
        'ontology/storeSelectedTaxonomyVersion',
        strippedNewTaxonomyVersion,
        { root: true },
      )
      commit(
        'ontology/storeOntology',
        { ontologyItems: newTaxonomyVersion.ontologyItems },
        {
          root: true,
        },
      )
      commit('setDialogIsOpen', false)
    } catch (/** @type {Error | any} */ e) {
      commit('storeTaxonomyVersionMapping', {
        feedback: [
          {
            row: null,
            message: `Server error upon attempt to save: ${e?.message ?? e}`,
            severity: 'error',
          },
        ],
        mappings: null,
      })
    }
  },

  // Accessors
  /** @type {Act<boolean>} */
  async setDialogIsOpen({ commit }, isOpen) {
    commit('setDialogIsOpen', isOpen)
  },
  /** @type {Act<string>} */
  async setName({ commit }, name) {
    commit('setName', name)
  },
  /** @type {Act<number>} */
  async setDepth({ commit }, depth) {
    commit('setDepth', depth)
  },
}

export const createNewTaxonomyVersionStore = {
  namespaced: true,
  ...createStoreUtils('ontology/createNewTaxonomyVersion', {
    state: initialState,
    getters,
    mutations,
    actions,
  }),
}
