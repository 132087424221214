<template>
	<v-container fluid class="survey-overview">
		<survey-header :cant-save="true" />
		<div v-if="loading" class="survey-overview-content-loading">
			<v-progress-circular
				:size="70"
				:width="7"
				color="primary"
				indeterminate
				class="survey-overview-loading"
			/>
		</div>
		<v-container grid-list-md>
			<v-layout wrap>
				<v-flex xs12>
					<h1>Business Capability Taxonomy Browser</h1>
					<ontology-browser :ontology="ontology" size="large" :closed= false />
				</v-flex>
			</v-layout>
		</v-container>
	</v-container>
</template>

<script>
import { mapState } from 'vuex'
import OntologyBrowser from '../../components/user/survey/OntologyBrowser.vue'
import SurveyHeader from '../../components/user/survey/SurveyHeader.vue'

export default {
	components: { SurveyHeader, OntologyBrowser },
	data() {
		return {
			loading: false,
		}
	},
	computed: {
		...mapState({
			ontology: (state) => state.userSurveys.ontology,
			surveyDomains: (state) => state.surveyDomain.all,
		}),
	},
	created() {
		this.loading = true
		this.$store.dispatch('surveyDomain/loadSurveyDomains').then(() => {
			this.$store.dispatch('userSurveys/getAllSurveys', {
				// Take the first survey domain as default now
				surveyDomainId: this.surveyDomains[0].id,
			}).then(() => {
				this.loading = false
			})
		})
	},
	methods: {},
}
</script>
