<template>
  <v-dialog v-model="isOpen" max-width="500px">
    <v-card v-if="taxonomyVersion">
      <v-card-title>
        <span class="headline">Accept draft version</span>
      </v-card-title>
      <v-card-text v-if="taxonomyVersion">
        <p>Version name: {{ taxonomyVersion.name }}</p>
        <p>
          Status:
          {{
            taxonomyVersion.draft
              ? 'draft'
              : taxonomyVersion.current
              ? 'current'
              : 'non-current version'
          }}
        </p>
        <p>
          Note that this will delete all associated draft survey templates and its associated surveys and data
        </p>
        <p v-if="error">
          {{ error }}
        </p>
        <div class="actions">
          <v-btn @click="showDialog(false)">Cancel</v-btn>
          <v-btn @click="submit" :disabled="!canSubmit">Accept</v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { acceptDraftTaxonomyVersionStore } from '../../../store/modules/admin/dialog/accept-draft-taxonomy-version'

export default {
  name: 'DownloadTaxonomyVersionDialog',
  computed: {
    ...acceptDraftTaxonomyVersionStore.mapGetters({
      error: 'error',
      taxonomyVersion: 'taxonomyVersion',
      canSubmit: 'canSubmit',
    }),
    ...acceptDraftTaxonomyVersionStore.mapAccessors({
      isOpen: 'dialogIsOpen',
    }),
  },
  methods: {
    ...acceptDraftTaxonomyVersionStore.mapActions({
      showDialog: 'setDialogIsOpen',
      acceptDraft: 'acceptDraft',
    }),

    async submit() {
      await this.acceptDraft()
      this.$router.go()
    },
  },
}
</script>

<style>
.actions {
  display: flex;
  justify-content: space-between;
}
</style>
