<template>
	<div>
		<h4 v-if="value" style="margin-top: 0.5em; ">
			{{ prefix }}business capability
		</h4>
		<p v-if="value">
			<span v-for="(pathItem, i) in ontologyPath" :key="i" class="path-item">{{ pathItem }}<span v-if="i < ontologyPath.length - 1">&nbsp;/&nbsp;</span></span>
		</p>
		<business-unit-selection-dialog
			:ontology="ontology"
			:systems-by-process-id="systemsByProcessId"
			:closed="closed"
			:highlighted-path="highlightedPath"
			:add-root="addRoot"
			:open="open"
			:hide-button="hideSelectButton"
			:disable-item="disableItem"
			@toggleOpen="$emit('toggleOpen', $event)"
			@selectOntologyItem="selectOntologyItem($event)"
		/>
	</div>
</template>

<script>
import { range } from 'lodash/fp'
import BusinessUnitSelectionDialog from './BusinessUnitSelectionDialog'

export default {
	components: { BusinessUnitSelectionDialog },
	props: {
		value: {
			type: Object,
			default: null,
		},
		ontology: {
			type: Object,
			required: true,
			default: null,
		},
		systemsByProcessId: {
			type: Object,
			required: true,
		},
		closed: {
			type: Boolean,
			default: true,
		},
		highlightedPath: {
			type: Array,
			default: null,
		},
		addRoot: {
			type: Boolean,
			default: false,
		},
		open: {
			type: Boolean,
			default: false,
		},
		hideSelectButton: {
			type: Boolean,
			default: false,
		},
		prefix: {
			type: String,
			default: '',
		},
		disableItem: {
			type: Object,
			default: null,
		},
	},
	computed: {
		ontologySelects() {
			const item = this.value
			const mappedIndex = this.ontology.mappedIndex

			return range(0, item.mappedPath.length - 1).map((i) => ({
				selected: mappedIndex[item.mappedPath[i + 1]],
				options: mappedIndex[item.mappedPath[i]].realChildren,
			}))
		},
		ontologyPath() {
			const mappedIndex = this.ontology.mappedIndex

			return this.value.mappedPath.slice(1).map((itemId) => mappedIndex[itemId].name)
		},
	},
	methods: {
		selectOntologyItem(ontologyItem) {
			if (!this.value || !this.value.mappedPath.filter((itemId) => itemId === ontologyItem.id)[0]) {
				this.$emit('input', ontologyItem.realLeaf || ontologyItem)
			}
		},
	},
}
</script>

<style scoped>
.path-item {
	white-space: nowrap;
	display: inline-block;
}
</style>
