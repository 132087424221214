<template>
  <v-container fluid class="admin-content">
    <v-slide-y-transition mode="out-in">
      <v-layout>
        <v-navigation-drawer
          v-model="drawer"
          :mini-variant="miniVariant"
          :clipped="clipped"
          persistent
          enable-resize-watcher
          fixed
          app
          class="navigation"
        >
          <router-link tag="div" to="/" class="button-icon-router-link">
            <v-btn flat icon class="button icon home">
              <v-icon class="button-icon"> home </v-icon>
            </v-btn>
          </router-link>
          <v-list class="list">
            <v-list-tile
              v-for="(item, i) in items"
              :key="i"
              :class="'list-item ' + checkRoute(item.navHighlight)"
              value="true"
              @click="goTo(item.to)"
            >
              <v-list-tile-content>
                <v-list-tile-title class="list-item-title">
                  {{ item.title }}
                </v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
          </v-list>
        </v-navigation-drawer>
        <v-content>
          <v-btn flat icon class="button icon" @click.stop="drawer = !drawer">
            <v-icon class="button-icon">
              {{ drawer ? 'mdi-chevron-left' : 'mdi-chevron-right' }}
            </v-icon>
          </v-btn>
          <router-view />
        </v-content>
      </v-layout>
    </v-slide-y-transition>
  </v-container>
</template>

<script>
export default {
  name: 'AdminView',
  data() {
    return {
      clipped: true,
      drawer: true,
      items: [
        {
          icon: 'mdi-bullhorn',
          title: 'Surveys',
          to: '/admin/surveys',
          navHighlight: 'surveys',
        },
        {
          icon: 'mdi-account-multiple',
          title: 'Companies & Users',
          to: '/admin/users',
          navHighlight: 'users',
        },
        {
          icon: 'mdi-file-tree',
          title: 'Business Capability Taxonomy',
          to: '/admin/ontology',
          navHighlight: 'ontology',
        },
        {
          icon: 'mdi-file-tree',
          title: 'Submission status',
          to: '/admin/submissions',
          navHighlight: 'submissions',
        },
      ],
      miniVariant: false,
      title: 'PRISME Survey Forum',
    }
  },
  methods: {
    goTo(path) {
      this.$router.push(path).catch(()=>{})
    },
    checkRoute(path) {
      return this.$route.meta.navMatch === path ? 'current' : ''
    },
  },
}
</script>
<style lang="scss">
.navigation {
  box-shadow: 0 2px 5px 0 #b8b8b8;
}
.admin-content {
  padding: 0;
}
.list {
  padding-top: 80px;
  .list-item {
    &.current {
      a {
        background-color: $blue;
        color: white !important;
        &:hover {
          background-color: $blue;
          color: white !important;
        }
      }
    }
    a {
      height: 70px;
      &:hover {
        color: $blue !important;
        background-color: white;
      }
    }
    &-title {
      font-size: 16px;
    }
  }
}
</style>
