// @ts-check

import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client/core'
import { isArray, isPlainObject, mapValues, omit } from 'lodash'
import { config } from '../config'

export default new ApolloClient({
  // Provide the URL to the API server.
  link: new HttpLink({
    uri: `${config.apiUrl}/graphql`,
    credentials: 'include',

    // useGETForQueries: true,
  }),

  // Using a cache for blazingly
  // Fast subsequent queries.
  cache: new InMemoryCache(/* { addTypename: false } */),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
    },
  },
})

export const removeTypeNames = (value, recurse = Infinity) => {
  if (recurse < 0) {
    return value
  }
  if (isArray(value)) {
    return value.map((v) => removeTypeNames(v, recurse - 1))
  } else if (isPlainObject(value)) {
    return mapValues(omit(value, '__typename'), (v) =>
      removeTypeNames(v, recurse - 1),
    )
  } else {
    return value
  }
}
