import graphqlClient from '../../../api/db'
import gql from 'graphql-tag'
import { Dataset } from '../../../lib/dataset'

// Initial state
const initialState = {
	surveyTemplates: [],
	dataset: null,
	loading: false,
}

// Getters
const getters = {
}

// Actions
const actions = {
	async loadData({ commit }) {
		commit('setLoading', true)
		commit('setAll', [])
		commit('setSurveyTemplates', [])

		const response = await graphqlClient.query({
			query: gql`
				query {
					graphSurveySelectionCriteria {
						surveyTemplateId
						businessUnitId
					}
					surveyTemplates {
						id
						name
						createdAt
					}
				}
			`,
		})

		const graphData = await Promise.all(response.data.graphSurveySelectionCriteria.map((t) =>
			graphqlClient.query({
				query: gql`
					query GetSurveyData($surveyTemplateId: String!, $businessUnitId: Int!) {
						graphData(surveyTemplateId: $surveyTemplateId, businessUnitId: $businessUnitId) {
							fieldNames
							data
						}
					}
				`,
				variables: {
					surveyTemplateId: t.surveyTemplateId,
					businessUnitId: t.businessUnitId,
				},
			}).then((res) => {
				return JSON.parse(res.data.graphData.data).map((values) => {
					return res.data.graphData.fieldNames.reduce((h, fieldName, i) => {
						const value = values[i]

						if (value !== '' && value !== null) {
							h[fieldName] = value
						}

						return h
					}, {})
				})
			}),
		)).then((sets) => sets.reduce((acc, s) => acc.concat(s), []))

		commit('setAll', graphData)
		commit('setSurveyTemplates', response.data.surveyTemplates)
		commit('setLoading', false)
	},
}

// Mutations
const mutations = {

	setLoading(state, loading) {
		state.loading = loading
	},

	setAll(state, all) {
		state.dataset = new Dataset(all)
	},

	setSurveyTemplates(state, surveyTemplates) {
		state.surveyTemplates = surveyTemplates
	},

}

export default {
	namespaced: true,
	state: initialState,
	getters,
	actions,
	mutations,
}
