import graphqlClient, { removeTypeNames } from '../../../api/db'
import gql from 'graphql-tag'
import { allFieldValidations } from '../../../api/fragments'
import { createValidator } from '../../../api/validations'

// Initial state
const initialState = {
	all: [],
	saving: false,
	companyValidator: null,
	serverCompanyErrors: null,
	serverError: null,
}

// Getters
const getters = {}

// Actions
const actions = {
	async getAllCompanies({ commit }, payload) {
		const response = await graphqlClient.query({
			query: gql`
				query Companies ($include: Boolean) {
					companies (includeInactive: $include) {
						id
						name
						address
						active
					}
					companyValidations {
						Object {
							name { ...allFieldValidations }
							address { ...allFieldValidations }
						}
					}
					${allFieldValidations}
				}
			`,
			variables: {
				include: payload,
			},
		})

		commit('setCompanies', response.data.companies)
		commit('setCompanyValidator', createValidator(removeTypeNames(response.data.companyValidations)))
	},

	createCompany({ commit }, payload) {
		commit('setSaving', true)
		commit('setServerError', false)
		commit('setServerCompanyErrors', null)

		return graphqlClient.mutate({
			mutation: gql`
				mutation CreateCompany($company: CompanyCreateProps!) {
					createCompany(props: $company) {
						id
					}
				}
			`,
			variables: {
				company: removeTypeNames(payload),
			},
		}).catch((e) => {
			if (e.graphQLErrors) {
				const error = e.graphQLErrors[0]

				if (error.validationErrors) {
					commit('setServerCompanyErrors', error.validationErrors)
				} else {
					commit('setServerError', true)
				}
			} else {
				commit('setServerError', true)
			}

			commit('setSaving', false)
			throw e
		}).then((response) => {
			commit('setSaving', false)
			commit('setServerError', false)
			commit('createCompany', Object.assign({}, { id: response.data.createCompany.id, active: true }, payload))
			commit('setSaving', false)
		})
	},

	updateCompany({ commit }, payload) {
		commit('setSaving', true)
		commit('setServerError', false)
		commit('setServerCompanyErrors', null)

		return graphqlClient.mutate({
			mutation: gql`
				mutation UpdateCompany($company: CompanyUpdateProps!) {
					updateCompany(props: $company) {
						id
					}
				}
			`,
			variables: {
				company: removeTypeNames(payload),
			},
		}).then(() => {
			commit('setSaving', false)
			commit('updateCompany', payload)
		}).catch((e) => {
			if (e.graphQLErrors) {
				const error = e.graphQLErrors[0]

				if (error.validationErrors) {
					commit('setServerCompanyErrors', error.validationErrors)
				} else {
					commit('setServerError', true)
				}
			} else {
				commit('setServerError', true)
			}

			commit('setSaving', false)
			throw e
		})
	},
}

// Mutations
const mutations = {
	setCompanies(state, comps) {
		state.all = comps
	},
	setCompanyValidator(state, validator) {
		state.companyValidator = validator
	},
	createCompany(state, company) {
		state.all.push(company)
	},
	updateCompany(state, company) {
		state.all = state.all.map((c) => c.id === company.id ? company : c)
	},
	setSaving(state, saving) {
		state.saving = saving
	},
	setServerError(state, error) {
		state.serverError = error
	},
	setServerCompanyErrors(state, errors) {
		state.serverCompanyErrors = errors
	},
}

export default {
	namespaced: true,
	state: initialState,
	getters,
	actions,
	mutations,
}
