var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('edit-ontology-item-dialog'),_c('download-taxonomy-version-dialog'),_c('download-taxonomy-version-mapping-dialog'),_c('create-new-taxonomy-version-dialog'),_c('delete-taxonomy-version-dialog'),_c('accept-draft-taxonomy-version-dialog'),_c('header',{staticClass:"header"},[_c('h1',{staticClass:"title-layout header-title"},[_vm._v("Business Capability Taxonomy")]),_c('div',{staticClass:"header-spacing"}),_c('div',{staticClass:"header-version"},[_c('taxonomy-version-selector')],1)]),_c('div',{staticClass:"actions"},[_c('div',{staticClass:"actions-group"},[_c('v-btn',{staticClass:"button",attrs:{"disabled":!_vm.selectedTaxonomyVersion},on:{"click":function($event){return _vm.downloadTaxonomyVersion({
            taxonomyVersion: _vm.selectedTaxonomyVersion,
          })}}},[_vm._v(" Download taxonomy ")]),_c('v-btn',{staticClass:"button",attrs:{"disabled":!_vm.selectedTaxonomyVersion || !_vm.previousTaxonomyVersionId},on:{"click":function($event){return _vm.downloadTaxonomyVersionMapping({
            toTaxonomyVersion: _vm.selectedTaxonomyVersion,
          })}}},[_vm._v(" Download version mapping ")])],1),_c('div',{staticClass:"actions-group"},[_c('v-btn',{staticClass:"button",attrs:{"disabled":!_vm.canDeleteTaxonomyVersion},on:{"click":function($event){return _vm.deleteTaxonomyVersion({ taxonomyVersion: _vm.selectedTaxonomyVersion })}}},[_vm._v(" Delete version ")]),(!_vm.canAcceptDraftTaxonomyVersion)?_c('v-btn',{staticClass:"button",attrs:{"disabled":!_vm.taxonomyVersionsLoaded || _vm.currentTaxonomyVersionHasDraft},on:{"click":_vm.createNewTaxonomyVersion}},[_vm._v(" Create new version ")]):_c('v-btn',{staticClass:"button",attrs:{"disabled":!_vm.canDeleteTaxonomyVersion},on:{"click":function($event){return _vm.acceptDraftTaxonomyVersion()}}},[_vm._v(" Accept draft version ")])],1)]),_c('section',{staticClass:"taxonomy-wrapper"},[_c('v-form',{staticClass:"search",on:{"submit":function($event){$event.preventDefault();return _vm.search()}}},[_c('div',{staticClass:"search-row"},[_c('v-text-field',{staticClass:"search-input",attrs:{"placeholder":"Search for an item ..."},on:{"input":function($event){_vm.searchResults = []}},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}),_c('v-btn',{staticClass:"button ontology-search-button",on:{"click":function($event){return _vm.search()}}},[_vm._v(" Search ")])],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.searchResults.length),expression:"searchResults.length"}],staticClass:"ontology-search-results"},[_c('div',{staticClass:"ontology-search-results-list"},_vm._l((_vm.searchResults),function(item){return _c('v-btn',{key:item.processId,staticClass:"ontology-search-result",on:{"click":function($event){_vm.expandToItem(item, { scroll: true })
            _vm.closeSearch()}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)]),_c('div',{staticClass:"taxonomy"},[_vm._l((_vm.levels),function(level){return [_c('div',{staticClass:"taxonomy-level"},[_c('header',{staticClass:"taxonomy-level-header"},[_c('h3',[_vm._v(" "+_vm._s(level.title)+" ")])]),_c('div',{staticClass:"taxonomy-level-body"},[_c('div',{staticClass:"taxonomy-level-items"},_vm._l((level.items),function(item){return _c('div',{key:item.processId,staticClass:"taxonomy-item",class:{
                  active: _vm.taxonomyPath[level.number] === item.processId,
                  inactive: !item.active || !level.active,
                },attrs:{"id":`taxonomy-item-id-${item.processId}`},on:{"click":function($event){return _vm.expandToItem(item, { scroll: false })}}},[_c('div',{staticClass:"taxonomy-item-label"},[_c('span',{staticClass:"taxonomy-item-name"},[_vm._v(_vm._s(item.name))]),_c('span',{staticClass:"taxonomy-item-child-count"},[_vm._v(" ("+_vm._s(item.totalSurveyItemCount)+") ")])]),_c('div',{staticClass:"taxonomy-item-actions"},[_c('v-icon',{staticClass:"taxonomy-item-action",on:{"click":function($event){$event.stopPropagation();return _vm.editOntologyItem({ ontologyItem: item })}}},[_vm._v(" mdi-pencil ")])],1)])}),0),_c('div',{staticClass:"taxonomy-level-actions"},[_c('v-form',{staticClass:"ontology-add-option",on:{"submit":function($event){$event.preventDefault();return _vm.addItem(level.number)}}},[_c('v-text-field',{attrs:{"disabled":level.number > _vm.taxonomyPath.length,"error-messages":_vm.addItemForms[level.number]
                      ? _vm.addItemFormErrors[level.number]
                      : []},model:{value:(_vm.addItemForms[level.number]),callback:function ($$v) {_vm.$set(_vm.addItemForms, level.number, $$v)},expression:"addItemForms[level.number]"}}),_c('v-btn',{staticClass:"button",attrs:{"disabled":level.number > _vm.taxonomyPath.length ||
                    !_vm.addItemForms[level.number] ||
                    _vm.addItemFormErrors[level.number]?.length > 0},on:{"click":function($event){return _vm.addItem(level.number)}}},[_vm._v(" Add ")])],1)],1)])])]})],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }