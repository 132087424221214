<!--
	Route: /surveys/:id/:selectedBusinessUnitId

	Contains the full UI, including header, for a survey by businessUnitId
-->

<template>
	<survey-item
		:id="id"
		:key="selectedBusinessUnitId"
		:selected-business-unit-id="selectedBusinessUnitId"
		@updateCantSave="cantSave = $event"
	/>
</template>

<script>
// This makes sure the Survey component is replaced such that everything is
// reloaded nicely for resetting purposes
import SurveyItem from './SurveyItem'

export default {
	name: 'SurveyReloader',
	components: { SurveyItem },
	beforeRouteLeave(to, from, next) {
		if (this.cantSave) {
			return next()
		} else if (
			window.confirm('Do you really want to leave? You have unsaved changes!')
		) {
			return next()
		} else {
			return next(false)
		}
	},
	props: {
		id: {
			type: String,
			required: true,
		},
		selectedBusinessUnitId: {
			type: String,
			require: false,
			default: null,
		},
	},
	data() {
		return {
			cantSave: true,
		}
	},
}
</script>
