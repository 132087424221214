import { api } from '../../../../api/api-service'
import { createStoreUtils } from '../../../../lib/vuex'

/**
 * @typedef {Store.Ontology.AcceptDraftTaxonomyVersion.LocalState} State
 * @typedef {typeof getters} Getters
 * @typedef {typeof mutations} Mutations
 * @typedef {typeof actions} Actions
 */

/**
 * @template {any} Payload [Payload=void]
 * @typedef {MutationFor<State, Payload>} Mutate
 */

/**
 * @template {any} Payload [Payload=void]
 * @typedef {ActionFor<Mutations, State, Payload, void>} Act
 */

/** @type {State}  */
const initialState = {
  isOpen: false,
  error: null,
  isSubmitting: false,
}

/** @satisfies {GettersFor<State>} */
const getters = {
  error(state, getters, rootState) {
    if (rootState.ontology.selectedTaxonomyVersion?.draft !== true) {
      return 'You must select a draft taxonomy version to accept.'
    } else {
      return state.error
    }
  },
  canSubmit(state, getters, rootState) {
    return (
      !state.error &&
      !state.isSubmitting &&
      rootState.ontology.selectedTaxonomyVersion?.draft === true
    )
  },
  taxonomyVersion(state, getters, rootState) {
    return rootState.ontology.selectedTaxonomyVersion
  },
  // Accessors
  dialogIsOpen(state) {
    return state.isOpen
  },
}

/** @satisfies {MutationsFor<State>} */
const mutations = {
  /** @type {Mutate<void>} */
  resetState(state) {
    state.isOpen = false
    state.error = null
  },

  // Accessors
  /** @type {Mutate<boolean>} */
  setDialogIsOpen(state, isOpen) {
    state.isOpen = isOpen
  },
  /** @type {Mutate<boolean>} */
  setSubmitting(state, isSaving) {
    state.isSubmitting = isSaving
  },
  /** @type {Mutate<string>} */
  setError(state, error) {
    state.error = error
  },
}

/** @satisfies {ActionsFor<Mutations, State>} */
const actions = {
  /** @type {Act<{taxonomyVersion: Model.TaxonomyVersion}>} */
  async openDialog({ commit }) {
    commit('setDialogIsOpen', true)
  },
  /** @type {Act<void>} */
  async acceptDraft({ commit, rootState }) {
    commit('setSubmitting', true)
    try {
      await api.acceptTaxonomyVersionDraft({
        taxonomyVersionId: rootState.ontology.selectedTaxonomyVersion.id,
      })
    } catch (error) {
      commit('setError', `${error?.message ?? error}`)
    }
    commit('setSubmitting', false)
  },
  // Accessors
  /** @type {Act<boolean>} */
  async setDialogIsOpen({ commit }, isOpen) {
    if (isOpen) {
      commit('setDialogIsOpen', isOpen)
    } else {
      commit('resetState', undefined)
    }
  },
}

export const acceptDraftTaxonomyVersionStore = {
  namespaced: true,
  ...createStoreUtils('ontology/acceptDraftTaxonomyVersion', {
    state: initialState,
    getters,
    mutations,
    actions,
  }),
}
