<template>
  <v-dialog v-model="isOpen" max-width="500px">
    <v-card v-if="taxonomyVersion">
      <v-card-title>
        <span class="headline">Delete Taxonomy Version</span>
      </v-card-title>
      <v-card-text>
        <p>Version name: {{ taxonomyVersion.name }}</p>
        <p>
          Status:
          {{
            taxonomyVersion.draft
              ? 'draft'
              : taxonomyVersion.current
              ? 'current'
              : 'non-current version'
          }}
        </p>
        <p v-if="isLoading">
          Contacting database to see if this version can be deleted..
        </p>
        <p v-else-if="error">Error: {{ error }}</p>
        <p v-else>
          It's safe to delete this taxonomy version: no one has started a survey and no
          derived versions found. OR it is a draft version.
        </p>

        <div class="actions">
          <v-btn @click="showDialog(false)">Cancel</v-btn>
          <v-btn
            @click="submit"
            :disabled="isLoading || error || isSubmitting"
          >
            Delete
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { deleteTaxonomyVersionStore } from '../../../store/modules/admin/dialog/delete-taxonomy-version'
import ontologyStore from '../../../store/modules/admin/ontology'

export default {
  name: 'DeleteTaxonomyVersionDialog',
  computed: {
    ...deleteTaxonomyVersionStore.mapState({
      deleteTaxonomyVersionStore: (state) => state,
    }),
    ...deleteTaxonomyVersionStore.mapGetters({
      isLoading: 'isLoading',
      isSubmitting: 'isSubmitting',
      error: 'error',
    }),
    ...deleteTaxonomyVersionStore.mapAccessors({
      isOpen: 'dialogIsOpen',
    }),
    ...ontologyStore.mapGetters({
      taxonomyVersion: 'selectedTaxonomyVersion',
    }),
  },
  methods: {
    ...deleteTaxonomyVersionStore.mapActions({
      showDialog: 'setDialogIsOpen',
      deleteTaxonomyVersion: 'deleteTaxonomyVersion',
    }),
    async submit() {
      await this.deleteTaxonomyVersion()
      // Reload the current page
      this.$router.go();
    },
  },
}
</script>

<style></style>
