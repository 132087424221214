<template>
	<div :class="{ wide }" class="chart-filter-dropdown-container" @mousedown="clickInside($event)">
		<div
			:class="{ all: selectedValue === ALL }"
			:style="{ 'background-color': colorFor(selectedValue) }"
			class="chart-filter-list-item is-dropdown"
			@click="toggle($event)"
		>
			<span v-if="selectedValue === ALL">all</span>
			<span v-else-if="!selectedValue" class="chart-filter-empty">empty</span>
			<span v-else>{{ selectedValue }}</span>
		</div>
		<div class="arrow-down-graphs" />
		<div :class="{ open }" class="chart-filter-dropdown">
			<dataset-list-filter
				:dataset="dataset"
				:filter="filter"
				:color-cache="colorCache"
				:property="property"
				:include-all="includeAll"
				:force-all="forceAll"
				:default-not-all="defaultNotAll"
				:sort="sort"
				single
				@update="select($event)"
			/>
		</div>
	</div>
</template>

<script>
import { Dataset } from '../../../lib/dataset'
import { ColorCache } from '../../../lib/color_cache'
import DatasetListFilter from './DatasetListFilter'

export default {
	components: { DatasetListFilter },
	props: {
		dataset: {
			type: Dataset,
			required: true,
		},
		colorCache: {
			type: ColorCache,
			required: true,
		},
		property: {
			type: String,
			required: true,
		},
		filter: {
			type: Object,
			default: null,
		},
		includeAll: {
			type: Boolean,
			default: false,
		},
		forceAll: {
			type: Boolean,
			default: false,
		},
		defaultNotAll: {
			type: Boolean,
			default: false,
		},
		wide: {
			type: Boolean,
			default: false,
		},
		sort: {
			type: Function,
			default: null,
		},
	},
	data() {
		return {
			open: false,
			ALL: Dataset.ALL,
			dontClose: false,
		}
	},
	computed: {
		selectedValue() {
			return this.filter === Dataset.ALL ? Dataset.ALL : this.filter ? Object.keys(this.filter)[0] : null
		},
	},
	created() {
		window.addEventListener('mousedown', this.close)
		window.addEventListener('mouseup', this.cancelDontClose)
	},
	destroyed() {
		window.removeEventListener('mousedown', this.close)
		window.removeEventListener('mouseup', this.cancelDontClose)
	},
	methods: {
		colorFor(value) {
			return this.colorCache.colorFor(this.property, value)
		},
		toggle() {
			this.open = !this.open
		},
		close() {
			if (!this.dontClose) {
				this.open = false
			}
		},
		clickInside() {
			this.dontClose = true
		},
		cancelDontClose() {
			this.dontClose = false
		},
		select(value) {
			this.close()
			this.$emit('update', value)
		},
	},
}
</script>
