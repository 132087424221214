<template>
	<v-dialog v-model="isOpen" max-width="500px">
		<v-card>
			<v-card-title>
				<span class="headline">Edit item</span>
			</v-card-title>
			<v-card-text v-if="ontologyItem">
				<v-container grid-list-md>
					<v-layout wrap="">
						<v-flex xs12>
							<v-text-field
								v-model="ontologyItem.name"
								label="Name"
								@keyup.enter.native="submit()"
							/>
							<v-checkbox
								v-model="ontologyItem.active"
								:disabled="ontologyItem.totalSurveyItemCount > 0"
								label="Active"
							/>
							<v-textarea
								v-model="ontologyItem.terms"
								label="Search terms (1 per line)"
							/>
							<v-textarea
								v-model="ontologyItem.description"
								label="Description"
							></v-textarea>
						</v-flex>
					</v-layout>
				</v-container>
			</v-card-text>
			<v-card-actions>
				<v-btn color="blue darken-1" flat @click.native="close()">
					Cancel
				</v-btn>
				<v-btn color="blue darken-1" flat @click.native="submit()">
					Save
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'EditOntologyItemDialog',
	computed: {
		isOpen: {
			get() {
				return this.$store.getters['ontology/editOntologyItemDialogIsOpen']
			},
			set(open) {
				if (!open) {
					this.$store.dispatch('ontology/closeEditOntologyItemDialog')
				}
			},
		},
		ontologyItem: {
			get() {
				return this.$store.getters[
					'ontology/editOntologyItemDialogOntologyItem'
				]
			},
			set(ontologyItem) {
				this.store$.commit('ontology/editOntologyItem')
			},
		},
		...mapGetters('ontology', {
			taxonomyVersion: 'editOntologyItemDialogTaxonomyVersion',
		}),
	},
	methods: {
		...mapActions('ontology', {
			close: 'closeEditOntologyItemDialog',
			updateOntologyItem: 'updateOntologyItem',
		}),

		async submit() {
			const terms = `\n${this.ontologyItem.terms}\n`
				.split(/\s*\n\s*/)
				.filter((t) => t)
			await this.updateOntologyItem({ ...this.ontologyItem, terms })
			this.close()
		},
	},
}
</script>

<style></style>
