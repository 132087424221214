import { api } from '../../../../api/api-service'
import { createStoreUtils } from '../../../../lib/vuex'

/**
 * @typedef {Store.Ontology.DeleteTaxonomyVersion.LocalState} State
 * @typedef {typeof getters} Getters
 * @typedef {typeof mutations} Mutations
 * @typedef {typeof actions} Actions
 */

/**
 * @template {any} Payload [Payload=void]
 * @typedef {MutationFor<State, Payload>} Mutate
 */

/**
 * @template {any} Payload [Payload=void]
 * @typedef {ActionFor<Mutations, State, Payload, void>} Act
 */

/** @type {State}  */
const initialState = {
  isOpen: false,
  isLoading: false,
  isSubmitting: false,
  error: null,
  taxonomyVersion: null,
}

/** @satisfies {GettersFor<State>} */
const getters = {
  // Accessors
  dialogIsOpen(state) {
    return state.isOpen
  },
  error(state) {
    return state.error
  },
  isLoading(state) {
    return state.isLoading
  },
  isSubmitting(state) {
    return state.isSubmitting
  },
  canDeleteBasedOnCache(state, getters, rootState) {
    const selectedTaxonomyVersion = rootState.ontology.selectedTaxonomyVersion
    const taxonomyVersions = rootState.ontology.taxonomyVersions

    if (rootState.ontology.all.length == 0) {
      return false
    }

    if (!selectedTaxonomyVersion) {
      return false
    }
    // If it has no parent it is the root taxonomy version
    if (!selectedTaxonomyVersion?.parentTaxonomyVersionId) {
      return false
    }
    const childTaxonomyVersion = taxonomyVersions.find(
      (tv) => tv?.parentTaxonomyVersionId === selectedTaxonomyVersion.id,
    )
    // If it has children it is a taxonomy in the middle
    if (childTaxonomyVersion) {
      return false
    }

    // If it has associates surveys and it not draft, it cannot be deleted
    const surveysWithItems = rootState.ontology.all.filter(ontology=> ontology.surveyItemCounts.length > 0)
    if (surveysWithItems.length > 0 && !selectedTaxonomyVersion.draft) {
      return false
    }
    return true
  },
}

/** @satisfies {MutationsFor<State>} */
const mutations = {
  // Accessors
  /** @type {Mutate<boolean>} */
  setDialogIsOpen(state, isOpen) {
    state.isOpen = isOpen
  },
  /** @type {Mutate<void>} */
  resetState(state) {
    state.error = null
    state.isLoading = false
    state.taxonomyVersion = null
    state.isSubmitting = false
  },
  /** @type {Mutate<null | string>} */
  setError(state, error) {
    state.error = error
  },
  /** @type {Mutate<boolean>} */
  setIsLoading(state, isLoading) {
    state.isLoading = isLoading
  },
  /** @type {Mutate<boolean>} */
  setIsSubmitting(state, isSubmitting) {
    state.isSubmitting = isSubmitting
  },
  /** @type {Mutate<null | Model.TaxonomyVersion>} */
  setTaxonomyVersion(state, taxonomyVersion) {
    state.taxonomyVersion = taxonomyVersion
  },
}

/** @satisfies {ActionsFor<Mutations, State>} */
const actions = {
  /** @type {Act<{toTaxonomyVersion: Model.TaxonomyVersion}>} */
  async openDialog({ commit, dispatch, rootState }) {
    commit('resetState', void 0)
    const taxonomyVersion = rootState.ontology.selectedTaxonomyVersion
    if (taxonomyVersion) {
      commit('setDialogIsOpen', true)
      commit('setTaxonomyVersion', taxonomyVersion)
      dispatch('loadData')
    }
  },
  /** @type {Act<boolean>} */
  async closeDialog({ commit }) {
    commit('setDialogIsOpen', false)
  },
  async loadData({ commit, state }) {
    if (!state.taxonomyVersion) {
      throw new Error('Taxonomy version not set')
    }
    commit('setError', null)
    commit('setIsLoading', true)
    const error = await api.canDeleteTaxonomyVersion({
      taxonomyVersionId: state.taxonomyVersion.id,
    })
    commit('setIsLoading', false)
    commit('setError', error)
  },
  /** @type {Act<void>} */
  async deleteTaxonomyVersion({ commit, state, dispatch, rootState }) {
    try {
      if (!state.taxonomyVersion) {
        throw new Error('Taxonomy version not set')
      }
      commit('setIsSubmitting', true)
      const error = await api.deleteTaxonomyVersion({
        taxonomyVersionId: state.taxonomyVersion.id,
      })
      commit('setIsSubmitting', false)
      if (!error) {
        commit('setDialogIsOpen', false)
        commit('ontology/resetState', null, { root: true })
        dispatch(
          'ontology/loadOntology',
          {
            surveyDomainId: rootState.surveys.selectedSurveyDomain.id,
          },
          { root: true },
        )
      } else {
        commit('setError', error)
      }
    } catch (error) {
      commit('setError', `${error?.message || error}`)
    }
  },

  // Accessors
  /** @type {Act<boolean>} */
  async setDialogIsOpen({ commit }, isOpen) {
    commit('setDialogIsOpen', isOpen)
  },
}

export const deleteTaxonomyVersionStore = {
  namespaced: true,
  ...createStoreUtils('ontology/deleteTaxonomyVersion', {
    state: initialState,
    getters,
    mutations,
    actions,
  }),
}
