<template>
	<v-dialog
		:value="editSystemDialog"
		max-width="500px"
		style="margin-left: 20px; "
		persistent
		@input="cancelEditSystem"
		@keydown.esc="cancelEditSystem"
	>
		<v-card v-if="system">
			<v-card-title>
				<span class="headline">Reclassify {{ system.values[surveyTemplate.keyField.fieldName] }}</span>
			</v-card-title>
			<v-card-text>
				<v-container grid-list-md>
					<v-layout wrap="">
						<v-flex xs12>
							<h4 v-if="system.ontologyItem" style="margin-top: 0.5em; ">
								old business capability
							</h4>
							<p v-if="system.ontologyItem">
								<span v-for="(pathItem, i) in oldOntologyPath" :key="i" class="path-item">{{ pathItem }}<span v-if="i < oldOntologyPath.length - 1">&nbsp;/&nbsp;</span></span>
							</p>
							<ontology-select
								v-model="ontologyItem"
								:ontology="ontology"
								:systems-by-process-id="systemsByProcessId"
								:highlighted-path="system.ontologyItem ? system.ontologyItem.path.map(({ processId }) => processId) : [system.businessUnitId]"
								:closed="true"
								:open="openOntologySelect"
								:hide-select-button="Boolean(ontologyItem)"
								:disable-item="system.ontologyItem"
								prefix="new "
								@toggleOpen="openOntologySelect = $event"
							/>
						</v-flex>
						<v-flex v-if="movingOut" xs12>
							<v-icon class="text-icon" color="blue">
								mdi-alert
							</v-icon>
							You are about to move your system to {{ newBusinessUnit }} but you have opened the survey for {{ currentBusinessUnit.name }}.
							If this is your intention click "Reclassify" below.
						</v-flex>
					</v-layout>
				</v-container>
			</v-card-text>
			<v-card-actions>
				<v-spacer />
				<v-btn v-if="ontologyItem" color="blue darken-1" flat @click.native="openOntologySelect = true">
					Reselect business capability
				</v-btn>
				<v-btn color="blue darken-1" flat @click.native="cancelEditSystem">
					Cancel
				</v-btn>
				<v-btn :disabled="cantSave" color="blue darken-1" flat @click.native="updateSystem">
					Reclassify
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import OntologySelect from './OntologySelect'

export default {
	components: { OntologySelect },
	props: {
		system: {
			type: Object,
			required: false,
			default: null,
		},
		ontology: {
			type: Object,
			required: true,
		},
		systemsByProcessId: {
			type: Object,
			required: true,
		},
		currentBusinessUnit: {
			type: Object,
			required: true,
		},
		surveyTemplate: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			ontologyItem: null,
			openOntologySelect: false,
		}
	},
	computed: {
		editSystemDialog() {
			return Boolean(this.system)
		},
		cantSave() {
			return !this.ontologyItem || this.system.ontologyItem && this.ontologyItem.processId === this.system.ontologyItem.processId
		},
		movingOut() {
			return this.ontologyItem && (!this.system.ontologyItem || this.ontologyItem.mappedPath[1] !== this.system.ontologyItem.path[0].processId)
		},
		newBusinessUnit() {
			return this.ontology.mappedIndex[this.ontologyItem.mappedPath[1]].name
		},
		oldOntologyPath() {
			return this.system.ontologyItem.path.map((item) => item.name)
		},
	},
	watch: {
		system(system) {
			if (system) {
				this.ontologyItem = null
			}
		},
	},
	methods: {
		cancelEditSystem() {
			this.$emit('cancel')
		},
		updateSystem() {
			this.$emit('update', this.ontologyItem)
		},
	},
}
</script>

<style scoped>
.text-icon {
	position: relative;
	top: 4px;
}
</style>
