<!-- eslint-disable vue/no-mutating-props -->
<template>
	<div class="survey-admin-field-column">
		<v-card
			:class="{ 'generic-disabled': disabled }"
			tile
			class="survey-admin-field-column-content"
		>
			<v-btn
				v-if="!field.isStandard"
				flat
				icon
				right
				color="primary"
				class="removeFieldButton"
				@click="$emit('removeField', field)"
			>
				<v-icon>mdi-close</v-icon>
			</v-btn>
			<v-card-title>
				<v-text-field
					v-model="field.fieldName"
					:readonly="field.isStandard"
					:error-messages="lookupMultiError(errors, 'fieldName')"
					:class="
						!!field.fieldName
							? field.isNewField
								? 'new'
								: field.isStandard
								? 'standard'
								: ''
							: 'empty'
					"
					type="text"
					placeholder="Field name"
					class="survey-admin-field-column-text-field"
				/>
			</v-card-title>
			<v-card-text class="survey-admin-field-column-text">
				<v-tooltip bottom>
					<v-checkbox
						v-if="!field.isStandard"
						slot="activator"
						v-model="field.isMandatory"
						label="Mandatory"
						color="primary"
						class="survey-admin-field-column-checkbox"
						@change="$emit('update:isMandatory', $event)"
					/>
					<span> Check if users are required to fill out this field </span>
				</v-tooltip>
				<v-radio-group
					v-if="!field.isStandard"
					v-model="field.isFreeText"
					mandatory
					class="survey-admin-field-column-radios"
					@change="$emit('update:isFreeText', $event)"
				>
					<v-tooltip bottom>
						<v-radio
							slot="activator"
							:value="true"
							color="primary"
							label="Free text"
						/>
						<span> Users freely enter text without guidance </span>
					</v-tooltip>
					<v-tooltip bottom>
						<v-radio
							slot="activator"
							:value="false"
							color="primary"
							label="Value List"
						/>
						<span> Attach list to guide users </span>
					</v-tooltip>
				</v-radio-group>
				<v-layout class="survey-admin-field-column-checkboxes" column>
					<v-tooltip bottom>
						<v-checkbox
							v-show="!field.isNewField & !field.isStandard"
							slot="activator"
							v-model="field.prefilled"
							label="Prefill data"
							color="primary"
							class="survey-admin-field-column-checkbox"
							@change="$emit('update:prefilled', $event)"
						/>
						<span>
							Check if field needs to be prefilled from template survey data
						</span>
					</v-tooltip>
				</v-layout>
				<div
					v-if="!field.isFreeText"
					v-bar
					class="survey-admin-field-value-list"
				>
					<div>
						<v-dialog v-model="listOfValues" scrollable max-width="600px">
							<v-btn
								slot="activator"
								dark
								class="button survey-admin-field-value-list-button"
							>
								List of values ({{ field.isStrict ? 'strict' : 'not strict' }},
								{{ field.allowedValues ? field.allowedValues.length : 0 }}
								values)
							</v-btn>
							<v-card v-if="listOfValues" max-height="500px">
								<v-card-title>
									<span class="headline">List of values</span>
								</v-card-title>
								<v-card-text>
									<v-layout>
										<v-tooltip bottom>
											<v-checkbox
												v-if="!field.isStandard"
												slot="activator"
												v-model="field.isStrict"
												label="Strict"
												color="primary"
												class="survey-admin-field-column-checkbox"
												@change="$emit('update:isStrict', $event)"
											/>
											<span>
												Check if users should be forced to select from this
												list.<br />
												If not checked, the list is used for suggestions only
											</span>
										</v-tooltip>
									</v-layout>
									<v-layout
										v-for="(value, index) in field.allowedValues"
										:key="index"
										align-center
									>
										<span>{{ value }}</span>
										<v-spacer />
										<span>
											<v-btn
												small
												flat
												icon
												color="primary"
												@click="$emit('deleteValue', index)"
											>
												<v-icon small>mdi-delete</v-icon>
											</v-btn></span
										>
									</v-layout>
								</v-card-text>
								<v-card-actions>
									<v-btn
										color="blue darken-1"
										flat
										@click.native="closeListOfValues"
									>
										Close
									</v-btn>
									<v-spacer />
									<v-layout>
										<v-text-field
											v-model="newValue"
											:error-messages="newValueErrors || []"
											autofocus
											name="add value"
											d-inline-block
											@keyup.enter.native="
												Boolean(newValueErrors)
													? ''
													: ($emit('addValue', newValue), (newValue = ''))
											"
										/>
										<v-btn
											:disabled="Boolean(newValueErrors)"
											flat
											icon
											color="primary"
											d-inline-block
											@click="$emit('addValue', newValue), (newValue = '')"
										>
											<v-icon>mdi-plus-circle-outline</v-icon>
										</v-btn>
									</v-layout>
								</v-card-actions>
							</v-card>
						</v-dialog>
					</div>
				</div>
				<!-- <div v-bar v-if="!field.isFreeText && !field.isStrict" class="survey-admin-field-value-list">
					<div>
						<v-dialog
							v-model="listOfCurrentValues"
							scrollable
							max-width="600px">
							<v-btn
								slot="activator"
								dark
								class="button survey-admin-field-value-list-button"
							>
								List of values ({{ field.allowedValues? field.allowedValues.length : 0 }} values)
							</v-btn>
							<v-card v-if="listOfCurrentValues" max-height="500px">
								<v-card-title>
									<span class="headline">List of values</span>
								</v-card-title>
								<v-card-text>
									<v-layout>
										<v-tooltip bottom>
											<v-checkbox
												v-if="!field.isStandard"
												slot="activator"
												v-model="field.isStrict"
												label="Strict"
												color="primary"
												class="survey-admin-field-column-checkbox"
												@change="$emit('update:isStrict', $event)"
											/>
											<span> Check if users should be forced to select from this list.<br> If not checked, the list is used for suggestions only </span>
										</v-tooltip>
									</v-layout>
									<v-layout v-for="(value, index) in field.allowedValues" :key="index" align-center>
										<span>{{ value }}</span>
										<v-spacer/>
										<span> <v-btn
											small
											flat
											icon
											color="primary"
											@click="$emit('deleteValue', index)"
										>
											<v-icon small>mdi-delete</v-icon>
										</v-btn></span>
									</v-layout>
								</v-card-text>
								<v-card-actions>
									<v-btn color="blue darken-1" flat @click.native="listOfCurrentValues = false">Close</v-btn>
									<v-spacer/>
									<v-layout>
										<v-text-field
											v-model="newValue"
											:error-messages="newValueErrors || []"
											autofocus
											name="add value"
											d-inline-block
											@keyup.enter.native ="Boolean(newValueErrors) ? '' : ($emit('addValue', newValue) , newValue='')"
										/>
										<v-btn
											:disabled="Boolean(newValueErrors)"
											flat
											icon
											color="primary"
											d-inline-block
											@click="$emit('addValue', newValue ), newValue=''"
										>
											<v-icon>mdi-plus-circle-outline</v-icon>
										</v-btn>
									</v-layout>
								</v-card-actions>
							</v-card>
						</v-dialog>
					</div>
				</div> -->
			</v-card-text>
		</v-card>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import {
	hasMultiError,
	lookupMultiError,
	lookupPartialError,
} from '../../api/validations'

export default {
	name: 'FieldTemplate',
	props: {
		field: {
			type: Object,
			default: () => {},
		},
		errors: {
			type: Array,
			default: null,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			listOfValues: false,

			//			listOfCurrentValues: false,
			newValue: '',
		}
	},
	computed: {
		...mapState({
			allowedValuesValidator: (state) => state.surveys.allowedValuesValidator,
		}),
		newValueErrors() {
			return this.allowedValuesValidator
				? lookupPartialError(
						this.allowedValuesValidator(
							this.field.allowedValues.concat([this.newValue]),
							false,
						),
						this.field.allowedValues ? this.field.allowedValues.length : 0,
				  )
				: null
		},
	},
	methods: {
		closeListOfValues() {
			this.listOfValues = false
		},
		lookupMultiError,
		hasMultiError,
	},
}
</script>

<style lang="scss">
@import '../../assets/scss/components/admin/surveyField';
</style>
