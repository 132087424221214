<template>
  <div
    :style="{ height: `${height}px`, width: `${width}px` }"
    class="survey-actions"
  >
    <div class="survey-actions-top">
      <p class="user-survey-side survey-actions-top-side">
        Showing {{ filteredSystems().length }} of
        {{ survey.surveyItems.length }} systems
      </p>
      <v-flex
        :style="{ width: `calc(100% - ${2 * sideWidth}px)` }"
        style="text-align: center; left: 50%"
        class="line-tabs survey-actions-top-tabs"
      >
        <v-tabs
          v-model="currentTab"
          fixed-tabs
          slider-color="#105187"
          @input="selectTab($event)"
        >
          <v-tab>Filter by business capability</v-tab>
          <v-tab>Search</v-tab>
          <v-tab>
            <span :class="{ incomplete_hl: incompleteSystemCount }">
              {{ incompleteSystemCount }} incomplete ({{
                incompleteSystemTotalCount
              }}
              total)
            </span>
          </v-tab>
        </v-tabs>
      </v-flex>
      <div
        v-if="surveyTemplate.status === 'started'"
        :style="{ width: `${sideWidth}px` }"
        style="text-align: right"
        class="survey-actions-top-buttons"
      >
        <ontology-browser-dialog
          :ontology="ontology"
          :systems-by-process-id="activeProcessIdIndex"
        />
        <import-dialog
          :ontology="fullExtendedOntology"
          :business-unit="fullExtendedOntology.businessUnit"
          :survey-template="surveyTemplate"
          @import="importSystems($event)"
        />
        <new-system-dialog
          :name-values="nameValues"
          :selected-ontology-item="selectedOntologyItem"
          :ontology="fullExtendedOntology"
          :systems-by-process-id="activeProcessIdIndex"
          :business-unit="fullExtendedOntology.businessUnit"
          @new="addNewSystem($event)"
        />
        <!-- <button
          class="button"
          style="padding: 0 15px; margin-left: 20px"
          @click="openBusinessLevelOverview"
        >
          Business capability model
        </button> -->
        <button
          :class="{
            disabled: cantSave,
            loading: isSaving && !isSavingSubmittedStatus,
          }"
          :disabled="cantSave || !online"
          style="padding: 0 15px; margin-left: 20px"
          class="button"
          @click="saveSurvey"
        >
          Save
        </button>
        <button
          :class="{ loading: isSavingSubmittedStatus }"
          :disabled="cantChangeSubmittedStatus"
          style="padding: 0 15px; margin-left: 20px"
          class="button"
          @click="toggleSurvey"
        >
          <template v-if="survey.hasBeenSubmitted"> Reopen </template
          ><template v-else> Submit </template>
        </button>
      </div>
    </div>
    <div v-if="!cantSave && online" class="survey-actions-unsaved-message">
      You have unsaved changes
    </div>
    <div v-if="!online" class="survey-actions-unsaved-message">
      Cannot save changes as you are offline
    </div>
    <v-tabs-items
      v-model="currentTab"
      :style="{ height: `${height - topHeight}px` }"
      class="survey-actions-tabs"
    >
      <v-tab-item class="survey-actions-tab">
        <v-layout style="height: 100%">
          <v-flex style="height: 100%" class="user-survey-side" />
          <div class="survey-actions-tab-content">
            <!-- <v-flex class="user-survey-arrow">
              <div text-xs-center>
                <v-icon :disabled="leftDisabled" large @click="goLeft">
                  mdi-chevron-left
                </v-icon>
              </div>
            </v-flex> -->
            <!-- eslint-disable-next-line vue/no-v-for-template-key -->
            <template v-for="(select, i) in ontologySelects">
              <p
                v-if="i > 0"
                :key="'select-p' + i"
                class="survey-actions-tab-slash"
              >
                /
              </p>
              <v-flex :key="'select-' + i" class="survey-actions-tab-select">
                <v-select
                  :items="select.options"
                  :value="select.selected"
                  item-text="name"
                  item-value="id"
                  return-object
                  @input="selectOntologyItem($event)"
                />
              </v-flex>
            </template>
            <!-- <v-flex class="user-survey-arrow">
              <div text-xs-center>
                <v-icon :disabled="rightDisabled" large @click="goRight">
                  mdi-chevron-right
                </v-icon>
              </div>
            </v-flex> -->
          </div>
        </v-layout>
      </v-tab-item>
      <v-tab-item class="survey-actions-tab">
        <v-container
          :style="{
            height: `${height - topHeight}px`,
            padding: `${(height - topHeight - 40) / 2}px 0`,
          }"
          fluid
        >
          <v-layout style="height: 100%">
            <v-flex style="height: 100%" class="user-survey-side" />
            <v-flex style="text-align: center">
              <input
                v-model="search"
                type="text"
                placeholder="Type to search"
                style="
                  border: 1px solid grey;
                  padding: 0px 10px;
                  height: 40px;
                  width: 300px;
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                "
                @input="updateSearch($event.target.value)"
              />
            </v-flex>
          </v-layout>
        </v-container>
      </v-tab-item>
      <v-tab-item class="survey-actions-tab">
        <v-container
          :style="{
            height: `${height - topHeight}px`,
            padding: `${(height - topHeight - 40) / 2}px 0`,
          }"
          fluid
        >
          <v-layout style="height: 100%">
            <v-flex style="height: 100%" class="user-survey-side" />
            <div class="survey-actions-tab-incomplete">
              <p class="survey-actions-tab-incomplete-text">
                Below only systems with missing information are shown
              </p>
            </div>
          </v-layout>
        </v-container>
      </v-tab-item>
    </v-tabs-items>
    <validation-errors-dialog
      :open="validationDialogOpen"
      :invalid-systems="invalidSystemLabels"
      @close="closeValidationDialog($event)"
    />
    <submission-validation-dialog
      :open="submissionDialogOpen"
      @close="closeValidationDialog($event)"
    />
    <business-unit-tree-dialog
      :open="businessUnitTreeDialogOpen"
      :tree="extendedOntology.businessUnit"
      :disabled-trees="otherBusinessUnits"
      :ontology-item-index="extendedOntology.mappedIndex"
      :systems-by-process-id="activeProcessIdIndex"
      @close="closeBusinessLevelOverview"
      @selectOntologyItem="goToOntologyItem($event)"
    />
    <edit-system-dialog
      :systems-by-process-id="activeProcessIdIndex"
      :system="editingSystem"
      :ontology="fullExtendedOntology"
      :current-business-unit="ontology.businessUnit"
      :survey-template="surveyTemplate"
      @cancel="cancelEditingSystem()"
      @update="updateSystemOntologyItem($event)"
    />
  </div>
</template>

<script>
import { range } from 'lodash/fp'
import { mapGetters, mapState } from 'vuex'
import BusinessUnitTreeDialog from './BusinessUnitTreeDialog.vue'
import EditSystemDialog from './EditSystemDialog'
import ImportDialog from './ImportDialog'
import NewSystemDialog from './NewSystemDialog.vue'
import OntologyBrowserDialog from './OntologyBrowserDialog'
import SubmissionValidationDialog from './SubmissionValidationDialog.vue'
import ValidationErrorsDialog from './ValidationErrorsDialog.vue'

const searchMatches = (string, search) => {
  return string.toLowerCase().indexOf(search.toLowerCase()) >= 0
}

// TODO: currently the tabs are hardcoded in a way I don't like (see the linter warning about the number 2 below for a glimpse)

export default {
  components: {
    NewSystemDialog,
    ValidationErrorsDialog,
    SubmissionValidationDialog,
    BusinessUnitTreeDialog,
    EditSystemDialog,
    ImportDialog,
    OntologyBrowserDialog,
  },
  props: {
    selectedSystems: {
      required: true,
      type: Array,
    },
    height: {
      type: Number,
      required: true,
    },
    width: {
      type: Number,
      required: true,
    },
    nameValues: {
      type: Array,
      required: false,
      default: null,
    },
    editingSystem: {
      type: Object,
      require: false,
      default: null,
    },
  },
  data() {
    return {
      validationDialogOpen: false,
      submissionDialogOpen: false,
      businessUnitTreeDialogOpen: false,
      currentTab: 1,
      sideWidth: 350,
      topHeight: 90,
      selectedOntologyItem: null,
      selectedOntologyItemIndex: null,
      search: '',
      ontologyItems: null,
      windowWidth: 0,
      online: window.navigator.onLine,
    }
  },
  computed: {
    ...mapState({
      surveyTemplate: (state) => state.userSurveys.surveyTemplate,
      survey: (state) => state.userSurveys.survey,
      isSaving: (state) => state.userSurveys.saving,
      isSavingSubmittedStatus: (state) =>
        state.userSurveys.savingSubmittedStatus,
      ontology: (state) => state.userSurveys.ontology,
    }),
    ...mapGetters('userSurveys', {
      cantSave: 'cantSave',
      cantChangeSubmittedStatus: 'cantChangeSubmittedStatus',
    }),
    // eslint-disable-next-line max-statements
    // NOTE: This extendedOntology was used in the past to make sure that users had to stay within the business capability level 1 they were filling out.
    // However, with the number of business capability level 1's we have now, this is no longer possible. As such, this is used much less
    extendedOntology() {
      // Ontology with All added to it
      const maxDepth = this.ontology.depth - 1
      const levelLists = range(0, maxDepth + 1).map(() => [])
      const leaves = []
      const index = {}
      const mappedIndex = {}
      const businessUnit = this.extendOntologyChildren(
        [this.ontology.businessUnit],
        null,
        [],
        index,
        mappedIndex,
        levelLists,
        leaves,
        0,
        maxDepth,
      )[0]

      const result = {
        businessUnit,
        leaves,
        index,
        mappedIndex,
        selectAll: levelLists[1][0],
      }
      return result
    },
    fullExtendedOntology() {
      const maxDepth = this.ontology.depth
      const levelLists = range(0, maxDepth + 1).map(() => [])
      const leaves = []
      const index = {}
      const mappedIndex = {}
      const fakeRoot = {
        processId: 'root',
        name: 'Root',
        children: this.ontology.roots.map((root) => ({
          ...root,
          parentId: 'root',
        })),
        terms: [],
      }

      const result = {
        businessUnit: this.extendOntologyChildren(
          [fakeRoot],
          null,
          [],
          index,
          mappedIndex,
          levelLists,
          leaves,
          0,
          maxDepth,
        )[0],
        leaves,
        index,
        mappedIndex,
        selectAll: levelLists[1][0],
        selectBusinessUnit: levelLists[2].find(element => element.includes(this.ontology.businessUnit.processId)),
      }
      return result
    },
    otherBusinessUnits() {
      return this.fullExtendedOntology.businessUnit.realChildren.filter(
        (businessUnit) =>
          businessUnit.id !== this.ontology.businessUnit.processId,
      )
    },
    invalidSystemLabels() {
      return this.survey.surveyItems
        .filter((item) => !item.valid)
        .map((item) => item.values[this.surveyTemplate.keyField.fieldName])
    },
    ontologySelects() {
      const item = this.selectedOntologyItem

      return range(0, this.ontology.depth ).map((i) => {
        const result = {
          selected: this.fullExtendedOntology.mappedIndex[item.mappedPath[i + 1]],
          // Remove the "All" option from the first level is it is not useful
          options: i === 0 ? this.fullExtendedOntology.mappedIndex[item.mappedPath[i]].children.filter((child) => child.name !== 'All') : this.fullExtendedOntology.mappedIndex[item.mappedPath[i]].children
        }
        return result
      })
    },
    leftDisabled() {
      return (
        this.findFirstActiveOntologyItemLeft(
          this.selectedOntologyItemIndex - 1,
        ) === null
      )
    },
    rightDisabled() {
      return (
        this.findFirstActiveOntologyItemRight(
          this.selectedOntologyItemIndex + 1,
        ) === null
      )
    },
    activeProcessIdIndex() {
      const result = {}

      this.survey.surveyItems.forEach((item) => {
        if (item.ontologyItemProcessId != null) {
          const ontologyItem = this.ontology.index[item.ontologyItemProcessId]

          ontologyItem.path.forEach((i) => {
            ;(result[i.processId] = result[i.processId] || []).push(item)
          })
        }
      })

      return result
    },
    selectedOntologyItemLeaf() {
      console.log({ selectedOntologyItem: this.selectedOntologyItem })
      const item =
        this.fullExtendedOntology.index[this.selectedOntologyItem.processId]

      return item.realLeaf || item
    },
    __surveyItems() {
      // to be able to watch this
      return this.survey.surveyItems
    },
    surveyIsEmpty() {
      return this.survey.surveyItems.every((surveyItem) => surveyItem.readOnly)
    },
    incompleteSystemTotalCount() {
      // eslint-disable-next-line no-magic-numbers
      return this.filteredSystems(2).length
    },
    incompleteSystemCount() {
      // eslint-disable-next-line no-magic-numbers
      return this.filteredSystems(2).filter((s) => !s.readOnly).length
    },
  },
  watch: {
    __surveyItems() {
      this.$emit('filteredSystems', {
        list: this.filteredSystems(),
        stayOnPage: true,
      })
    },
  },
  created() {
    window.addEventListener('resize', this.handleResize)
    window.addEventListener('online', this.updateConnectionStatus)
    window.addEventListener('offline', this.updateConnectionStatus)

    // if (this.survey.surveyItems.length) {
    //   this.ontologyItems = this.fullExtendedOntology.leaves.map(
    //     (mappedId) => this.fullExtendedOntology.mappedIndex[mappedId],
    //   )
    // } else {
    this.ontologyItems = [
      this.fullExtendedOntology.mappedIndex[this.fullExtendedOntology.selectBusinessUnit],
    ]
    // }
    this.setOntologyItemIndex(this.findFirstActiveOntologyItemRight(0))
  },
  mounted() {
    this.handleResize()
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize)
    window.addEventListener('online', this.updateConnectionStatus)
    window.addEventListener('offline', this.updateConnectionStatus)
  },
  methods: {
    saveSurvey() {
      this.$store.dispatch('userSurveys/saveSurvey')
    },
    toggleSurvey() {
      const invalidSystems = this.invalidSystemLabels

      if (!this.survey.hasBeenSubmitted && invalidSystems.length) {
        this.validationDialogOpen = true
      } else if (!this.survey.hasBeenSubmitted && !invalidSystems.length) {
        this.submissionDialogOpen = true
      } else {
        this.flipSubmittedStatus()
      }
    },
    openBusinessLevelOverview() {
      this.businessUnitTreeDialogOpen = true
    },
    closeBusinessLevelOverview() {
      this.businessUnitTreeDialogOpen = false
    },
    flipSubmittedStatus() {
      this.$store.dispatch('userSurveys/setSubmitted', {
        survey: this.survey,
        submitted: !this.survey.hasBeenSubmitted,
      })
    },
    addNewSystem(newSurveyItem) {
      if (newSurveyItem.ontologyItem) {
        const idIndex = newSurveyItem.ontologyItem.id.toString().split('-')[0]
        var ontologyItem = this.ontology.index[idIndex]
      } else {
        var ontologyItem = null
      }

      console.log({ newSurveyItem, ontologyItem })

      const normalizedNewSurveyItem = {
        ...newSurveyItem,
        ontologyItem,
        businessUnitId: ontologyItem
          ? ontologyItem.path[0].processId
          : this.ontology.businessUnit.processId,
      }

      this.$store.dispatch('userSurveys/addSurveyItem', normalizedNewSurveyItem)
      this.setOntologyItem(newSurveyItem.ontologyItem)
      this.currentTab = 0

      if (newSurveyItem.ontologyItem.mappedPath[1] == this.ontology.businessUnit.processId) {
          this.$emit('filteredSystems', {
            list: this.filteredSystems(),
            goToLastPage: true,
          })
      } else {
        this.$emit('filteredSystems', {
          list: this.filteredSystems()
        })
      }
    },
    updateSystemOntologyItem(ontologyItem) {
      if (ontologyItem) {
        const idIndex = ontologyItem.id.toString().split('-')[0]
        var unmappedOntologyItem = this.ontology.index[idIndex]
      } else {
        var unmappedOntologyItem = null
      }
      console.log({ ontologyItem, unmappedOntologyItem }) // correct

      this.$store.dispatch('userSurveys/changeOntologyItem', {
        surveyItem: this.editingSystem,
        ontologyItem: unmappedOntologyItem,
        businessUnitId: unmappedOntologyItem
          ? unmappedOntologyItem.path[0].processId
          : this.ontology.businessUnit.processId,
      })
      this.cancelEditingSystem()

    },
    closeValidationDialog(forceSubmit) {
      if (forceSubmit) {
        this.flipSubmittedStatus()
      } else if (this.validationDialogOpen) {
        this.currentTab = 2
        this.$emit('filteredSystems', { list: this.filteredSystems() })
      }

      this.validationDialogOpen = false
      this.submissionDialogOpen = false
    },
    selectOntologyItem(ontologyItem) {
      if (
        !this.selectedOntologyItem.mappedPath.filter(
          (itemId) => itemId === ontologyItem.id,
        )[0]
      ) {
        this.setOntologyItem(ontologyItem.leaf || ontologyItem)
      }
    },
    setOntologyItem(ontologyItem) {
      this.selectedOntologyItem = ontologyItem
      this.ontologyItems = [
        this.fullExtendedOntology.mappedIndex[this.fullExtendedOntology.selectAll],
      ]
      // ontologyItem.level.map(
      //   (mappedItemId) => this.fullExtendedOntology.mappedIndex[mappedItemId],
      // )
      this.selectedOntologyItemIndex = ontologyItem
        ? this.ontologyItems.indexOf(ontologyItem)
        : null
      this.$emit('filteredSystems', { list: this.filteredSystems() })
    },
    setOntologyItemIndex(ontologyItemIndex) {
      this.selectedOntologyItem =
        ontologyItemIndex !== null
          ? this.ontologyItems[ontologyItemIndex]
          : this.ontologyItems[0]
      this.selectedOntologyItemIndex = ontologyItemIndex
      this.$emit('filteredSystems', { list: this.filteredSystems() })
    },
    filteredSystems(tab = this.currentTab, search = this.search) {
      switch (tab) {
        case 0:
          return (
            this.activeProcessIdIndex[this.selectedOntologyItem.processId] || []
          )
        case 1:
          return this.survey.surveyItems.filter(
            (item) =>
              Object.keys(item.values).filter(
                (k) => item.values[k] && searchMatches(item.values[k], search),
              )[0] ||
              (item.ontologyItem &&
                item.ontologyItem.path
                  .slice(1)
                  .filter((oi) => searchMatches(oi.name, search))[0]) ||
              searchMatches(
                this.ontology.index[item.businessUnitId].name,
                search,
              ),
          )
        /* eslint-disable-next-line no-magic-numbers */
        case 2:
          return this.survey.surveyItems.filter((item) => !item.valid)
        default:
          return null
      }
    },
    selectTab(tab) {
      this.$emit('filteredSystems', {
        list: this.filteredSystems(tab, this.search),
      })
    },
    goLeft() {
      this.setOntologyItemIndex(
        this.findFirstActiveOntologyItemLeft(
          this.selectedOntologyItemIndex - 1,
        ),
      )
    },
    goRight() {
      this.setOntologyItemIndex(
        this.findFirstActiveOntologyItemRight(
          this.selectedOntologyItemIndex + 1,
        ),
      )
    },
    updateSearch(search) {
      this.$emit('filteredSystems', {
        list: this.filteredSystems(this.currentTab, search),
      })
    },
    findFirstActiveOntologyItemLeft(ontologyItemIndex) {
      return this.findFirstActiveOntologyItem(ontologyItemIndex, -1)
    },
    findFirstActiveOntologyItemRight(ontologyItemIndex) {
      return this.findFirstActiveOntologyItem(ontologyItemIndex, 1)
    },
    findFirstActiveOntologyItem(ontologyItemIndex, delta) {
      let index = ontologyItemIndex

      if (index < 0 || index >= this.ontologyItems.length) {
        return null
      }
      let item = this.ontologyItems[index]

      while (!this.activeProcessIdIndex[item.processId]) {
        index += delta

        if (index < 0 || index >= this.ontologyItems.length) {
          return null
        }

        item = this.ontologyItems[index]
      }

      return index
    },
    goToOntologyItem(ontologyItem) {
      this.closeBusinessLevelOverview()
      this.currentTab = 0
      this.selectOntologyItem(ontologyItem)
    },
    // eslint-disable-next-line max-params
    extendOntologyChildren(
      items,
      parent,
      path,
      index,
      mappedIndex,
      levelLists,
      leaves,
      depth,
      maxDepth,
    ) {
      const virtualItem = parent
        ? [
            this.createAllOntologyItem(
              parent,
              path,
              mappedIndex,
              levelLists[depth],
              depth,
              maxDepth,
            ),
          ]
        : []

      return virtualItem.concat(
        // eslint-disable-next-line max-statements
        items.map((item) => {
          const mappedItem = {
            ...item,
            type: 'real',
            id: item.processId,
          }

          // eslint-disable-next-line no-underscore-dangle
          delete mappedItem.__typename
          delete mappedItem.parent
          delete mappedItem.parentId
          delete mappedItem.path

          mappedItem.mappedPath = [...path, mappedItem.id]
          mappedItem.level = leaves
          leaves.push(mappedItem.id)
          if (!item.children) {
            delete mappedItem.fullName
          } else {
            mappedItem.children = this.extendOntologyChildren(
              item.children,
              item,
              [...path, mappedItem.id],
              index,
              mappedIndex,
              levelLists,
              leaves,
              depth + 1,
              maxDepth,
            )
            mappedItem.realChildren = mappedItem.children.filter(
              (i) => i.type === 'real',
            )

            mappedItem.leaf =
              mappedItem.children[0].leaf || mappedItem.children[0]
            mappedItem.realLeaf =
              mappedItem.realChildren[0].realLeaf || mappedItem.realChildren[0]
            delete mappedItem.processId
          }

          index[item.processId] = mappedItem

          if (mappedIndex[mappedItem.id]) {
            throw new Error('oops')
          }

          mappedIndex[mappedItem.id] = mappedItem

          return mappedItem
        }),
      )
    },
    // eslint-disable-next-line max-statements
    createAllOntologyItem(parent, path, mappedIndex, level, depth, maxDepth) {
      const item = Object.assign({}, parent, {
        name: 'All',
        type: 'virtual',
        id: `${parent.processId}-all[${depth}]`,
      })

      mappedIndex[item.id] = item
      // eslint-disable-next-line no-underscore-dangle
      delete item.__typename
      delete item.parent
      delete item.parentId
      delete item.path

      if (depth === maxDepth) {
        level.push(item.id)

        item.level = level
        item.mappedPath = path.concat([item.id])
        delete item.children
      } else {
        item.children = [
          this.createAllOntologyItem(
            parent,
            path.concat([item.id]),
            mappedIndex,
            level,
            depth + 1,
            maxDepth,
          ),
        ]
        item.leaf = item.children[0].leaf || item.children[0]
        delete item.processId
      }

      return item
    },
    handleResize() {
      this.windowWidth = window.innerWidth
    },
    smallScreen() {
      const smallScreenWidth = 1200

      return this.windowWidth < smallScreenWidth
    },
    updateConnectionStatus() {
      this.online = window.navigator.onLine
    },
    cancelEditingSystem() {
      this.$emit('cancelEditingSystem')
    },
    importSystems(systems) {
      systems.forEach((system) => {
        system.ontologyItem = this.ontology.index[system.ontologyItemProcessId]
      })
      this.$store.dispatch('userSurveys/importSurveyItems', systems)
      this.currentTab = 1
      this.$emit('filteredSystems', { list: this.filteredSystems() })
    },
  },
}
</script>

<style lang="scss">
.line-tabs {
  .v-tabs__bar {
    background-color: transparent;
  }
  .v-tabs__slider {
    height: 3px;
  }
}
.incomplete_hl {
  color: red;
}
</style>
