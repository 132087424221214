<template>
  <v-dialog v-model="isOpen" max-width="500px">
    <v-card>
      <v-card-title>
        <span class="headline">Download Taxonomy Version Mapping</span>
      </v-card-title>

      <v-card-text v-if="toTaxonomyVersion">
        <p>Version: {{ toTaxonomyVersion.name }}</p>
        <p v-if="!previousTaxonomyVersionId">
          Error: No parent version available
        </p>
        <div class="actions">
          <v-btn @click="showDialog(false)">Cancel</v-btn>
          <v-btn @click="download" :disabled="!mappings">Download</v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { downloadTaxonomyVersionMappingStore } from '../../../store/modules/admin/dialog/download-taxonomy-version-mapping'
import ontologyStore from '../../../store/modules/admin/ontology'
import TaxonomyVersionSelector2 from '../components/TaxonomyVersionSelector2'

export default {
  name: 'DownloadTaxonomyVersionMappingDialog',
  components: {
    TaxonomyVersionSelector2,
  },
  computed: {
    ...downloadTaxonomyVersionMappingStore.mapGetters({
      previousTaxonomyVersionId: 'previousTaxonomyVersionId',
    }),
    ...downloadTaxonomyVersionMappingStore.mapState({
      mappings: (state) => state.mappings,
    }),
    ...downloadTaxonomyVersionMappingStore.mapAccessors({
      isOpen: 'dialogIsOpen',
    }),
    ...ontologyStore.mapState({
      toTaxonomyVersion: (state) => state.selectedTaxonomyVersion,
      toOntologyItemsById: (state) => state.allByProcessId,
    }),
  },
  methods: {
    ...downloadTaxonomyVersionMappingStore.mapActions({
      showDialog: 'setDialogIsOpen',
      download: 'downloadVersionMappingCsv',
    }),
  },
}
</script>

<style>
.actions {
  display: flex;
  justify-content: space-between;
}
</style>
