import { config } from '../config'

export const buildUrl = (path, params) => {
	if (params) {
		const serializedParams = Object.keys(params)
			.map((key) => {
				const val = params[key]
				const serializedVal = Array.isArray(val) ? val.join(',') : val

				return `${encodeURIComponent(key)}=${encodeURIComponent(serializedVal)}`
			}).join('&')

		return `${config.apiUrl}${path}?${serializedParams}`
	} else {
		return config.apiUrl + path
	}
}
