import 'whatwg-fetch'
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import Vue from 'vue'
import Vuebar from 'vuebar'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import './plugins/vuetify'
import App from './App.vue'
import router from './router'
import store from './store/index'
import './registerServiceWorker'
import LineClamp from 'vue-line-clamp'

Vue.config.productionTip = false
Vue.use(Vuebar)
Vue.use(LineClamp)

new Vue({
	router,
	store,
	render: h => h(App),
}).$mount('#app')
