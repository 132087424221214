<template>
	<div class="chart-container">
		<h1 v-if="slotHasContent('caption')">
			<slot :count="groups.length" name="caption" />
		</h1>
		<div v-if="filterDefs" class="bar-chart-filters">
			<dataset-filters
				:dataset="dataset"
				:filter-defs="filterDefs"
				:color-cache="colorCache"
				:show-labels="false"
				:horizontal="horizontalFilterDefs"
				inline
				@update="updateDataset($event)"
			/>
		</div>
		<div v-if="slotHasContent('header')" class="bar-chart-header">
			<slot name="header" />
		</div>
		<div :class="{ 'with-legend': Boolean(stackProperty) }" class="bar-chart">
			<div class="bar-chart-content">
				<div
					v-for="(tick, i) in ticks"
					:key="i"
					:style="{
						bottom: `calc(${tick.ratio * 100}% - ${tick.ratio * 10}px)`,
					}"
					class="bar-chart-tick"
				>
					<slot :value="tick.value" :total="total" name="tick">
						{{ tick.value }}
					</slot>
				</div>
				<div
					v-for="(group, i) in groups"
					:key="`label-${group.key}`"
					:style="{
						left: `${((i + 0.5) / groups.length) * 100}%`,
						width: `calc(${100 / groups.length}% - 6px)`,
					}"
					:class="{
						selected:
							group.key ===
							(highlighted !== null
								? typeof highlighted === 'string'
									? highlighted
									: highlighted[0]
								: null),
					}"
					class="bar-chart-label"
				>
					<table class="bar-chart-label-positioner1">
						<tr class="bar-chart-label-positioner2">
							<td class="bar-chart-label-content">
								{{ group.key }}
							</td>
						</tr>
					</table>
				</div>
				<template v-if="stackProperty">
					<stacked-bar
						v-for="(group, i) in groups"
						:key="`bar-${group.key}`"
						:index="i"
						:count="groups.length"
						:dataset="group"
						:max-value="maxValue"
						:property="stackProperty"
						:color-for="(value) => colorFor(stackProperty, value)"
						:highlight="highlighted"
						:class="{ clickable: hasSelectListener }"
						@select="select([group.key, $event])"
						@deselect="deselect([group.key, $event])"
						@click="$emit('select', group.key)"
					>
						<!-- <template slot-scope="{ slotDataset }">
							<slot :dataset="slotDataset" name="value">
								{{ slotDataset.count }}
							</slot>
						</template> -->
					</stacked-bar>
				</template>
				<template v-else>
					<bar-chart-bar
						v-for="(group, i) in groups"
						:key="`bar-${group.key}`"
						:index="i"
						:count="groups.length"
						:dataset="group"
						:max-value="maxValue"
						:color="colorFor(barProperty, group.key)"
						:highlight="highlighted"
						:class="{ clickable: hasSelectListener }"
						@select="select(group.key)"
						@deselect="deselect(group.key)"
						@click="$emit('select', group.key)"
					>
						<!-- <template slot-scope="{ slotDataset }">
							<slot :dataset="slotDataset" name="value">
								{{ slotDataset.count }}
							</slot>
						</template> -->
					</bar-chart-bar>
				</template>
				<div v-if="groups.length === 0" class="bar-chart-no-data" />
			</div>
			<div v-if="stackProperty" class="bar-chart-legend">
				<div
					v-for="value in stackValues"
					:key="value"
					:style="{ background: colorFor(stackProperty, value) }"
					class="bar-chart-legend-item"
					@mouseover="selectLegendItem(value)"
					@mouseout="deselectLegendItem(value)"
				>
					<template v-if="value">
						{{ value }}
					</template><template v-else>
						<i>empty</i>
					</template>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { range } from 'lodash/fp'
import { ColorCache } from '../../../lib/color_cache'
import { Dataset } from '../../../lib/dataset'
import BarChartBar from './BarChartBar.vue'
import DatasetFilters from './DatasetFilters.vue'
import StackedBar from './StackedBar.vue'

const STEPS = [1, 2, 5] // eslint-disable-line no-magic-numbers
const PREFERRED_TICK_COUNT = 4

// const SCALES = [0.01, 0.1, 1, 10, 100, 1000, 10000, 100000, 1000000] // eslint-disable-line no-magic-numbers
const SCALES = [1, 10, 100, 1000, 10000, 100000, 1000000] // eslint-disable-line no-magic-numbers

export default {
	components: { BarChartBar, StackedBar, DatasetFilters },
	props: {
		filterDefs: {
			type: Array,
			default: null,
		},
		dataset: {
			type: Dataset,
			required: true,
		},
		quantity: {
			type: String,
			default: null,
		},
		barProperty: {
			type: String,
			required: true,
		},
		stackProperty: {
			type: String,
			default: null,
		},
		includeEmpty: {
			type: Boolean,
			default: false,
		},
		sortByCount: {
			type: String,
			default: null,
		},
		colorCache: {
			type: ColorCache,
			default: null,
		},
		horizontalFilterDefs: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			highlighted: null,
			filteredDataset: this.dataset,
		}
	},
	computed: {
		cleanedDataset() {
			let dataset = this.filteredDataset

			if (!this.includeEmpty) {
				dataset = dataset.filterMissing(this.barProperty)

				if (this.stackProperty) {
					dataset = dataset.filterMissing(this.stackProperty)
				}
			}

			return dataset
		},
		groups() {
			const groups = this.cleanedDataset.groupBy(this.barProperty)

			return this.sortByCount === 'DESC'
				? groups.sort((g1, g2) => g2.count - g1.count)
				: this.sortByCount === 'ASC'
					? groups.sort((g1, g2) => g1.count - g2.count)
					: groups
		},
		maxValue() {
			return Math.max(0, ...this.groups.map((b) => b.count))
		},
		barCount() {
			return this.groups.length
		},
		ticks() {
			let bestStep = null
			let bestTickCount = null
			let bestDistanceToPreferredTickCount = null

			STEPS.forEach((step) => {
				SCALES.forEach((scale) => {
					const totalStep = step * scale
					const tickCount = Math.floor(this.maxValue / totalStep)
					const distanceToPreferredTickCount = Math.abs(
						PREFERRED_TICK_COUNT - tickCount,
					)

					if (
						bestDistanceToPreferredTickCount === null
						|| bestDistanceToPreferredTickCount > distanceToPreferredTickCount
					) {
						bestStep = totalStep
						bestTickCount = tickCount
						bestDistanceToPreferredTickCount = distanceToPreferredTickCount
					}
				})
			})

			return range(1, bestTickCount + 1).map((i) => ({
				value: i * bestStep,
				ratio: (i * bestStep) / this.maxValue,
			}))
		},
		stackValues() {
			if (this.stackProperty) {
				return this.cleanedDataset.uniqueValuesFor(this.stackProperty)
			} else {
				return []
			}
		},
		hasSelectListener() {
			return this.$listeners && this.$listeners.select
		},
		total() {
			return this.dataset.count
		},
	},
	watch: {
		dataset() {
			this.filteredDataset = this.dataset
		},
	},
	methods: {
		colorFor(property, value) {
			return this.colorCache.colorFor(property, value)
		},
		select(value) {
			this.highlighted = value
		},
		deselect() {
			this.highlighted = null
		},
		selectLegendItem(value) {
			this.highlighted = [null, value]
		},
		deselectLegendItem() {
			this.highlighted = null
		},
		updateDataset(dataset) {
			this.filteredDataset = dataset
		},
		slotHasContent(slot) {
			return this.$slots[slot] || this.$scopedSlots[slot]
		},
	},
}
</script>

<style lang="scss">
@import '../../../assets/scss/pages/user/charts/bar';
</style>
