<!-- eslint-disable vue/no-mutating-props -->
<template>
  <v-dialog
    :value="open"
    max-width="650px"
    @input="$emit('toggleOpen', $event)"
    @keydown.esc="open = false"
  >
    <v-btn
      v-show="!hideButton"
      slot="activator"
      color="primary"
      dark
      class="button"
    >
      Select a new business capability
    </v-btn>
    <v-card>
      <v-card-title>
        <span class="headline">Select a new business capability</span>
      </v-card-title>
      <v-card-text>
        <business-unit-selector
          v-if="open"
          :trees="
            addRoot
              ? [ontology.businessUnit]
              : ontology.businessUnit.realChildren
          "
          :disabled-trees="[]"
          :systems-by-process-id="systemsByProcessId"
          :ontology-item-index="ontology.mappedIndex"
          :select-only-leafs="false"
          :prune-empty="false"
          :closed="closed"
          :highlighted-path="highlightedPath"
          :disable-item="disableItem"
          @selectOntologyItem="select($event)"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="blue darken-1" flat @click="$emit('toggleOpen', false)">
          cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import BusinessUnitSelector from './BusinessUnitSelector.vue'

export default {
  components: {
    BusinessUnitSelector,
  },
  props: {
    systemsByProcessId: {
      type: Object,
      required: true,
    },
    ontology: {
      type: Object,
      required: true,
      default: null,
    },
    closed: {
      type: Boolean,
      default: true,
    },
    highlightedPath: {
      type: Array,
      default: null,
    },
    addRoot: {
      type: Boolean,
      default: false,
    },
    open: {
      type: Boolean,
      required: true,
    },
    hideButton: {
      type: Boolean,
      default: false,
    },
    disableItem: {
      type: Object,
      default: null,
    },
  },

  // data() {
  // 	return {
  // 		open: false,
  // 	}
  // },
  watch: {
    open() {
      if (this.open && this.highlightedPath) {
        setTimeout(() => {
          const el = document.getElementById(
            `tree-item-${this.highlightedPath.slice(-1)[0]}`,
          )

          if (el) {
            el.scrollIntoView()
          }
          // eslint-disable-next-line no-magic-numbers
        }, 10)
      }
    },
  },
  methods: {
    select(ontologyItem) {
      this.$emit('selectOntologyItem', ontologyItem)
      this.$emit('toggleOpen', false)
    },
  },
}
</script>
