<template>
	<div :class="{ next: next, previous: !next, disabled }" class="chart-filter-navigation-button" @click="updateFilter">
&nbsp;
	</div>
</template>

<script>
import { Dataset } from '../../../lib/dataset'

export default {
	props: {
		next: {
			type: Boolean,
			default: false,
		},
		dataset: {
			type: Dataset,
			required: true,
		},
		properties: {
			type: Array,
			required: true,
		},
		filter: {
			type: Object,
			required: true,
		},
	},
	computed: {
		disabled() {
			return !this.toFilter
		},
		toFilter() {
			return this.next ? this.dataset.nextFilter(this.filter, this.properties) : this.dataset.previousFilter(this.filter, this.properties)
		},
	},
	methods: {
		updateFilter() {
			this.$emit('update', this.toFilter)
		},
	},
}
</script>
