<template>
	<div>
		<dataset-list-filter
			v-if="filterDef.type === 'list'"
			:dataset="dataset"
			:filter="fullFilter[filterDef.property] || null"
			:color-cache="colorCache"
			:property="filterDef.property"
			:inline="inline"
			:single="filterDef.single"
			:include-all="filterDef.includeAll"
			:force-all="forceAll"
			:default-not-all="filterDef.defaultNotAll"
			:show-controls="filterDef.showControls"
			:sort="filterDef.sort"
			@update="$emit('update', { [filterDef.property]: $event })"
		/>
		<dataset-dropdown-filter
			v-if="filterDef.type === 'dropdown'"
			:dataset="dataset"
			:filter="fullFilter[filterDef.property] || null"
			:color-cache="colorCache"
			:property="filterDef.property"
			:include-all="filterDef.includeAll"
			:force-all="forceAll"
			:default-not-all="filterDef.defaultNotAll"
			:sort="filterDef.sort"
			@update="$emit('update', { [filterDef.property]: $event })"
		/>
		<dataset-navigation-button
			v-if="filterDef.type === 'previousButton'"
			:dataset="navigationDataset"
			:filter="fullFilter"
			:properties="filterDef.properties"
			:next="false"
			@update="$emit('update', $event)"
		/>
		<dataset-navigation-button
			v-if="filterDef.type === 'nextButton'"
			:dataset="navigationDataset"
			:filter="fullFilter"
			:properties="filterDef.properties"
			:next="true"
			@update="$emit('update', $event)"
		/>
		<div v-if="filterDef.type === 'space'" class="chart-filter-space" />
	</div>
</template>

<script>
import { Dataset } from '../../../lib/dataset'
import { ColorCache } from '../../../lib/color_cache'
import DatasetListFilter from './DatasetListFilter'
import DatasetDropdownFilter from './DatasetDropdownFilter'
import DatasetNavigationButton from './DatasetNavigationButton'

export default {
	name: 'DatasetFilter',
	components: { DatasetListFilter, DatasetDropdownFilter, DatasetNavigationButton },
	props: {
		filterDef: {
			type: Object,
			required: true,
		},
		unfilteredDataset: {
			type: Dataset,
			required: true,
		},
		fullFilter: {
			type: Object,
			default() { return {} },
		},
		colorCache: {
			type: ColorCache,
			default: null,
		},
		inline: {
			type: Boolean,
			default: false,
		},
		precedingProperties: {
			type: Array,
			required: true,
		},
	},
	computed: {
		dataset() {
			return this.unfilteredDataset.applyPartialFilter(this.fullFilter, this.precedingProperties)
		},
		navigationDataset() {
			const propHash = this.precedingProperties.reduce((h, prop) => {
				h[prop] = true

				return h
			}, {})

			this.filterDef.properties.forEach((prop) => {
				delete propHash[prop]
			})

			return this.unfilteredDataset.applyPartialFilter(this.fullFilter, Object.keys(propHash))
		},
		forceAll() {
			return this.filterDef.includeAll && this.filterDef.forceAllProperty && this.fullFilter[this.filterDef.forceAllProperty] === Dataset.ALL
		},
	},
}
</script>
