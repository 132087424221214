<template>
	<v-dialog
		:value="open"
		persistent
		max-width="500px"
		@input="$emit('close', false)"
	>
		<v-card>
			<v-card-title>
				<span class="headline">Systems with incomplete information</span>
			</v-card-title>
			<v-card-text>
				<v-container grid-list-md>
					<v-layout wrap="">
						<v-flex xs12>
							{{ invalidSystems.length }} systems have incomplete information. Please complete them using the 'Incomplete' tab.
							<!-- <ul style="max-height: 300px; overflow: auto; ">
								<li v-for="(system, index) in invalidSystems" :key="index">{{ system }}</li>
							</ul> -->
						</v-flex>
					</v-layout>
				</v-container>
			</v-card-text>
			<v-card-actions>
				<v-spacer />
				<v-btn color="blue darken-1" flat @click.native="$emit('close', true)">
					Submit anyway
				</v-btn>
				<v-btn color="blue darken-1" flat @click.native="$emit('close', false)">
					Review systems
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	props: {
		open: {
			type: Boolean,
			required: true,
		},
		invalidSystems: {
			type: Array,
			required: true,
		},
	},
}
</script>

<style>

</style>
