import { keyBy } from 'lodash'
import { api } from '../../../../api/api-service'
import { downloadCsv } from '../../../../lib/csv'
import {
  generateTaxonomyVersionMappingCsvData,
  treeifyOntologyItems,
} from '../../../../lib/ontology'
import { createStoreUtils } from '../../../../lib/vuex'

/**
 * @typedef {Store.Ontology.DownloadTaxonomyVersionMapping.LocalState} State
 * @typedef {typeof getters} Getters
 * @typedef {typeof mutations} Mutations
 * @typedef {typeof actions} Actions
 */

/**
 * @template {any} Payload [Payload=void]
 * @typedef {MutationFor<State, Payload>} Mutate
 */

/**
 * @template {any} Payload [Payload=void]
 * @typedef {ActionFor<Mutations, State, Payload, void>} Act
 */

/** @type {State}  */
const initialState = {
  isOpen: false,
  mappings: null,
  oldOntologyItems: null,
  oldOntologyItemsByProcessId: null,
}

/** @satisfies {GettersFor<State>} */
const getters = {
  previousTaxonomyVersionId(state, getters, rootState) {
    const selectedTaxonomyVersion = rootState.ontology.selectedTaxonomyVersion
    if (!selectedTaxonomyVersion) {
      return null
    } else {
      return selectedTaxonomyVersion.parentTaxonomyVersionId
    }
  },

  // Accessors
  dialogIsOpen(state) {
    return state.isOpen
  },
}

/** @satisfies {MutationsFor<State>} */
const mutations = {
  /** @type {Mutate<Api.GetVersionMappings.TaxonomyVersionMappingResult[]>} */
  setMappings(state, mappings) {
    state.mappings = mappings
  },
  /** @type {Mutate<Model.OntologyItem[]>} */
  setOldOntologyItems(state, oldOntologyItems) {
    state.oldOntologyItems = oldOntologyItems
    state.oldOntologyItemsByProcessId = keyBy(oldOntologyItems, 'processId')
    treeifyOntologyItems({ byId: state.oldOntologyItemsByProcessId })
  },

  // Accessors
  /** @type {Mutate<boolean>} */
  setDialogIsOpen(state, isOpen) {
    state.isOpen = isOpen
  },
}

/** @satisfies {ActionsFor<Mutations, State>} */
const actions = {
  /** @type {Act<{toTaxonomyVersion: Model.TaxonomyVersion}>} */
  async openDialog({ commit, dispatch }, { toTaxonomyVersion }) {
    commit('setDialogIsOpen', true)
    dispatch('loadData')
  },

  /** @type {Act<void>} */
  async loadData({ commit, rootState }) {
    const selectedTaxonomyVersion = rootState.ontology.selectedTaxonomyVersion
    if (!selectedTaxonomyVersion) {
      throw new Error('No selected taxonomy version')
    }
    if (!selectedTaxonomyVersion.parentTaxonomyVersionId) {
      return
    }

    const { oldOntologyItems, taxonomyVersionMappings } =
      await api.getTaxonomyVersionMapping({
        fromTaxonomyVersionId: selectedTaxonomyVersion.parentTaxonomyVersionId,
        toTaxonomyVersionId: selectedTaxonomyVersion.id,
      })
    commit('setMappings', taxonomyVersionMappings)
    commit('setOldOntologyItems', oldOntologyItems)
  },

  /** @type {Act<void>} */
  async downloadVersionMappingCsv({ state, commit, rootState }) {
    if (!state.mappings) throw new Error('No mappings')
    if (!state.oldOntologyItemsByProcessId) throw new Error('No old ontology')
    if (!rootState.ontology.allByProcessId) throw new Error('No new ontology')

    const csvData = generateTaxonomyVersionMappingCsvData({
      mappings: state.mappings,
      oldOntologyItemsByProcessId: state.oldOntologyItemsByProcessId,
      newOntologyItemsByProcessId: rootState.ontology.allByProcessId,
      taxonomyVersions: rootState.ontology.taxonomyVersions
    })
    const sanitizedName = 'FILENAME'
      .replace(/[^a-z0-9]/gi, '-')
      .replaceAll(/-+/g, '-')
      .replaceAll(/^-|-$/g, '')
      .toLowerCase()

    downloadCsv(`version-mapping-from-${sanitizedName}.csv`, csvData)
    commit('setDialogIsOpen', false)
  },

  // Accessors
  /** @type {Act<boolean>} */
  async setDialogIsOpen({ commit }, isOpen) {
    commit('setDialogIsOpen', isOpen)
  },
}

export const downloadTaxonomyVersionMappingStore = {
  namespaced: true,
  ...createStoreUtils('ontology/downloadTaxonomyVersionMapping', {
    state: initialState,
    getters,
    mutations,
    actions,
  }),
}
