<template>
	<v-app>
		<router-view />
		<footer class="footer">
			<v-flex row wrap>
				<a href="https://xaop.com/" target="_blank" class="footer-xaop-link">
					<p class="footer-xaop-text">
						Powered by
					</p>
					<div class="footer-xaop-logo" />
				</a>
				<p class="footer-xaop-support-link">
					Spotted a bug or need help? Contact support at <a href="mailto:prismesupport@xaop.com"> prismesupport@xaop.com </a>
				</p>
			</v-flex>
		</footer>
	</v-app>
</template>

<script>
export default {
	name: 'App',
	data() {
		return {
			fixed: false,
		}
	},
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Work+Sans:400,500,600');
@import './assets/scss/general';
</style>
