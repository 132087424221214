<template>
	<v-dialog
		:value="open"
		persistent
		max-width="500px"
		@input="$emit('close', false)"
	>
		<v-card>
			<v-card-title>
				<span class="headline">Are you sure you want to submit the survey?</span>
			</v-card-title>
			<v-card-text>
				<v-container grid-list-md>
					<v-layout wrap="">
						<v-flex xs12>
							This will indicate to the admin that you are done filling out the survey for this business capability level 1 and will render the survey read-only
							<!-- <ul style="max-height: 300px; overflow: auto; ">
								<li v-for="(system, index) in invalidSystems" :key="index">{{ system }}</li>
							</ul> -->
						</v-flex>
					</v-layout>
				</v-container>
			</v-card-text>
			<v-card-actions>
				<v-spacer />
				<v-btn color="blue darken-1" flat @click.native="$emit('close', false)">
					cancel
				</v-btn>
				<v-btn color="blue darken-1" flat @click.native="$emit('close', true)">
					submit
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	props: {
		open: {
			type: Boolean,
			required: true,
		},
	},
}
</script>

<style>

</style>
