import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify, {
	iconfont: 'mdi',
	theme: {
		primary: '#105187',
		secondary: '#197FD3',
		accent: '#F59423',
	},
})
