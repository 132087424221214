var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"chart-container"},[(_vm.slotHasContent('caption'))?_c('h1',[_vm._t("caption",null,{"count":_vm.groups.length})],2):_vm._e(),(_vm.filterDefs)?_c('div',{staticClass:"bar-chart-filters"},[_c('dataset-filters',{attrs:{"dataset":_vm.dataset,"filter-defs":_vm.filterDefs,"color-cache":_vm.colorCache,"show-labels":false,"horizontal":_vm.horizontalFilterDefs,"inline":""},on:{"update":function($event){return _vm.updateDataset($event)}}})],1):_vm._e(),(_vm.slotHasContent('header'))?_c('div',{staticClass:"bar-chart-header"},[_vm._t("header")],2):_vm._e(),_c('div',{staticClass:"bar-chart",class:{ 'with-legend': Boolean(_vm.stackProperty) }},[_c('div',{staticClass:"bar-chart-content"},[_vm._l((_vm.ticks),function(tick,i){return _c('div',{key:i,staticClass:"bar-chart-tick",style:({
					bottom: `calc(${tick.ratio * 100}% - ${tick.ratio * 10}px)`,
				})},[_vm._t("tick",function(){return [_vm._v(" "+_vm._s(tick.value)+" ")]},{"value":tick.value,"total":_vm.total})],2)}),_vm._l((_vm.groups),function(group,i){return _c('div',{key:`label-${group.key}`,staticClass:"bar-chart-label",class:{
					selected:
						group.key ===
						(_vm.highlighted !== null
							? typeof _vm.highlighted === 'string'
								? _vm.highlighted
								: _vm.highlighted[0]
							: null),
				},style:({
					left: `${((i + 0.5) / _vm.groups.length) * 100}%`,
					width: `calc(${100 / _vm.groups.length}% - 6px)`,
				})},[_c('table',{staticClass:"bar-chart-label-positioner1"},[_c('tr',{staticClass:"bar-chart-label-positioner2"},[_c('td',{staticClass:"bar-chart-label-content"},[_vm._v(" "+_vm._s(group.key)+" ")])])])])}),(_vm.stackProperty)?_vm._l((_vm.groups),function(group,i){return _c('stacked-bar',{key:`bar-${group.key}`,class:{ clickable: _vm.hasSelectListener },attrs:{"index":i,"count":_vm.groups.length,"dataset":group,"max-value":_vm.maxValue,"property":_vm.stackProperty,"color-for":(value) => _vm.colorFor(_vm.stackProperty, value),"highlight":_vm.highlighted},on:{"select":function($event){return _vm.select([group.key, $event])},"deselect":function($event){return _vm.deselect([group.key, $event])},"click":function($event){return _vm.$emit('select', group.key)}}})}):_vm._l((_vm.groups),function(group,i){return _c('bar-chart-bar',{key:`bar-${group.key}`,class:{ clickable: _vm.hasSelectListener },attrs:{"index":i,"count":_vm.groups.length,"dataset":group,"max-value":_vm.maxValue,"color":_vm.colorFor(_vm.barProperty, group.key),"highlight":_vm.highlighted},on:{"select":function($event){return _vm.select(group.key)},"deselect":function($event){return _vm.deselect(group.key)},"click":function($event){return _vm.$emit('select', group.key)}}})}),(_vm.groups.length === 0)?_c('div',{staticClass:"bar-chart-no-data"}):_vm._e()],2),(_vm.stackProperty)?_c('div',{staticClass:"bar-chart-legend"},_vm._l((_vm.stackValues),function(value){return _c('div',{key:value,staticClass:"bar-chart-legend-item",style:({ background: _vm.colorFor(_vm.stackProperty, value) }),on:{"mouseover":function($event){return _vm.selectLegendItem(value)},"mouseout":function($event){return _vm.deselectLegendItem(value)}}},[(value)?[_vm._v(" "+_vm._s(value)+" ")]:[_c('i',[_vm._v("empty")])]],2)}),0):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }