<template>
	<div class="survey-header" fluid>
		<div class="survey-header-logo-wrapper">
			<router-link to="/">
				<div class="survey-header-logo prisme" />
			</router-link>
			<router-link v-if="$route.path === '/'" to="/ontology" class="link survey-header-link" style="margin-left: 40px;">
				Business Capability Taxonomy Browser
			</router-link>
		</div>
		<h3 v-if="$route.name === 'userSurvey' && businessUnit" class="survey-header-title">
			{{ surveyTemplate.name }} -
			<div class="business-unit-switcher">
				<v-select
					:value="businessUnit"
					:items="otherBusinessUnits"
					item-text="name"
					item-value="processId"
					@input="changeBusinessUnit($event)"
				/>
			</div>
		</h3>
		<h3 v-if="businessUnit && survey.hasBeenSubmitted" class="survey-header-title-submitted">
			<br> Submitted
		</h3>
		<div ref="userOptions" v-click-outside="hideUserOptions" class="survey-header-user">
			<router-link v-if="isAdmin && !businessUnit" to="/admin" class="link survey-header-link">
				To Admin page
			</router-link>
			<router-link to="/help" class="link survey-header-link">
				To help page
			</router-link>
			<div ref="userOptions" v-click-outside="hideUserOptions" class="survey-header-user-info">
				<div class="survey-header-user-toggle-button" @click="toggleUserOptions">
					<p class="survey-header-user-text">
						{{ user.company.name }} - {{ user.firstName }} {{ user.lastName }}
					</p>
					<div class="survey-header-user-icon" />
				</div>
				<div v-show="showUserOptions" class="survey-header-user-options">
					<div v-if="user.hasPassword">
						<v-btn
							slot="activator"
							color="primary"
							dark
							class="button"
							@click="OpenChangePassword()"
						>
							Change password
						</v-btn>
					</div>
					<v-btn
						color="primary"
						dark
						class="button"
						flat
						@click="doLogout($event)"
					>
						Logout
					</v-btn>
				</div>
				<v-dialog v-model="changePasswordDialog" max-width="500px" persistent>
					<v-card>
						<v-card-title>
							<span class="headline">Change password</span>
						</v-card-title>
						<v-card-text>
							<v-container grid-list-md>
								<v-layout wrap="">
									<v-flex xs12>
										<v-text-field
											v-model="newPassword"
											:error-messages="passwordErrors(newPassword).concat(passwordsEqual(newPassword,retypePassword))"
											:append-icon="showNewPassword ? 'visibility_off' : 'visibility'"
											:type="showNewPassword ? 'text' : 'password'"
											label="new password"
											@click:append="showNewPassword = !showNewPassword"
										/>
										<v-text-field
											v-model="retypePassword"
											:error-messages="passwordErrors(retypePassword).concat(passwordsEqual(newPassword,retypePassword))"
											:append-icon="showNewPassword ? 'visibility_off' : 'visibility'"
											:type="showNewPassword ? 'text' : 'password'"
											label="current password"
											@click:append="showNewPassword = !showNewPassword"
										/>
									</v-flex>
								</v-layout>
							</v-container>
						</v-card-text>
						<v-card-actions>
							<v-spacer />
							<v-btn color="blue darken-1" flat @click.native="closeChangePassword">
								Cancel
							</v-btn>
							<v-btn :disabled="!validPasswords(newPassword,retypePassword)" color="blue darken-1" flat @click.native="changePassword">
								Change password
							</v-btn>
						</v-card-actions>
					</v-card>
					<v-alert
						v-model="changePasswordFailedWarning"
						type="warning"
					>
						Password change failed
					</v-alert>
					<v-alert
						v-model="nonMatchingPasswordsWarning"
						type="warning"
					>
						Passwords do not match
					</v-alert>
					<v-alert
						v-model="changePasswordAlert"
						class="login-warning"
						type="info"
					>
						Password has been changed
					</v-alert>
				</v-dialog>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import ClickOutside from 'vue-click-outside'

export default {
	directives: {
		ClickOutside,
	},
	props: {
		cantSave: {
			type: Boolean,
			required: true,
		},
	},
	data() {
		return {
			showUserOptions: false,
			changePasswordDialog: false,
			newPassword: '',
			changePasswordFailedWarning: false,
			showNewPassword: false,
			processing: false,
			nonMatchingPasswordsWarning: false,
			changePasswordAlert: false,
			retypePassword: '',
			validpasswords: false,
		}
	},
	computed: {
		...mapState({
			surveyTemplate: state => state.userSurveys.surveyTemplate,
			businessUnit: state => state.userSurveys.ontology.businessUnit,
			survey: state => state.userSurveys.survey,
			ontology: state => state.userSurveys.ontology,
		}),
		...mapGetters('login', {
			isAdmin: 'isAdmin',
			user: 'currentUser',
		}),

		otherBusinessUnits() {
			return this.ontology.roots // .filter(({ processId }) => processId !== this.businessUnit.processId)
		},
	},
	mounted() {
		this.popupItem = this.$refs.userOptions
	},
	methods: {

		...mapActions('login', {
			doSetPassword: 'setPassword',
		}),

		closeChangePassword() {
			this.changePasswordDialog = false
			this.changePasswordFailedWarning = false
			this.nonMatchingPasswordsWarning = false
		},
		/* eslint-disable max-statements */
		changePassword() {
			if (this.processing) {
				return
			}

			if (this.newPassword === undefined || this.newPassword === '') {
				this.changePasswordFailedWarning = true

				return
			}

			if (this.newPassword !== this.retypePassword) {
				this.nonMatchingPasswordsWarning = true

				return
			}

			this.changePasswordAlert = false
			this.changePasswordFailedWarning = false
			this.nonMatchingPasswordsWarning = false
			this.processing = true

			const props = {
				newPassword: this.newPassword,
				id: this.user.id,
			}

			this.doSetPassword(props).then((success) => {
				this.processing = false

				if (success) {
					this.changePasswordAlert = true
				} else {
					this.changePasswordFailedWarning = true
				}
			})
		},
		passwordsEqual(newPassword, retypePassword) {
			const errors = []

			if (newPassword !== retypePassword) {
				errors.push('Passwords are not equal')
			}

			return errors
		},
		passwordErrors(password) {
			const errors = []


			if (!password.match('^($)|(?=.*[A-Za-z])(?=.*\\d)[A-Za-z\\d^a-zA-Z0-9].{7,}$')) {
				errors.push('is not a valid password. Must contain at least 8 characters, 1 number and 1 character.')
			}

			if (password === '') {
				errors.push('passwords cannot be blank')
			}

			return errors
		},
		validPasswords(newPassword, retypePassword) {
			return this.passwordErrors(newPassword).length === 0 && this.passwordErrors(retypePassword).length === 0 && this.passwordsEqual(newPassword, retypePassword).length === 0
		},
		OpenChangePassword() {
			this.changePasswordDialog = true
			this.showUserOptions = false
		},
		surveyStatus(surveyTemplate) {
			if (surveyTemplate) {
				if (surveyTemplate.status === 'closed') {
					return 'closed'
				} else if (surveyTemplate.status === 'new') {
					return 'not yet started'
				} else if (surveyTemplate.hasBeenSubmitted) {
					return 'submitted'
				} else {
					return 'open'
				}
			} else {
				return ''
			}
		},
		doLogout(e) {
			if (e) { e.preventDefault() }

			this.$store.dispatch('login/logout')
		},
		toggleUserOptions() {
			this.showUserOptions = !this.showUserOptions
		},
		hideUserOptions() {
			this.showUserOptions = false
		},

		// changeBusinessUnit(event) {
		// 	event.preventDefault()

		// 	if (this.cantSave) {
		// 		this.$router.push({ path: event.target.getAttribute('href') })
		// 	} else if (window.confirm('Do you really want to leave? You have unsaved changes!')) {
		// 		this.$router.push({ path: event.target.getAttribute('href') })
		// 	}
		// },
		changeBusinessUnit(businessUnitId) {
			const path = `/surveys/${this.surveyTemplate.id}/${businessUnitId}`

			if (this.cantSave) {
				this.$router.push({ path }).catch(()=>{})
			} else if (window.confirm('Do you really want to leave? You have unsaved changes!')) {
				this.$router.push({ path }).catch(()=>{})
			}
		},
	},
}
</script>

<style lang="scss">
@import '../../../assets/scss/components/surveyHeader';
</style>
