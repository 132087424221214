export const sortAs = (orderedList) => {
	const index = {}

	orderedList.forEach((value, i) => {
		index[value] = i
	})

	return (list) => list.sort((a, b) => index[a] - index[b])
}

export const formatPercentage = (count, total) => {
	// eslint-disable-next-line no-magic-numbers
	return `${Math.round(count * 1000 / total) / 10}%`
}
