/* eslint-disable no-warning-comments */
// TODO: I wanted to share this file with the back-end, but couldn't find a way, so now it is copied.
// Fix that.

import { keyBy, flatMap, uniq, some } from 'lodash/fp'

export class ValidationError extends Error {
	constructor(
		props,
	) {
		super('validation error')
		Object.assign(
			this,
			props,
		)
	}
}

const validationGuard = (f) => (value, context) => {
	if (value !== null && value !== undefined) {
		return f(value, context)
	} else {
		return [value, false]
	}
}

const withPath = (context, key, f) => {
	context.path.push(key)

	const result = f()

	context.path.pop()

	return result
}

const validatorCreators = {
	length: ({ min, max, minMesg, maxMesg }) => (s) => { /* eslint-disable-line complexity */
		if (min && s.length < min) {
			return [s, true, [minMesg || 'is too short']]
		} else if (max && s.length > max) {
			return [s, true, [maxMesg || 'is too long']]
		} else {
			return [s, false]
		}
	},

	format: ({ re: reString, desc, mesg }) => {
		const re = new RegExp(reString)

		return (s) => {
			if (!s.match(re)) {
				/* eslint-disable max-len */
				return [s, true, [mesg || (desc ? desc === 'password' ? `is not a valid ${desc}. Must contain at least 8 characters, 1 number and 1 character.` : `is not a valid ${desc}` : 'is not valid')]]
			} else {
				return [s, false]
			}
		}
	},

	unique: ({ over, mesg, desc }) => {
		const overIndex = keyBy((a) => a, over)

		return (v, { path, isUnique }) => {
			const scope = path.filter((_, i) => overIndex[path.length - 1 - i] === undefined)

			if (isUnique(scope, v)) {
				return [v, false]
			} else {
				return [v, true, [mesg || (desc ? `is not a unique ${desc}` : 'is not unique')]]
			}
		}
	},

	uniquenessConstraint: ({ listKey, mesg, desc }) => {
		if (listKey) {
			return (v, { lists }) => {
				if (lists && lists[listKey] && lists[listKey].indexOf(v) >= 0) {
					return [v, true, [mesg || (desc ? `is not a unique ${desc}` : 'is not unique')]]
				} else {
					return [v, false]
				}
			}
		} else {
			return (v) => [v, false]
		}
	},
}

const errorClassifierCreators = {
	length: () => (v) => {
		return [v, false]
	},

	format: () => (v) => {
		return [v, false]
	},

	unique: () => (v) => {
		return [v, false]
	},

	uniquenessConstraint: ({ name, column, mesg, desc }) => {
		return (v, { error }) => { /* eslint-disable-line complexity */
			if (error.name === 'error' && error.code === '23505' && error.constraint === name && error.detail === `Key (${column})=(${v}) already exists.`) {
				return [v, true, [mesg || (desc ? `is not a unique ${desc}` : 'is not unique')]]
			} else {
				return [v, false]
			}
		}
	},
}

const createPrimitiveValidator = (validations, isErrorClassifier) => {
	const validators = Object.keys(validations).filter((type) => type !== 'trim' && type !== 'lowercase' && validations[type]).map((type) => {
		if (!validatorCreators[type]) {
			throw new Error(`unknown validation type ${type}`)
		}

		return (isErrorClassifier ? errorClassifierCreators : validatorCreators)[type](validations[type])
	})

	return validationGuard((originalValue, context) => {
		let value = validations.trim ? originalValue.replace(/^[ ]+|[ ]+$/g, '') : originalValue
		let anyError = false
		const errors = []

		value = validations.lowercase ? value.toLowerCase() : value

		validators.forEach((validator) => {
			const [subValue, subAnyError, subErrors] = validator(value, context)

			value = subValue
			anyError = anyError || subAnyError

			if (subAnyError) {
				errors.push(...subErrors)
			}
		})

		return [value, anyError, anyError ? errors : null]
	})
}

const createObjectValidator = (validations, isErrorClassifier) => {
	const validators = Object.keys(validations).filter((fieldName) => validations[fieldName]).map((fieldName) => {
		return {
			fieldName,
			validator: createAnyValidator(validations[fieldName], isErrorClassifier),
		}
	})

	return validationGuard((originalValue, context) => {
		let value = originalValue
		let anyError = false
		const errors = {}

		validators.forEach(({ fieldName, validator }) => {
			const [subValue, anySubError, subErrors] = withPath(context, fieldName, () => validator(originalValue[fieldName], context))

			if (subValue !== originalValue[fieldName]) {
				if (value === originalValue) {
					value = Object.assign({}, originalValue)
				}

				value[fieldName] = subValue
			}

			anyError = anyError || anySubError

			if (anySubError) {
				errors[fieldName] = subErrors
			}
		})

		return [value, anyError, anyError ? errors : null]
	})
}

const createArrayValidator = (validations, isErrorClassifier) => {
	const validator = createAnyValidator(validations, isErrorClassifier)

	return validationGuard((originalValue, context) => {
		let value = originalValue
		let anyError = false
		const errors = {}

		originalValue.forEach((v, i) => {
			const [subValue, anySubError, subErrors] = withPath(context, i, () => validator(v, context))

			if (subValue !== v) {
				if (value === originalValue) {
					value = originalValue.slice(0)
				}

				value[i] = subValue
			}

			anyError = anyError || anySubError

			if (anySubError) {
				errors[i] = subErrors
			}
		})

		return [value, anyError, anyError ? errors : null]
	})
}

const createAnyValidator = (validations, isErrorClassifier) => {
	if (validations.Object) {
		return createObjectValidator(validations.Object, isErrorClassifier)
	} else if (validations.Array) {
		return createArrayValidator(validations.Array, isErrorClassifier)
	} else {
		return createPrimitiveValidator(validations, isErrorClassifier)
	}
}

const collectorCreators = {
	format: () => () => {},
	length: () => () => {},
	unique: ({ over }) => {
		const overIndex = keyBy((a) => a, over)

		return (v, { path, collect }) => {
			const scope = path.filter((_, i) => overIndex[path.length - 1 - i] === undefined)

			collect(scope, v)
		}
	},
	uniquenessConstraint: () => () => {},
}

const createPrimitiveCollector = (validations) => {
	const collectors = Object.keys(validations).filter((type) => type !== 'trim' && type !== 'lowercase' && validations[type]).map((type) => {
		if (!collectorCreators[type]) {
			throw new Error(`unknown validation type ${type}`)
		}

		return collectorCreators[type](validations[type])
	})

	return validationGuard((originalValue, context) => {
		let value = validations.trim ? originalValue.replace(/^[ ]+|[ ]+$/g, '') : originalValue

		value = validations.lowercase ? value.toLowerCase() : value

		collectors.forEach((collector) => collector(value, context))
	})
}

const createObjectCollector = (validations) => {
	const collectors = Object.keys(validations).filter((fieldName) => validations[fieldName]).map((fieldName) => {
		return {
			fieldName,
			collector: createAnyCollector(validations[fieldName]),
		}
	})

	return validationGuard((value, context) => {
		collectors.forEach(({ fieldName, collector }) => { withPath(context, fieldName, () => collector(value[fieldName], context)) })
	})
}

const createArrayCollector = (validations) => {
	const collector = createAnyCollector(validations)

	return validationGuard((value, context) => {
		value.forEach((v, i) => withPath(context, i, () => collector(v, context)))
	})
}

export const createAnyCollector = (validations) => {
	if (validations.Object) {
		return createObjectCollector(validations.Object)
	} else if (validations.Array) {
		return createArrayCollector(validations.Array)
	} else {
		return createPrimitiveCollector(validations)
	}
}

export const createValidator = (validations) => {
	const collector = createAnyCollector(validations)
	const validator = createAnyValidator(validations, false)

	return (value, throwError = true, lists) => {
		const uniquenessScopes = {}

		const collect = (scope, v) => {
			const key = scope.join('/')
			const uniquenessScope = uniquenessScopes[key] = uniquenessScopes[key] || {}

			if (uniquenessScope[v]) {
				uniquenessScope[v] += 1
			} else {
				uniquenessScope[v] = 1
			}
		}

		const isUnique = (scope, v) => {
			const key = scope.join('/')
			const uniquenessScope = uniquenessScopes[key]

			return uniquenessScope[v] <= 1
		}

		collector(value, { path: [], collect })

		const [mappedValue, anyError, errors] = validator(value, { path: [], isUnique, lists })

		if (throwError) {
			if (anyError) {
				throw new ValidationError({ value: mappedValue, errors })
			} else {
				return mappedValue
			}
		} else {
			return anyError ? errors : null
		}
	}
}

export const createErrorClassifier = (validations) => {
	const classifier = createAnyValidator(validations, true)

	return (value, error, options) => {
		const [, anyError, errors] = classifier(value, { path: [], options, error })

		return anyError ? errors : null
	}
}

export const withValidator = (fieldSpecs, fieldName, f) => {
	const validator = createValidator(fieldSpecs)
	const classifier = createErrorClassifier(fieldSpecs)

	return async (root, data) => {
		const value = validator(data[fieldName])

		try {
			return await f(root, Object.assign({}, data, { [fieldName]: value }))
		} catch (e) {
			const errors = classifier(value, e)

			if (errors) {
				throw new ValidationError({ value, errors })
			} else {
				throw e
			}
		}
	}
}

export const serializeValidator = (fieldSpecs) => {
	return fieldSpecs
}

export const deserializeValidator = (fieldSpecs) => {
	return fieldSpecs
}

export const lookupError = (errors, ...fields) => {
	return lookupPartialError(errors, ...fields) || []
}

export const lookupMultiError = (errorsList, ...path) => {
	const errors = flatMap((errs) => lookupError(errs, ...path) || [])(errorsList)

	return errors.length ? uniq(errors) : null
}

export const lookupPartialError = (errors, ...fields) => {
	const result = fields.reduce((object, field) => {
		return object ? object[field] : null
	}, errors)

	return result
}

export const lookupPartialMultiError = (errorsList, ...fields) => {
	return errorsList.map((errors) => lookupPartialError(errors, ...fields))
}

export const hasError = Boolean

export const hasMultiError = (errorsList) =>
	some(hasError)(errorsList)
