<template>
	<v-dialog
		v-model="importDialog"
		:disabled="isReadOnly"
		max-width="800px"
		style="margin-left: 20px; "
		persistent
		@keydown.esc="cancelImport"
	>
		<v-btn
			slot="activator"
			:disabled="isReadOnly"
			:class="{ disabled: isReadOnly }"
			color="primary"
			dark
			class="button"
			style="position: relative; top: -1px;"
		>
			Import systems
		</v-btn>
		<v-card>
			<v-card-title>
				<span class="headline">Import systems</span>
			</v-card-title>
			<v-card-text>
				<v-container grid-list-md>
					<v-layout wrap="">
						<v-flex xs12>
							<div v-if="!loadedData" style="margin-bottom: 0.5em; ">
								<v-icon class="text-icon" color="green">
									mdi-information
								</v-icon>
								<b>
									Warning: Importing a CSV file will erase all records that currently exist, across all business capability level 1's.
								</b>
								This function is intended only to create a completely new survey.
								<b>Note that to use this functionality you should first obtain the template from a Prisme administrator</b>
							</div>
							<input
								v-if="!loadingFile && !loadedData && !loadedData"
								ref="fileInput"
								type="file"
								accept=".csv"
								@input="readCSV()"
							>
							<div v-if="error" style="margin-top: 0.5em; ">
								<v-icon class="text-icon" color="red">
									mdi-alert-circle
								</v-icon> <b>
									{{ error }}
								</b>
							</div>
							<p v-if="loadingFile">
								<v-progress-circular
									:size="20"
									:width="4"
									color="primary"
									indeterminate
									style="position: relative; top: -1px; margin-right: 5px; "
								/>
								Loading {{ loadingFile.name }} ({{ loadedBytes }} of {{ loadingFile.size }} bytes)
							</p>
							<div v-if="loadedData">
								<p>
									<v-icon class="text-icon" color="green">
										mdi-information
									</v-icon>
									Your file contains {{ pluralize(loadedData.systems.length, 'system') }}.
									<span v-if="loadedData.systems.length">Click import to import them.</span>
								</p>
								<p v-if="!loadedData.systems.length">
									<v-icon class="text-icon" color="blue">
										mdi-alert
									</v-icon>
									<b>
										Cannot import anything.
									</b>
								</p>
								<!-- <p v-if="loadedData.businessUnitCount.length">
									<v-icon class="text-icon" color="blue">
										mdi-alert
									</v-icon>
									<b>
										In addition your file contains
										<span
											v-for="({ key, count }, i) in loadedData.businessUnitCount"
											:key="key"
										><span v-if="i > 0">, </span>{{ pluralize(count, 'system') }} for {{ key }}</span>.
									</b>
									To import these please open the appropriate business capability level 1.
								</p> -->
								<p v-if="loadedData.invalidOrMissingBusinessUnitCount + loadedData.invalidBusinessProcessesCount">
									<v-icon class="text-icon" color="red">
										mdi-alert-circle
									</v-icon>
									<b>
										Your file contains {{ pluralize(loadedData.invalidBusinessProcessesCount, 'system') }}
										with invalid business capability levels.
									</b>
									<span v-if="loadedData.invalidBusinessProcessesCount > loadedData.invalidOrMissingBusinessUnitCount">
										Of these, {{ pluralize(loadedData.invalidBusinessProcessesCount - loadedData.invalidOrMissingBusinessUnitCount, 'system has', 'systems have') }}
										a valid business capability level 1 and will be imported. You can assign them a business capability after the import.
										The systems without a valid business capability level 1 cannot be imported.
									</span>
									<span v-else-if="loadedData.invalidOrMissingBusinessUnitCount">
										None of these systems can be imported because they do not have a valid business capability level 1.
									</span>
									Alternatively, you can cancel the import, fix the business capabilities in the CSV file, and retry the import.
								</p>
								<p v-if="loadedData.missingMandatoryCount.length">
									<v-icon class="text-icon" color="blue">
										mdi-alert
									</v-icon>
									<b>
										Your file contains a number of systems with missing values for
										{{ loadedData.missingMandatoryCount.length === 1 ? 'this property' : 'these properties' }}:
										{{ loadedData.missingMandatoryCount.map(({ key }) => key).join(', ') }}.
									</b>
									If you proceed with the import, these invalid values will be cleared and you can enter a value
									from the interface. You can also cancel, enter a value in the file and retry the import.
								</p>
								<p v-if="loadedData.clearedStrictsCount.length">
									<v-icon class="text-icon" color="blue">
										mdi-alert
									</v-icon>
									<b>
										Your file contains a number of systems with invalid values for
										{{ loadedData.clearedStrictsCount.length === 1 ? 'this property' : 'these properties' }}:
										<span
											v-for="({ key, fieldTemplate }, i) in loadedData.clearedStrictsCount"
											:key="key"
										><span v-if="i > 0">, </span><span :title="formatValidValues(fieldTemplate)">{{ key }}</span></span>.
									</b>
									If you proceed with the import, these invalid values will be cleared and you can select a correct value
									from the interface. You can also cancel, fix the errors in the file and retry the import.
								</p>
							</div>
						</v-flex>
					</v-layout>
				</v-container>
			</v-card-text>
			<v-card-actions>
				<v-spacer />
				<v-btn color="blue darken-1" flat @click.native="cancelImport">
					Cancel
				</v-btn>
				<v-btn :disabled="!Boolean(loadedData && loadedData.systems.length)" color="blue darken-1" flat @click.native="importSystems">
					Import systems
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import * as CSV from 'csv-string'
import { mapGetters } from 'vuex'

export default {
	props: {
		ontology: {
			type: Object,
			required: true,
		},
		businessUnit: {
			type: Object,
			required: true,
		},
		surveyTemplate: {
			type: Object,
			required: true,
		},
	},
	data() {
		const taxonomyDepth = this.surveyTemplate.taxonomyDepth
		const businessProcessHeaders = [];

		for (let level = 1; level <= taxonomyDepth; level++) {
			businessProcessHeaders.push(`Business capability - Level ${level}`);
		}
		return {
			expectedHeaders: businessProcessHeaders
				.concat(this.surveyTemplate.fieldTemplates.map(f => f.fieldName)),
			importDialog: false,
			loadingFile: null,
			loadedData: null,
			loadedBytes: 0,
			processing: false,
			error: null,
		}
	},
	computed: {
		...mapGetters('userSurveys', {
			isReadOnly: 'isReadOnly',
		}),
		checkEmpty() {
			return false
		},
		fieldValueNormalizers() {
			const result = {}

			this.surveyTemplate.fieldTemplates.forEach(fieldTemplate => {
				const mapping = {}

				fieldTemplate.allowedValues.forEach(value => {
					mapping[value.toLowerCase()] = value
				})

				result[fieldTemplate.fieldName] = mapping
			})

			return result
		},
	},
	methods: {
		cancelImport() {
			this.loadedData = null
			this.importDialog = false
			this.error = null
		},
		importSystems() {
			this.$emit('import', this.loadedData.systems)
			this.loadedData = null
			this.importDialog = false
			this.error = null
		},
		// eslint-disable-next-line max-statements
		readCSV() {
			const input = this.$refs.fileInput
			const file = input.files[0]
			const reader = new FileReader()

			const handler = e => {
				this.loadedBytes = e.loaded

				// eslint-disable-next-line default-case
				switch (e.type) {
				case 'load':
					this.processCSV(reader.result)
					break
				case 'loadend':
					this.loadingFile = null
					break
				case 'error':
				case 'abort':
					this.error = 'Error loading file'
					break
				}
			}

			reader.addEventListener('load', handler)
			reader.addEventListener('loadend', handler)
			reader.addEventListener('progress', handler)
			reader.addEventListener('error', handler)
			reader.addEventListener('abort', handler)

			this.loadingFile = file
			this.loadedBytes = 0
			this.processing = false
			this.error = null
			reader.readAsText(file)
		},
		processCSV(text) {
			try {
				const rows = CSV.parse(text)

				if (
					rows[0]
					&& rows[0].length === this.expectedHeaders.length
					&& rows[0].every((header, i) => header === this.expectedHeaders[i])
				) {
					this.processSystems(rows.slice(1))
				} else {
					this.error = 'File has the wrong format'
				}
			} catch (e) {
				this.error = 'File is not valid CSV'
				this.processing = false
			}
		},
		processSystems(rows) {
			const systems = []
			const missingMandatoryCount = {}
			const clearedStrictsCount = {}
			//const businessUnitCount = {}
			let invalidOrMissingBusinessUnitCount = 0
			let invalidBusinessProcessesCount = 0

			// eslint-disable-next-line max-statements, complexity
			rows.forEach(row => {
				// Filter out the empty levels. These are possible when the taxonomy is not of uniform depth.
				const levels = row.slice(0, this.surveyTemplate.taxonomyDepth).filter(level => level)
				const fieldValues = row.slice(this.surveyTemplate.taxonomyDepth);

				const properties = fieldValues.reduce((h, fieldValue, i) => {
					h[this.surveyTemplate.fieldTemplates[i].fieldName] = fieldValue

					return h
				}, {})
				const keyValue = properties[this.surveyTemplate.keyField.fieldName]

				if (keyValue && keyValue.match(/\S/)) {
					const classification = this.processOntologyLevels(levels)
					const { missingMandatories, clearedStricts } = this.validateSystem(
						properties,
					)

					if (!classification.item) {
						invalidBusinessProcessesCount += 1
					}

					if (classification.bestItem) {
						if (!classification.item.processId){
							console.log(classification.item)
						}
						const surveyItem = {
							ontologyItemProcessId: classification.item
								? classification.item.processId ?
									classification.item.processId
									: classification.item.id
								: null,
							businessUnitId: (
								classification.bestItem.leaf || classification.bestItem
							).mappedPath[1],
							surveyTemplateId: this.surveyTemplate.id,
							surveyItemValues: this.surveyTemplate.fieldTemplates.map(
								({ fieldName }) => ({
									fieldName,
									value: properties[fieldName] || '',
								}),
							),
							values: properties,
						}

						systems.push(surveyItem)

						// const businessUnitName = this.ontology.mappedIndex[
						// 	surveyItem.businessUnitId
						// ].name

						// if (surveyItem.businessUnitId === this.businessUnit.id) {
						// 	systems.push(surveyItem)
						// } else {
						// 	businessUnitCount[businessUnitName]
						// 		= (businessUnitCount[businessUnitName] || 0) + 1
						// }
					} else {
						invalidOrMissingBusinessUnitCount += 1
					}

					missingMandatories.forEach(fieldName => {
						missingMandatoryCount[fieldName]
							= (missingMandatoryCount[fieldName] || 0) + 1
					})
					clearedStricts.forEach(fieldName => {
						clearedStrictsCount[fieldName]
							= (clearedStrictsCount[fieldName] || 0) + 1
					})
				}
			})

			this.loadedData = {
				systems,
				missingMandatoryCount: this.processCount(missingMandatoryCount),
				clearedStrictsCount: this.processCount(clearedStrictsCount, true),
				//businessUnitCount: this.processCount(businessUnitCount),
				invalidOrMissingBusinessUnitCount,
				invalidBusinessProcessesCount,
			}
		},
		processOntologyLevels(levels) {
			// BusinessUnit is the virtual root here (a category above all level 1 business capabilities)
			let item = this.ontology.businessUnit
			let bestItem = null

			levels.forEach(level => {
				if (item) {
					item = item.realChildren.find(i => i.name === level)

					if (item) {
						bestItem = item
					}
				}
			})

			return {
				item,
				bestItem,
			}
		},
		isBlank(value) {
			return value === null || value === undefined || value.match(/^\s*$/)
		},
		validateSystem(properties) {
			const missingMandatories = []
			const clearedStricts = []

			// eslint-disable-next-line complexity
			this.surveyTemplate.fieldTemplates.forEach(
				({ isMandatory, fieldName, isStrict, isFreeText }) => {
					if (this.isBlank(properties[fieldName])) {
						if (isMandatory) {
							missingMandatories.push(fieldName)
						}
					} else if (!isFreeText && isStrict) {
						const normalizer = this.fieldValueNormalizers[fieldName]
						const value = properties[fieldName].toLowerCase()

						if (normalizer.hasOwnProperty(value)) {
							properties[fieldName] = normalizer[value]
						} else {
							clearedStricts.push(fieldName)
							properties[fieldName] = ''
						}
					}
				},
			)

			return {
				missingMandatories,
				clearedStricts,
			}
		},
		processCount(obj, addFieldTemplate = false) {
			return Object.keys(obj).map(key => ({
				key,
				count: obj[key],
				fieldTemplate: addFieldTemplate
					? this.surveyTemplate.fieldTemplates.find(
						fieldTemplate => fieldTemplate.fieldName === key,
					  )
					: null,
			}))
		},
		formatValidValues(fieldTemplate) {
			return fieldTemplate
				? `valid values are ${fieldTemplate.allowedValues.join(', ')}`
				: ''
		},
		pluralize(count, singular, plural = `${singular}s`) {
			return `${count} ${count === 1 ? singular : plural}`
		},
	},
}
</script>

<style>
.text-icon {
	position: relative;
	top: 4px;
}
</style>
