
<template>
	<v-container fluid class="survey-dashboard">
		<v-layout row wrap="">
			<!-- Main page title "All Surveys" + [Create Survey] -->
			<div class="survey-dashboard-title-box">
				<h1 class="title-layout survey-dashboard-title">All Surveys</h1>
				<!-- Route to to AdminSurveys.vue -->
				<router-link
					to="/admin/surveys/create"
					class="button-link survey-dashboard-title-button"
				>
					Create Survey
				</router-link>
			</div>

			<!-- Survey selection buttons [{name} - {new |started|closed}] -->
			<div class="survey-dashboard-history-list">
				<v-btn
					v-for="survey in sortedSurveys"
					:key="survey.id"
					:class="{ active: survey === currentSurvey, draft: survey.isDraft }"
					class="button survey-dashboard-history-button"
					@click="select(survey) && setBusinessUnit(survey.rootOntologyItems[0])"
				>
					{{ label(survey) }}
				</v-btn>
			</div>
		</v-layout>

		<!-- Selected survey overview -->
		<v-layout v-if="currentSurvey" row wrap="" style="margin-top: 30px">
			<!-- Actions -->

			<!-- Header: "Selected Survey: {name} - {open|closed}" -->
			<v-flex xs12 class="survey-dashboard-title-box">
				<h1 class="title-layout survey-dashboard-title">
					Selected Survey: {{ currentSurvey.name }} &mdash;
					{{ currentSurvey.status }}
				</h1>
				<!-- [Start survey] -->
				<v-btn
					v-if="currentSurvey.status === 'new'"
					class="button"
					style="margin-left: 40px"
					@click="openSurvey(currentSurvey)"
				>
					Start survey
				</v-btn>
				<!-- [Close survey] -->
				<v-btn
					v-if="currentSurvey.status === 'started'"
					class="button"
					style="margin-left: 40px"
					@click="closeSurvey(currentSurvey)"
				>
					Close survey
				</v-btn>
				<!-- [Download survey template] -->
				<v-btn
					v-if="currentSurvey.status === 'started'"
					:href="templateHref(currentSurvey)"
					:download="`prisme-${currentSurvey.name}-import.csv`"
					class="button"
					style="
						line-height: 40px;
						text-decoration: none;
						padding: 0 16px;
						margin-left: 1em;
					"
				>
					Download CSV template
				</v-btn>
				<!-- [Reopen survey] -->
				<v-btn
					v-if="currentSurvey.status === 'closed'"
					class="button"
					style="margin-left: 40px"
					@click="openSurvey(currentSurvey)"
				>
					Reopen survey
				</v-btn>
			</v-flex>

			<div
				v-if="currentSurvey.status != 'new'"
				class="survey-dashboard-main-overview"
			>
				<!-- Business capability level 1 selection buttons: [{name}]  ... -->
				<v-layout row wrap="">
					<v-flex xs12 class="survey-dashboard-business-box">
						<v-select
							:items="currentSurvey.rootOntologyItems"
							class="survey-dashboard-business-box-select"
							item-text="name"
							v-model="currentBusinessUnit"
							@change="setBusinessUnit"
							return-object
						></v-select>
					</v-flex>
				</v-layout>

				<!-- Companies in 3 columns: Not started | Started | Submitted -->
				<v-layout
					v-if="currentSurvey.status !== 'new' && currentSurvey.surveys"
					row
					wrap=""
					class="survey-dashboard-overview"
				>
					<!-- Column: Not Started -->
					<v-flex xs4 class="survey-dashboard-column">
						<h2 class="survey-dashboard-column-title">Not started</h2>
						<div v-bar class="survey-dashboard-list-wrapper">
							<ul class="survey-dashboard-list">
								<li
									v-for="company in notStarted(
										currentSurvey.surveys,
										allCompanies,
										currentBusinessUnit.processId,
									)"
									:key="company"
									class="survey-dashboard-list-item"
								>
									{{ company }}
								</li>
							</ul>
						</div>
					</v-flex>

					<!-- Column: Started -->
					<v-flex xs4 class="survey-dashboard-column">
						<v-layout row wrap>
							<h2 slot="activator" class="survey-dashboard-column-title">
								Started
							</h2>
							<v-tooltip bottom>
								<v-icon
									slot="activator"
									class="survey-dashboard-column-icon"
									small
								>
									mdi-help-circle-outline
								</v-icon>
								<span>
									Click any started company to download current saved data
								</span>
							</v-tooltip>
						</v-layout>
						<div v-bar class="survey-dashboard-list-wrapper">
							<ul class="survey-dashboard-list">
								<li
									v-for="survey in started(
										currentSurvey.surveys,
										currentBusinessUnit.processId,
									)"
									:key="survey.companyId"
									class="survey-dashboard-list-item-clickable"
								>
									<a :href="downloadUrl(survey.company)">{{
										survey.company.name
									}}</a>
								</li>
							</ul>
						</div>
					</v-flex>

					<!-- Column: Submitted -->
					<v-flex xs4 class="survey-dashboard-column">
						<v-layout row wrap>
							<h2 class="survey-dashboard-column-title">Submitted</h2>
							<v-tooltip bottom>
								<v-icon
									slot="activator"
									class="survey-dashboard-column-icon"
									small
								>
									mdi-help-circle-outline
								</v-icon>
								<span>
									Click any company that has submitted to download submitted
									data
								</span>
							</v-tooltip>
						</v-layout>
						<div v-bar class="survey-dashboard-list-wrapper">
							<ul class="survey-dashboard-list">
								<li
									v-for="survey in submitted(
										currentSurvey.surveys,
										currentBusinessUnit.processId,
									)"
									:key="survey.companyId"
									class="survey-dashboard-list-item-clickable"
								>
									<a :href="downloadUrl(survey.company)">{{
										survey.company.name
									}}</a>
								</li>
							</ul>
						</div>
					</v-flex>
				</v-layout>
			</div>
		</v-layout>
	</v-container>
</template>

<script>
/* eslint-disable global-require */

import * as CSV from 'csv-string'
import { mapActions, mapState } from 'vuex'
import { buildUrl } from '../../api/api_helper.js'
import ontologyStore from '../../store/modules/admin/ontology.js'
import surveysStore from '../../store/modules/admin/surveys.js'

export default {
  name: 'AdminDashboard',
  components: {},
  data() {
    return {
      currentBusinessUnit: {
        name: null,
        processId: null,
      },
    }
  },
  computed: {
    ...surveysStore.mapState({
      surveys: (state) => state.templates,
    }),
    ...surveysStore.mapGetters({
      currentSurvey: 'getCurrentSurvey',
    }),
    ...ontologyStore.mapState({
      ontologyItems: (state) => state.all,
    }),
    ...ontologyStore.mapGetters({
      rootOntologyItems: 'rootOntologyItems',
    }),
    ...mapState({
      allCompanies: (state) => state.companies.all,
      surveyDomains: (state) => state.surveyDomain.all,
    }),
    isDynamic(strict) {
      return !strict
    },
	sortedSurveys() {
		return this.surveys.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
	},
  },
  created() {
    this.$store.dispatch('surveys/getSurveysAndCompanies')
    this.$store.dispatch('companies/getAllCompanies')
    this.$store.dispatch('surveyDomain/loadSurveyDomains').then(() => {
      this.$store.dispatch('ontology/loadOntology', {
        surveyDomainId: this.surveyDomains[0].id,
      })
    })
  },

  methods: {
    ...mapActions('surveys', {
      select: 'selectSurvey',
      openSurvey: 'openSurvey',
      closeSurvey: 'closeSurvey',
    }),
    setBusinessUnit(businessUnitOntology) {
      this.currentBusinessUnit = this.currentSurvey.rootOntologyItems.find(
        (item) => item.processId === businessUnitOntology.processId
      )
    },
    notStarted(surveys, allCompanies, businessUnitId) {
      const companyNames = allCompanies.map((s) => s.name)
      const startedOrSubmittedCompanyNames = surveys
        .filter((s) => s.businessUnitId === businessUnitId)
        .map((s) => s.company.name)

			return companyNames.filter((name) => !startedOrSubmittedCompanyNames.includes(name)).sort()
		},
		started(surveys, businessUnitId) {
			return surveys && surveys.filter((s) => !s.hasBeenSubmitted && s.businessUnitId === businessUnitId).sort()
		},
		submitted(surveys, businessUnitId) {
			return surveys && surveys.filter((s) => s.hasBeenSubmitted && s.businessUnitId === businessUnitId).sort()
		},
		downloadUrl(company) {
			return buildUrl('/export', { surveyTemplateIds: [this.currentSurvey.id], businessUnitId: this.currentBusinessUnit.processId, companyIds: [company.id] })
		},
		templateHref(survey) {
			const taxonomyDepth = survey.taxonomyDepth
			const baseHeaders = ['Business capability - Level 1'];
			const businessProcessHeaders = [...baseHeaders]; // Start with base headers

			for (let level = 1; level < taxonomyDepth; level++) {
				businessProcessHeaders.push(`Business capability - Level ${level + 1}`);
			}
			const headers = businessProcessHeaders.concat(survey.fieldTemplates.map(({ fieldName }) => fieldName))

			return `data:text/plain;charset=utf-8,${ encodeURIComponent(CSV.stringify(headers))}`
		},
		label(survey){
			return survey.isDraft? survey.name + "-" + survey.status + " (draft)" : survey.name + "-" + survey.status
		}
	},
}
</script>

<style lang="scss" scoped>
	@import '../../assets/scss/pages/admin/surveyDashboard';
</style>
</template>
