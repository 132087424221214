// @ts-check

import gql from 'graphql-tag'
import graphqlClient from './db'
import { allFieldValidations } from './fragments'

export const api = {
  /**
   * @param {Api.GetTaxonomyVersion.Options} options
   * @returns {Promise<Api.GetTaxonomyVersion.Result>}
   */
  async getTaxonomyVersion({ taxonomyVersionId }) {
    const { data } = await graphqlClient.query({
      query: gql`
        query OntologyItems($taxonomyVersionId: ID!) {
          taxonomyVersion(taxonomyVersionId: $taxonomyVersionId) {
            id
            surveyDomainId
            parentTaxonomyVersionId
            name
            active
            draft
            current
            depth
            ontologyItems {
              processId
              name
              parentId
              active
              description
              surveyItemCounts {
                count
                processId
                surveyTemplateId
              }
              terms
            }
          }
        }
      `,
      variables: {
        taxonomyVersionId,
      },
    })

    return data.taxonomyVersion
  },

  /**
   * @param {Api.GetTaxonomyForExport.Options} opts
   * @returns {Promise<Api.GetTaxonomyForExport.Result>}
   */
  async getTaxonomyForExport({ taxonomyVersionId }) {
    const { data } = await graphqlClient.query({
      query: gql`
        query OntologyItems($taxonomyVersionId: ID!) {
          surveyTemplates(taxonomyVersionId: $taxonomyVersionId) {
            id
            name
            createdAt
          }
          taxonomyVersion(taxonomyVersionId: $taxonomyVersionId) {
            id
            surveyDomainId
            parentTaxonomyVersionId
            name
            active
            draft
            current
            depth
            ontologyItems {
              processId
              name
              parentId
              active
              description
              surveyItemCounts {
                count
                processId
                surveyTemplateId
              }
              terms
            }
          }
        }
      `,
      variables: {
        taxonomyVersionId,
      },
    })

    return {
      surveyTemplates: data.surveyTemplates,
      taxonomyVersion: data.taxonomyVersion,
    }
  },

  /**
   * @param {Api.CreateTaxonomyVersion.Options} options
   * @returns {Promise<Api.CreateTaxonomyVersion.Result>}
   */
  async createTaxonomyVersion({ name, parentTaxonomyVersionId, mappings }) {
    const { data } = await graphqlClient.mutate({
      mutation: gql`
        mutation CreateTaxonomyVersion(
          $name: String!
          $parentTaxonomyVersionId: ID!
          $mappings: [TaxonomyVersionMappingCreateProps!]!
        ) {
          createTaxonomyVersion(
            name: $name
            parentTaxonomyVersionId: $parentTaxonomyVersionId
            mappings: $mappings
          ) {
            id
            surveyDomainId
            parentTaxonomyVersionId
            name
            active
            draft
            current
            depth
            ontologyItems {
              processId
              name
              parentId
              active
              description
              surveyItemCounts {
                count
                processId
                surveyTemplateId
              }
              terms
            }
          }
        }
      `,
      variables: {
        name,
        parentTaxonomyVersionId,
        mappings,
      },
    })

    return data.createTaxonomyVersion
  },

  /**
   * @param {Api.GetVersionMappings.Options} options
   * @returns {Promise<Api.GetVersionMappings.Result>}
   */
  async getTaxonomyVersionMapping({
    fromTaxonomyVersionId,
    toTaxonomyVersionId,
  }) {
    const { data } = await graphqlClient.mutate({
      mutation: gql`
        query GetTaxonomyVersionMapping(
          $fromTaxonomyVersionId: ID!
          $toTaxonomyVersionId: ID!
        ) {
          taxonomyVersionMappings(toTaxonomyVersionId: $toTaxonomyVersionId) {
            processIdOld
            processIdNew
          }
          ontologyItems(taxonomyVersionId: $fromTaxonomyVersionId) {
            processId
            name
            parentId
            active
            description
            surveyItemCounts {
              count
              processId
              surveyTemplateId
            }
            terms
          }
        }
      `,
      variables: {
        fromTaxonomyVersionId,
        toTaxonomyVersionId,
      },
    })

    return {
      taxonomyVersionMappings: data.taxonomyVersionMappings,
      oldOntologyItems: data.ontologyItems,
    }
  },

  /**
   * @param {Api.GetAdminOntology.Options} opts
   * @returns {Promise<Api.GetAdminOntology.Result}
   */
  async getAdminOntology({ surveyDomainId, taxonomyVersionId }) {
    const result = await graphqlClient.query({
      query: gql`
				query OntologyItems($surveyDomainId: ID, $taxonomyVersionId: ID) {
					taxonomyVersions(surveyDomainId: $surveyDomainId) {
						id
						surveyDomainId
						parentTaxonomyVersionId
						name
						active
						draft
						current
						depth
						createdAt
						# updatedAt
					}
					ontologyItems(
						surveyDomainId: $surveyDomainId,
						taxonomyVersionId: $taxonomyVersionId
					) {
						processId
						name
						parentId
						active
						description
						surveyItemCounts {
							count
							processId
							surveyTemplateId
						}
						terms
					}
					ontologyItemValidations {
						Object {
							name { ...allFieldValidations }
						}
					}
					${allFieldValidations}
				}
			`,
      variables: {
        surveyDomainId,
        taxonomyVersionId,
      },
    })

    return result.data
  },

  /**
   * @param {Api.CanDeleteTaxonomyVersion.Options} opts
   * @returns {Promise<Api.CanDeleteTaxonomyVersion.Result>}
   */
  async canDeleteTaxonomyVersion({ taxonomyVersionId }) {
    const { data } = await graphqlClient.query({
      query: gql`
        query CanDeleteTaxonomyVersion($taxonomyVersionId: ID!) {
          canDeleteTaxonomyVersion(taxonomyVersionId: $taxonomyVersionId)
        }
      `,
      variables: {
        taxonomyVersionId,
      },
    })

    return data.canDeleteTaxonomyVersion
  },

  /**
   * @param {Api.DeleteTaxonomyVersion.Options} opts
   * @returns {Promise<Api.DeleteTaxonomyVersion.Result>}
   */
  async deleteTaxonomyVersion({ taxonomyVersionId }) {
    const { data } = await graphqlClient.mutate({
      mutation: gql`
        mutation DeleteTaxonomyVersion($taxonomyVersionId: ID!) {
          deleteTaxonomyVersion(taxonomyVersionId: $taxonomyVersionId)
        }
      `,
      variables: {
        taxonomyVersionId,
      },
    })

    return data.deleteTaxonomyVersion
  },

  /**
   * @param {Api.GetSurveyTemplates.Options} opts
   * @returns {Promise<Api.GetAllSurveys.Result>} */
  async getAllSurveys() {
    const { data } = await graphqlClient.query({
      query: gql`
				query SurveyTemplates {
					surveyTemplates {
						id
						name
						isDraft
            createdAt
						fieldTemplates {
							fieldName
							isStrict
							isFreeText
							allowedValues
							currentValues
							isMandatory
							prefilled
						}
					}
					surveyTemplateValidations {
						Object {
							name { ...allFieldValidations }
							fieldTemplates {
								Array {
									Object {
										fieldName { ...allFieldValidations }
										allowedValues {
											Array { ...allFieldValidations }
										}
									}
								}
							}
						}
					}
					${allFieldValidations}
				}
			`,
    })

    return {
      surveyTemplates: data.surveyTemplates,
      surveyTemplateValidations: data.surveyTemplateValidations,
    }
  },

  /**
   * @param {Api.GetSurveysAndCompanies.Options} opts
   * @returns {Promise<Api.GetSurveysAndCompanies.Result>}
   */

  async getSurveysAndCompanies() {
    const { data } = await graphqlClient.query({
      query: gql`
        query SurveyTemplates {
          surveyTemplates {
            id
            name
            status
            parentId
            isDraft
            createdAt
            taxonomyDepth
            rootOntologyItems {
              name
              processId
            }
            fieldTemplates {
              fieldName
            }
            surveys {
              hasBeenSubmitted
              businessUnitId
              company {
                name
                id
              }
            }
          }
        }
      `,
    })

    return {
      surveyTemplates: data.surveyTemplates,
    }
  },

  /**
   * @param {Api.GetSubmissionStatuses.Options} opts
   * @returns {Promise<Api.GetSubmissionStatuses.Result>}
   */
  async getSubmissionStatuses() {
    const { data } = await graphqlClient.query({
      query: gql`
        query SubmissionStatuses {
          submissionStatuses {
            surveyTemplateName
            companyName
            businessUnitName
            submitted
            numberOfSystems
            surveyTemplateCreatedAt
          }
        }
      `,
    })

    return data.submissionStatuses
  },

  /**
   * @param {Api.AcceptTaxonomyVersionDraft.Options} opts
   * @returns {Promise<Api.AcceptTaxonomyVersionDraft.Result>}
   */
  async acceptTaxonomyVersionDraft({ taxonomyVersionId }) {
    const { data } = await graphqlClient.query({
      query: gql`
        mutation acceptTaxonomyVersionDraft(
          $taxonomyVersionId: ID!
        ) {
          acceptTaxonomyVersionDraft(
            taxonomyVersionId: $taxonomyVersionId
          ) {
            id
          }
        }
      `,
      variables: {
        taxonomyVersionId,
      },
    })

    return data.acceptTaxonomyVersionDraft
  },

  /**
   * @param {Api.getPreSignedUrl.Options} opts
   * @returns {Promise<Api.getPreSignedUrl.Result>}
   */
  async getPreSignedUrl({ asset }) {
    const { data } = await graphqlClient.query({
      query: gql`
        query getPreSignedURL($asset: String!) {
          getPreSignedURL(asset: $asset)
        }
      `,
      variables: {
        asset,
      },
    })
    return data.getPreSignedURL
  }
}
