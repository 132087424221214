<template>
  <div
    v-if="readOnly"
    :class="{ 'with-side': withSide }"
    class="survey-input read-only-survey-input"
    style="overflow: hidden"
  >
    <div>{{ value }}</div>
  </div>
  <div
    v-else
    :class="{ 'free-text-survey-input': field.isFreeText }"
    class="survey-input"
  >
    <div
      v-if="field.isFreeText"
      :class="{ 'text-area-focussed': textareaFocussed }"
      class="free-text-survey-input-positioner"
    >
      <div>{{ value }}</div>
      <textarea
        ref="textarea"
        :value="value"
        style="resize: none; overflow-wrap: break-word"
        class="survey-input-field survey-input-field-textarea"
        @input="change($event.target.value)"
        @keydown="preventMultiline($event)"
        @focus="focus()"
        @blur="blur()"
      />
    </div>
    <auto-complete
      v-else-if="!field.isStrict"
      :value="value"
      :items="UniqueListOfAllowedAndCurrent(field)"
      :is-strict="false"
      :header="header"
      class="survey-input-field survey-input-field-autocomplete"
      @select="change($event)"
      @focus="focus()"
    />
    <auto-complete
      v-else
      :value="value"
      :items="field.allowedValues"
      :is-strict="true"
      :header="header"
      class="survey-input-field survey-input-field-autocomplete"
      @select="change($event)"
      @focus="focus()"
    />
  </div>
</template>

<script>
const NEWLINE = 13

import { uniq } from "lodash/fp"
import AutoComplete from "./Autocomplete.vue"

export default {
  components: { AutoComplete },
  props: {
    value: {
      required: true,
      type: String,
    },
    field: {
      required: true,
      type: Object,
    },
    readOnly: {
      required: false,
      default: false,
      type: Boolean,
    },
    valid: {
      required: true,
      type: Boolean,
    },
    warning: {
      required: false,
      default: false,
      type: Boolean,
    },
    withSide: {
      required: false,
      default: false,
      type: Boolean,
    },
    header: {
      required: false,
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      localValue: "",
      showComboMenu: false,
      valueUpdated: false,
      textareaFocussed: false,
    }
  },
  methods: {
    change(value) {
      this.valueUpdated = false
      this.$emit("input", value || "")
    },
    preventMultiline(event) {
      if (event.keyCode === NEWLINE) {
        event.preventDefault()
      }
    },
    keyup(e) {
      if (!this.valueUpdated) {
        this.valueUpdated = true
      }

      this.localValue = e.target.value
      this.showComboMenu =
        (e.target.value !== this.value || !this.valueUpdated) && Boolean(e.target.value)
    },
    focus() {
      this.$emit("focus")

      this.textareaFocussed = true
    },
    blur() {
      if (this.localValue !== this.value && this.valueUpdated) {
        this.valueUpdated = false
        this.$emit("input", this.localValue || "")
      }

      this.textareaFocussed = false

      if (this.$refs.textarea) {
        this.$refs.textarea.scrollTop = 0
      }
    },
    UniqueListOfAllowedAndCurrent(field) {
      return uniq(field.currentValues.concat(field.allowedValues))
    },
  },
}
</script>

<style scoped>
textarea {
  overflow: hidden;
}
textarea:focus {
  overflow: auto;
}
.read-only-survey-input {
  position: relative;
  height: 42px;
}
.read-only-survey-input div {
  padding-left: 10px;
  max-height: 42px;
  position: absolute;
  width: 100%;
  overflow: hidden;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.free-text-survey-input-positioner {
  position: absolute;
  top: 50%;
  width: 100%;
  max-height: 42px;
  min-height: 21px;
  overflow: hidden;
  padding-right: 10px;
  transform: translateY(-50%);
}
.free-text-survey-input-positioner div {
  visibility: hidden;
}
.with-pencil .free-text-survey-input-positioner .survey-input-field-textarea {
  width: calc(100% + 20px);
}
.free-text-survey-input-positioner .survey-input-field-textarea {
  position: absolute;
  top: -15px;
  left: -10px;
  height: 53px;
  width: 100%;
}
.free-text-survey-input {
  position: absolute;
  height: 100%;
  width: 100%;
}
.text-area-focussed.free-text-survey-input-positioner {
  top: 0;
  transform: none;
  max-height: 100%;
  height: 100%;
  left: -10px;
}
.free-text-survey-input-positioner.text-area-focussed .survey-input-field-textarea {
  top: 0;
  left: 0;
  height: 53px;
  width: 100%;
}
</style>
