<template>
	<v-dialog
		:value="open"
		max-width="650px"
		@input="$emit('close', false)"
		@keydown.esc="$emit('close')"
	>
		<v-card>
			<v-card-title>
				<span class="headline">Business capability model overview</span>
			</v-card-title>
			<v-card-text>
				<p> Overview of all available business capabilities and the number of entered systems.<br>Click a business capability level to view your systems.</p>
				<business-unit-selector
					v-if="open"
					:trees="[tree]"
					:disabled-trees="disabledTrees"
					:systems-by-process-id="systemsByProcessId"
					:ontology-item-index="ontologyItemIndex"
					@selectOntologyItem="select($event)"
				/>
			</v-card-text>
			<v-card-actions>
				<v-spacer />
				<v-btn color="blue darken-1" flat @click="$emit('close')">
					cancel
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import BusinessUnitSelector from './BusinessUnitSelector.vue'

export default {
	components: {
		BusinessUnitSelector,
	},
	props: {
		open: {
			type: Boolean,
			required: true,
		},
		tree: {
			type: Object,
			required: true,
		},
		disabledTrees: {
			type: Array,
			required: true,
		},
		systemsByProcessId: {
			type: Object,
			required: true,
		},
		ontologyItemIndex: {
			type: Object,
			required: true,
		},
	},
	methods: {
		select(ontologyItem) {
			this.$emit('selectOntologyItem', ontologyItem)
		},
	},
}
</script>
