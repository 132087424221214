import { groupBy } from 'lodash/fp'

export class Dataset {
	constructor(items) {
		this.items = items
	}

	filterMissing(property) {
		return new Dataset(
			this.items.filter((item) => {
				const value = item[property]

				return value !== null && value !== undefined && value !== ''
			}),
		)
	}

	groupBy(property, orderByCount = null) {
		const groups = groupBy((item) => item[property] || '')(this.items)
		let offset = 0
		const keys = Object.keys(groups)

		if (orderByCount === 'DESC') {
			keys.sort((k1, k2) => groups[k2].length - groups[k1].length)
		} else if (orderByCount === 'ASC') {
			keys.sort((k1, k2) => groups[k1].length - groups[k2].length)
		} else {
			keys.sort()
		}

		return keys.map((key) => {
			const myOffset = offset
			const items = groups[key]

			offset += items.length

			return new DatasetGroup(
				key,
				items,
				myOffset,
				this.total || this.items.length,
			)
		})
	}

	get count() {
		return this.items.length
	}

	uniqueValuesFor(property) {
		const values = {}

		this.items.forEach((item) => {
			values[item[property] || ''] = true
		})

		return Object.keys(values).sort()
	}

	applyFilter(filter) {
		return new Dataset(
			this.items.filter((item) => {
				return Object.keys(filter).every((property) => {
					return (
						!filter[property]
						|| filter[property] === Dataset.ALL
						|| filter[property][item[property]]
					)
				})
			}),
		)
	}

	applyPartialFilter(filter, properties) {
		return new Dataset(
			this.items.filter((item) => {
				return properties.every((property) => {
					return (
						!filter[property]
						|| filter[property] === Dataset.ALL
						|| filter[property][item[property]]
					)
				})
			}),
		)
	}

	previousFilter(filter, properties) {
		return this.adjacentFilter(filter, properties, -1)
	}

	nextFilter(filter, properties) {
		return this.adjacentFilter(filter, properties, 1)
	}

	// eslint-disable-next-line max-statements
	adjacentFilter(filter, properties, delta) {
		let dataset = this // eslint-disable-line consistent-this

		if (properties.filter((property) => !filter[property])[0]) {
			return null
		}

		const levels = properties
			.filter((property) => filter[property] !== Dataset.ALL)
			.map((property, i) => {
				const value = Object.keys(filter[property])[0]
				const values = dataset.uniqueValuesFor(property)
				const index = values.indexOf(value)
				const applicableDataset = dataset

				dataset = dataset.applyFilter({ [property]: { [value]: true } })

				return {
					property,
					value,
					values,
					index,
					dataset: applicableDataset,
					sequence: i,
				}
			})

		const mainLevel = levels
			.slice()
			.reverse()
			.find((level) => {
				return delta === 1
					? level.index < level.values.length - 1
					: level.index > 0
			})

		if (mainLevel) {
			const newFilter = {}
			let mainLevelDataset = mainLevel.dataset
			const partialMainFilter = {
				[mainLevel.property]: {
					[mainLevel.values[mainLevel.index + delta]]: true,
				},
			}

			Object.assign(newFilter, partialMainFilter)
			mainLevelDataset = mainLevelDataset.applyFilter(partialMainFilter)
			levels.slice(mainLevel.sequence + 1).forEach((level) => {
				const values = mainLevelDataset.uniqueValuesFor(level.property)
				const value = delta === 1 ? values[0] : values.slice(-1)[0]
				const partialFilter = { [level.property]: { [value]: true } }

				Object.assign(newFilter, partialFilter)
				mainLevelDataset = mainLevelDataset.applyFilter(partialFilter)
			})

			return newFilter
		} else {
			return null
		}
	}
}

Dataset.ALL = { all: true }

export class DatasetGroup extends Dataset {
	constructor(key, items, offset, total) {
		super(items)
		this.key = key
		this.offset = offset
		this.total = total
	}
}
