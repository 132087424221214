import graphqlClient, { removeTypeNames } from '../../../api/db'
import gql from 'graphql-tag'
import { filter, omit } from 'lodash/fp'
import { allFieldValidations } from '../../../api/fragments'
import { createValidator } from '../../../api/validations'

// Initial state
const initialState = {
	all: [],
	saving: false,
	userValidator: null,
	serverUserErrors: null,
	serverError: null,
}

// Getters
const getters = {
	getUsersFromCompany: (state) => (companyId) => {
		return filter((c) => c.companyId === companyId, state.all)
	},
}

// Actions
const actions = {
	async getAllUsers({ commit }, payload) {
		const response = await graphqlClient.query({
			query: gql`
				query Users ($inactive: Boolean) {
					users (includeInactive : $inactive) {
						id
						email
						firstName
						lastName
						hasPassword
						companyId
						isAdmin
						active
					}
					userValidations {
						Object {
							firstName { ...allFieldValidations }
							lastName { ...allFieldValidations }
							email { ...allFieldValidations }
							password { ...allFieldValidations }
						}
					}
				}
				${allFieldValidations}
			`,
			variables: {
				inactive: payload,
			},
		})

		commit('setUsers', response.data.users)
		commit('setUserValidator', createValidator(removeTypeNames(response.data.userValidations)))
	},
	createUser({ commit }, payload) {
		commit('setSaving', true)
		commit('setServerError', false)
		commit('setServerUserErrors', null)

		return graphqlClient.mutate({
			mutation: gql`
				mutation CreateUser($user: UserCreateProps!) {
					createUser(props: $user) {
						id
					}
				}
			`,
			variables: {
				user: omit(['hasPassword'], removeTypeNames(payload)),
			},
		}).catch((e) => {
			if (e.graphQLErrors) {
				const error = e.graphQLErrors[0]

				if (error.validationErrors) {
					commit('setServerUserErrors', error.validationErrors)
				} else {
					commit('setServerError', true)
				}
			} else {
				commit('setServerError', true)
			}

			commit('setSaving', false)
			throw e
		}).then((response) => {
			commit('setServerError', false)
			commit('createUser', Object.assign({}, { id: response.data.createUser.id }, payload))
			commit('setSaving', false)
		})
	},

	updateUser({ commit }, payload) {
		commit('setSaving', true)
		commit('setServerError', false)
		commit('setServerUserErrors', null)

		return graphqlClient.mutate({
			mutation: gql`
				mutation UpdateUser($user: UserUpdateProps!) {
					updateUser(props: $user) {
						id
					}
				}
			`,
			variables: {
				user: omit(['companyId', 'hasPassword'], removeTypeNames(payload)),
			},
		}).then(() => {
			commit('setSaving', false)
			commit('updateUser', payload)
		}).catch((e) => {
			if (e.graphQLErrors) {
				const error = e.graphQLErrors[0]

				if (error.validationErrors) {
					commit('setServerUserErrors', error.validationErrors)
				} else {
					commit('setServerError', true)
				}
			} else {
				commit('setServerError', true)
			}

			commit('setSaving', false)
			throw e
		})
	},

	disableCompany({ commit }, company) {
		commit('disableCompany', company)
	},

	deleteUser({ commit }, payload) {
		commit('setSaving', true)
		commit('setServerError', false)
		commit('setServerUserErrors', null)

		return graphqlClient.mutate({
			mutation: gql`
				mutation DeleteUser($id: String!) {
					deleteUser(id:$id)
				}
			`,
			variables: {
				id: payload.id,
			},
		}).then(() => {
			commit('setSaving', false)
			commit('deleteUser', payload)
		}).catch((e) => {
			if (e.graphQLErrors) {
				const error = e.graphQLErrors[0]

				if (error.validationErrors) {
					commit('setServerUserErrors', error.validationErrors)
				} else {
					commit('setServerError', true)
				}
			} else {
				commit('setServerError', true)
			}

			commit('setSaving', false)
			throw e
		})
	},
	async sendNotificationEmail(_context, email) {
		await graphqlClient.mutate({
			mutation: gql`
				mutation SendNotifiationEmail($email: String!) {
					sendNotificationEmail(email: $email)
				}
			`,
			variables: {
				email,
			},
		})
	},
}

// Mutations
const mutations = {
	setUsers(state, comps) {
		state.all = comps
	},
	setUserValidator(state, validator) {
		state.userValidator = validator
	},
	createUser(state, user) {
		state.all.push(user)
	},
	updateUser(state, user) {
		state.all = state.all.map((u) => u.id === user.id ? user : u)
	},
	deleteUser(state, user) {
		state.all = state.all.map((u) => u.id === user.id ? {} : u)
	},
	setSaving(state, saving) {
		state.saving = saving
	},
	setServerError(state, error) {
		state.serverError = error
	},
	setServerUserErrors(state, errors) {
		state.serverUserErrors = errors
	},
	disableCompany(state, company) {
		state.all.forEach((user) => {
			const index = state.all.findIndex(u => u.companyId === company.id)

			state.all[index] = user
		})
	},
}

export default {
	namespaced: true,
	state: initialState,
	getters,
	actions,
	mutations,
}
