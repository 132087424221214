/* eslint-disable no-process-env */

export const config = {
  baseUrl: process.env.BASE_URL || 'MISSING process.env.BASE_URL',

  // URL hosting the backend.
  apiUrl: process.env.VUE_APP_API_URL || 'http://localhost:4000/development',

  // Log level for console output
  consoleLogLevel: process.env.VUE_APP_CONSOLE_LOG_LEVEL || 'silly',
}

export const environment = process.env.NODE_ENV

export const languages = [
  { key: 'nl', value: 'Nederlands' },
  { key: 'fr', value: 'Français' },
]
