import gql from 'graphql-tag'
import { keyBy } from 'lodash'
import { omit, range, sortBy } from 'lodash/fp'
import { api } from '../../../api/api-service'
import graphqlClient, { removeTypeNames } from '../../../api/db'
import { createValidator } from '../../../api/validations'
import {
  treeifyOntologyItems,
  updateTreeForNewOntologyItem,
} from '../../../lib/ontology'
import { createStoreUtils } from '../../../lib/vuex'
import { acceptDraftTaxonomyVersionStore } from './dialog/accept-draft-taxonomy-version'
import { createNewTaxonomyVersionStore } from './dialog/create-new-taxonomy-version'
import { deleteTaxonomyVersionStore } from './dialog/delete-taxonomy-version'
import { downloadTaxonomyVersionStore } from './dialog/download-taxonomy-version'
import { downloadTaxonomyVersionMappingStore } from './dialog/download-taxonomy-version-mapping'

/**
 * @typedef {Store.Ontology.LocalState} State
 * @typedef {typeof getters} Getters
 * @typedef {typeof mutations} Mutations
 * @typedef {typeof actions} Actions
 */

/**
 * @template {any} Payload [Payload=void]
 * @typedef {MutationFor<State, Payload>} Mutate
 */

/**
 * @template {any} Payload [Payload=void]
 * @typedef {ActionFor<Mutations, State, Payload, void>} Act
 */

/**
 * @template {any} Payload [Payload=void]
 * @template {any} ReturnValue [ReturnValue=void]
 * @typedef {ActionFor<Mutations, State, Payload, ReturnValue>} ActWithReturn
 */

/** @type {State}  */
const initialState = {
  all: [],
  allByProcessId: {},
  selectedTaxonomyVersion: null,
  ontologyItemValidator: null,
  taxonomyVersions: [],
  dialog: {
    editOntologyItem: {
      isOpen: false,
      ontologyItem: null,
    },
  },
}

/** @satisfies {GettersFor<State>} */
const getters = {
  // Ontology & Taxonomy
  currentTaxonomyVersion: (state) =>
    state.taxonomyVersions.find((v) => v.current),
  selectedTaxonomyVersion: (state) => state.selectedTaxonomyVersion,
  rootOntologyItems: (state) => {
    return state.all.filter((item) => item.parentId === null)
  },
  taxonomyVersionsLoaded: (state) => state.taxonomyVersions.length > 0,
  currentTaxonomyVersionHasDraft: (state) =>
    state.taxonomyVersions.length
      ? !!state.taxonomyVersions.find((tv) => tv.draft)
      : false,

  // Dialogs
  editOntologyItemDialogIsOpen: (state) => state.dialog.editOntologyItem.isOpen,
  editOntologyItemDialogOntologyItem: (state) =>
    state.dialog.editOntologyItem.ontologyItem,
}

/** @satisfies {MutationsFor<State>} */
const mutations = {
  /** @type {Mutate<any>} */
  openDialog(state, { dialog, ...subState }) {
    // @ts-ignore
    state.dialog[dialog] = {
      // @ts-ignore
      ...state.dialog[dialog],
      ...subState,
      isOpen: true,
    }
  },

  /** @type {Mutate<any>} */
  closeDialog(state, { dialog, ...subState }) {
    // @ts-ignore
    state.dialog[dialog] = {
      // @ts-ignore
      ...state.dialog[dialog],
      ...subState,
      isOpen: false,
    }
  },

  /** @type {Mutate<void>} */
  clearOntology(state) {
    state.all = []
    state.allByProcessId = {}
    state.taxonomyVersions = []
    state.ontologyItemValidator = null
  },

  /** @type {Mutate<{field: keyof Model.OntologyItem; value: any}>} */
  editOntologyItem(state, { field, value }) {
    const ontologyItem = state.dialog.editOntologyItem.ontologyItem
    if (ontologyItem) {
      // @ts-ignore
      ontologyItem[field] = value
    }
  },

  /** @type {Mutate<{ontologyItems: Model.OntologyItem[]}>} */
  storeOntology(state, { ontologyItems }) {
    state.all = sortBy((i) => i.name.toLowerCase())(ontologyItems)
    state.allByProcessId = keyBy(ontologyItems, 'processId')
    // in-memory change to Model.TreeOntologyItem[]
    treeifyOntologyItems({ byId: state.allByProcessId })
  },

  /** @type {Mutate<Model.TaxonomyVersion[]>} */
  storeTaxonomyVersions(state, taxonomyVersions) {
    state.taxonomyVersions = taxonomyVersions.sort((a, b) =>
      a.name.localeCompare(b.name),
    )
    if (!state.selectedTaxonomyVersion) {
      const currentTaxonomyVersion =
        taxonomyVersions.find((v) => v.draft) ||
        taxonomyVersions.find((v) => v.current) ||
        null
      state.selectedTaxonomyVersion = currentTaxonomyVersion
    }
  },

  /** @type {Mutate<{taxonomyVersion: Model.TaxonomyVersion}>} */
  storeNewTaxonomyVersion(state, { taxonomyVersion }) {
    state.taxonomyVersions = [...state.taxonomyVersions, taxonomyVersion].sort(
      (a, b) => a.name.localeCompare(b.name),
    )
  },

  /** @type {Mutate<object>} */
  setOntologyItemValidator(state, validator) {
    state.ontologyItemValidator = validator
  },

  /** @type {Mutate<{ontologyItems: Model.TreeOntologyItem[]}>} */
  createOntologyItems(state, { ontologyItems }) {
    for (const item of ontologyItems) {
      state.all.push(item)
      state.allByProcessId[item.processId] = item
    }
    for (const item of ontologyItems) {
      updateTreeForNewOntologyItem(item, { byId: state.allByProcessId })
    }
    state.all = state.all.sort((a, b) => a.name.localeCompare(b.name))
  },

  /** @type {Mutate<Partial<Model.OntologyItem>>} */
  updateOntologyItem(state, updatedItem) {
    Object.assign(state.allByProcessId[updatedItem.processId], updatedItem)
    state.all = state.all.sort((a, b) => a.name.localeCompare(b.name))
  },

  /** @type {Mutate<Model.TreeOntologyItem>} */
  deleteOntologyItem(state, ontologyItem) {
    const index = state.all.indexOf(ontologyItem)
    state.all.splice(index, 1)
    delete state.allByProcessId[ontologyItem.processId]
  },

  /** @type {Mutate<Model.TaxonomyVersion>} */
  storeSelectedTaxonomyVersion(state, taxonomyVersion) {
    state.selectedTaxonomyVersion = taxonomyVersion
  },

  /** @type {Mutate<void>} */
  pickParentTaxonomyVersion(state) {
    const selectedTaxonomyVersion = state.selectedTaxonomyVersion
    if (!selectedTaxonomyVersion) {
      throw new Error('No selected taxonomy version')
    }

    const parentTaxonomyVersion = state.taxonomyVersions.find(
      (tv) => tv.id === selectedTaxonomyVersion.parentTaxonomyVersionId,
    )
    if (parentTaxonomyVersion) {
      state.selectedTaxonomyVersion = parentTaxonomyVersion
    }
  },

  /** @type {Mutate<void>} */
  resetState(state) {
    state.all = []
    state.allByProcessId = {}
    state.selectedTaxonomyVersion = null
    state.ontologyItemValidator = null
    state.taxonomyVersions = []
    state.dialog = {
      editOntologyItem: {
        isOpen: false,
        ontologyItem: null,
      },
    }
  },
}

/** @satisfies {ActionsFor<Mutations, State>} */
const actions = {
  /** @type {Act<Model.TaxonomyVersion>} */
  async pickTaxonomyVersion({ commit, dispatch }, taxonomyVersion) {
    commit('storeSelectedTaxonomyVersion', taxonomyVersion)
    dispatch('loadOntology', {
      taxonomyVersionId: taxonomyVersion.id,
    })
  },

  /** @type {Act<{ontologyItem: Model.TreeOntologyItem}>} */
  async openEditOntologyItemDialog({ commit }, { ontologyItem }) {
    commit('openDialog', {
      dialog: 'editOntologyItem',
      ontologyItem: {
        name: ontologyItem.name,
        active: ontologyItem.active,
        terms: ontologyItem.terms.join('\n'),
        description: ontologyItem.description,
        totalSurveyItemCount: ontologyItem.totalSurveyItemCount,
        processId: ontologyItem.processId,
      },
    })
  },

  /** @type {Act<void>} */
  async closeEditOntologyItemDialog({ commit }) {
    commit('closeDialog', {
      dialog: 'editOntologyItem',
      ontologyItem: null,
    })
  },

  /** @type {Act<{surveyDomainId?: string; taxonomyVersionId?: string }>} */
  async loadOntology({ commit }, { surveyDomainId, taxonomyVersionId }) {
    commit('clearOntology', undefined)
    const data = await api.getAdminOntology({
      surveyDomainId,
      taxonomyVersionId,
    })

    commit('storeOntology', {
      ontologyItems: data.ontologyItems,
    })
    commit('storeTaxonomyVersions', removeTypeNames(data.taxonomyVersions))
    commit(
      'setOntologyItemValidator',
      createValidator(removeTypeNames(data.ontologyItemValidations)),
    )
  },

  /** @type {Act<void>} */
  async resetState({ commit, dispatch, rootState }) {
    commit('resetState', undefined)
    dispatch('loadOntology', {
      surveyDomainId: rootState.surveys.selectedSurveyDomain.id,
    })
  },

  /** @type {Act<{surveyTemplateId: string; businessUnitId: string }>} */
  async userEditingSurvey(_, { surveyTemplateId, businessUnitId }) {
    await graphqlClient.mutate({
      mutation: gql`
        mutation userEditingSurvey(
          $surveyTemplateId: ID!
          $businessUnitId: ID!
        ) {
          userEditingSurvey(
            surveyTemplateId: $surveyTemplateId
            businessUnitId: $businessUnitId
          ) {
            userId
            surveyTemplateId
            companyId
            businessUnitId
            editorOpenAt
          }
        }
      `,
      variables: {
        surveyTemplateId,
        businessUnitId,
      },
    })
  },

  /** @type {ActWithReturn<{ontologyItem: Model.TreeOntologyItem, level: number }, Model.TreeOntologyItem[]>} */
  async createOntologyItem({ commit, state }, { ontologyItem, level }) {
    /** @type {Model.TreeOntologyItem[]} */
    const ontologyItems = []

    // eslint-disable-next-line no-magic-numbers
    await range(level, 4).reduce(async (parentPromise, l) => {
      const parentId = await parentPromise
      const newOntologyItem =
        l === level
          ? removeTypeNames({
              ...ontologyItem,
              parentId: ontologyItem.parentId ?? null,
              taxonomyVersionId: state.selectedTaxonomyVersion.id,
            })
          : {
              name: '-',
              parentId,
              terms: [],
              taxonomyVersionId: state.selectedTaxonomyVersion.id,
            }

      newOntologyItem.active = true

      const response = await graphqlClient.mutate({
        mutation: gql`
          mutation CreateOntologyItem($ontologyItem: OntologyItemCreateProps!) {
            createOntologyItem(props: $ontologyItem) {
              processId
            }
          }
        `,
        variables: {
          ontologyItem: newOntologyItem,
        },
      })
      const processId = response.data.createOntologyItem.processId

      ontologyItems.push({
        ...newOntologyItem,
        processId,
        surveyItemCounts: [{ count: 0 }],
        totalSurveyItemCount: 0,
        terms: [],
      })

      return processId
    }, Promise.resolve(ontologyItem.parentId))

    commit('createOntologyItems', { ontologyItems })

    return ontologyItems
  },

  /** @type {Act<Model.TreeOntologyItem>} */
  async deleteOntologyItem({ commit }, ontologyItem) {
    await graphqlClient.mutate({
      mutation: gql`
        mutation DeleteOntologyItem($processId: Int!) {
          deleteOntologyItem(processId: $processId)
        }
      `,
      variables: {
        processId: ontologyItem.processId,
      },
    })

    commit('deleteOntologyItem', ontologyItem)
  },

  /** @type {Act<Model.TreeOntologyItem>} */
  async updateOntologyItem({ commit }, ontologyItem) {
    await graphqlClient.mutate({
      mutation: gql`
        mutation UpdateOntologyItem($ontologyItem: OntologyItemUpdateProps!) {
          updateOntologyItem(props: $ontologyItem) {
            processId
          }
        }
      `,
      variables: {
        ontologyItem: omit([
          'surveyItemCounts',
          'totalSurveyItemCount',
          'parent',
          'children',
        ])(removeTypeNames(ontologyItem, 0)),
      },
    })

    commit('updateOntologyItem', ontologyItem)
  },
}

export const ontologyStore = {
  namespaced: true,
  ...createStoreUtils('ontology', {
    state: initialState,
    getters,
    actions,
    mutations,
  }),
  modules: {
    createNewTaxonomyVersion: createNewTaxonomyVersionStore,
    downloadTaxonomyVersionMapping: downloadTaxonomyVersionMappingStore,
    downloadTaxonomyVersion: downloadTaxonomyVersionStore,
    deleteTaxonomyVersion: deleteTaxonomyVersionStore,
    acceptDraftTaxonomyVersion: acceptDraftTaxonomyVersionStore,
  },
}

export default ontologyStore
