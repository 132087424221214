<template>
	<div>
		<p>
			<v-text-field
				v-model="filter"
				label="Search the business capabilities"
			/>
		</p>
		<div v-show="filterHighlight" :style="{ maxHeight, overflowY: 'auto' }">
			<business-levels-tree
				v-for="ontologyItem in filteredTrees"
				:key="ontologyItem.processId"
				:highlight="filterHighlight"
				:ontology-item="ontologyItem"
				:systems-by-process-id="filteredSystemsByProcessId"
				:open-reset-counter="openResetCounter"
				:reset-on-reset="true"
				:select-only-leafs="selectOnlyLeafs"
				:closed="false"
				:highlighted-path="[]"
				:prune-empty="false"
				:disable-item="disableItem"
				@select="select($event)"
			/>
			<business-levels-tree
				v-for="ontologyItem in filteredDisabledTrees"
				:key="ontologyItem.processId"
				:highlight="filterHighlight"
				:ontology-item="ontologyItem"
				:systems-by-process-id="filteredSystemsByProcessId"
				:open-reset-counter="openResetCounter"
				:reset-on-reset="true"
				:select-only-leafs="selectOnlyLeafs"
				:disabled="true"
				:closed="closed"
				:prune-empty="pruneEmpty"
				:highlighted-path="highlightedPath"
				:disable-item="disableItem"
				@select="select($event)"
			/>
		</div>
		<div v-show="!filterHighlight" :style="{ maxHeight, overflowY: 'auto' }">
			<business-levels-tree
				v-for="ontologyItem in filteredTrees"
				:key="ontologyItem.processId"
				:highlight="null"
				:ontology-item="ontologyItem"
				:systems-by-process-id="filteredSystemsByProcessId"
				:open-reset-counter="0"
				:reset-on-reset="false"
				:select-only-leafs="selectOnlyLeafs"
				:closed="closed"
				:highlighted-path="highlightedPath"
				:prune-empty="pruneEmpty"
				:disable-item="disableItem"
				@select="select($event)"
			/>
			<business-levels-tree
				v-for="ontologyItem in filteredDisabledTrees"
				:key="ontologyItem.processId"
				:highlight="null"
				:ontology-item="ontologyItem"
				:systems-by-process-id="filteredSystemsByProcessId"
				:open-reset-counter="0"
				:reset-on-reset="false"
				:select-only-leafs="selectOnlyLeafs"
				:disabled="true"
				:closed="closed"
				:prune-empty="pruneEmpty"
				:highlighted-path="highlightedPath"
				:disable-item="disableItem"
				@select="select($event)"
			/>
		</div>
	</div>
</template>

<script>
import BusinessLevelsTree from './BusinessLevelsTree.vue'

export default {
	components: {
		BusinessLevelsTree,
	},
	props: {
		trees: {
			type: Array,
			required: true,
		},
		disabledTrees: {
			type: Array,
			required: true,
		},
		systemsByProcessId: {
			type: Object,
			default: null,
		},
		ontologyItemIndex: {
			type: Object,
			required: true,
		},
		selectOnlyLeafs: {
			type: Boolean,
			default: false,
		},
		pruneEmpty: {
			type: Boolean,
			default: true,
		},
		closed: {
			type: Boolean,
			default: false,
		},
		highlightedPath: {
			type: Array,
			default: null,
		},
		maxHeight: {
			type: String,
			default: '50vh',
		},
		disableItem: {
			type: Object,
			default: null,
		},
	},
	data() {
		return {
			filter: '',
			openResetCounter: 0,
		}
	},
	computed: {
		filteredTrees() {
			return this.filteredData.trees
		},
		filteredDisabledTrees() {
			return this.filteredData.disabledTrees
		},
		filteredSystemsByProcessId() {
			return this.filteredData.systemsByProcessId
		},
		filterHighlight() {
			if (this.filter) {
				return (s) => {
					const lowerCaseS = s.toLowerCase()

					const terms = this.filter.toLowerCase().split(/ +/)

					return terms.map((term) => {
						const index = lowerCaseS.indexOf(term)

						return index >= 0 ? { from: index, to: index + term.length } : null
					}).filter((m) => m)
				}
			} else {
				return null
			}
		},
		filteredData() {
			if (this.filter) {
				const systemsByProcessId = {}

				return {
					trees: this.trees.map((tree) => this.filterTree(tree, systemsByProcessId)).filter((tree) => tree),
					disabledTrees: this.disabledTrees.map((tree) => this.filterTree(tree, systemsByProcessId)).filter((tree) => tree),
					systemsByProcessId,
				}
			} else {
				return {
					trees: this.trees,
					disabledTrees: this.disabledTrees,
					systemsByProcessId: this.systemsByProcessId,
				}
			}
		},
	},
	watch: {
		filter() {
			this.openResetCounter += 1
		},
	},
	methods: {
		select(ontologyItem) {
			this.$emit('selectOntologyItem', ontologyItem)
		},
		// eslint-disable-next-line max-statements
		filterTree(ontologyItem, systemMap) {
			// Now everything has a mapped path, We also need to check that they are leaves. We do this by looking at the "realChilren" property. There won't be any for a leaf.
			if (!ontologyItem.realChildren) {
				if (this.filterMatches(ontologyItem)) {
					const systems = this.systemsByProcessId[ontologyItem.id]

					if (systems) {
						ontologyItem.mappedPath.forEach((itemId) => {
							if (!systemMap[itemId]) {
								systemMap[itemId] = []
							}

							systemMap[itemId].push(...systems)
						})
					}

					return ontologyItem
				} else {
					return null
				}
			} else {
				const realChildren = ontologyItem.realChildren.map((child) => this.filterTree(child, systemMap)).filter((item) => item)

				if (realChildren.length) {
					return { ...ontologyItem, realChildren }
				} else {
					return null
				}
			}
		},
		filterMatches(item) {
			const terms = this.filter.toLowerCase().split(/ +/)

			return terms.every((term) =>
				item.mappedPath.some((pathItemId) => {
					const pathItem = this.ontologyItemIndex[pathItemId]

					return pathItemId === 'root' ? false : pathItem.name.toLowerCase().indexOf(term) >= 0 || pathItem.terms.some((t) => t.toLowerCase().indexOf(term) >= 0)
				}),
			)
		},
	},
}
</script>
