<template>
	<div>
		<div
			:id="`tree-item-${ontologyItem.id}`"
			:class="{ folder: isFolder, highlighted: highlightedPath && highlightedPath.includes(ontologyItem.id), explicitlyDisabled: explicitlyDisabled }"
			:title="ontologyItem.terms.join(', ')"
			class="element"
		>
			<span v-if="isFolder" :class="{ open: isOpen }" class="toggler" @click="toggle" />
			<span
				:class="{ 'non-empty': numberOfsystems > 0 && pruneEmpty, disabled: disabled, selectable: selectable }"
				class="item"
				@click="select"
			>
				<span v-for="(part, i) in highlightedName" :key="i" :class="{ highlighted: part.highlighted }">{{ part.string }}</span> ({{ numberOfsystems }})</span>
				<v-tooltip bottom>
					<v-icon 
						small 
						class="help-icon" 
						slot="activator"
					>
						help
					</v-icon>
					<span> {{ontologyItem.description}} </span>
				</v-tooltip>
		</div>
		<ul v-if="isOpen && isFolder">
			<li
				v-for="child in ontologyItem.realChildren"
				:key="child.id"
			>
				<business-levels-tree
					:ontology-item="child"
					:systems-by-process-id="systemsByProcessId"
					:disabled="disabled"
					:highlight="highlight"
					:open-reset-counter="openResetCounter"
					:select-only-leafs="selectOnlyLeafs"
					:prune-empty="pruneEmpty"
					:closed="closed"
					:highlighted-path="highlightedPath"
					:reset-on-reset="resetOnReset"
					:disable-item="disableItem"
					@select="$emit('select', $event)"
				>
					{{ child.name }}
				</business-levels-tree>
			</li>
		</ul>
	</div>
</template>

<script>
export default {
	name: 'BusinessLevelsTree',
	props: {
		ontologyItem: {
			type: Object,
			required: true,
		},
		systemsByProcessId: {
			type: Object,
			required: true,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		closed: {
			type: Boolean,
			default: false,
		},
		highlight: {
			type: Function,
			default: null,
		},
		openResetCounter: {
			type: Number,
			default: 0,
		},
		resetOnReset: {
			type: Boolean,
			default: true,
		},
		selectOnlyLeafs: {
			type: Boolean,
			required: true,
		},
		pruneEmpty: {
			type: Boolean,
			default: true,
		},
		highlightedPath: {
			type: Array,
			default: null,
		},
		disableItem: {
			type: Object,
			default: null,
		},
	},
	data() {
		return {
			isOpen: this.shouldBeOpen(),
		}
	},
	computed: {
		isFolder() {
			return this.ontologyItem.realChildren && !this.hasUninformativeChildren
		},
		numberOfsystems() {
			return this.calculateNumberOfSystems()
		},
		selectableItem() {
			return this.calculateSelectableItem()
		},
		hasUninformativeChildren() {
			return this.calculatedHasUninformativeChildren(this.ontologyItem)
		},
		// eslint-disable-next-line complexity,max-statements
		highlightedName() {
			const highlights = this.highlight ? this.highlight(this.ontologyItem.name) : null

			if (highlights) {
				const highlighted = new Array(this.ontologyItem.name.length).fill(false)

				highlights.sort((a, b) => a.from - b.from).forEach(({ from, to }) => {
					for (let i = from; i < to; i++) {
						highlighted[i] = true
					}
				})

				const parts = []
				let part = null

				for (let i = 0; i < this.ontologyItem.name.length; i++) {
					if (part) {
						if (part.highlighted === highlighted[i]) {
							part.to = i
						} else {
							parts.push(part)
							part = {
								from: i,
								to: i,
								highlighted: highlighted[i],
							}
						}
					} else {
						part = {
							from: i,
							to: i,
							highlighted: highlighted[i],
						}
					}
				}

				if (part) {
					parts.push(part)
				}

				return parts.map((p) => ({
					string: this.ontologyItem.name.slice(p.from, p.to + 1),
					highlighted: p.highlighted,
				}))
			} else {
				return [{
					string: this.ontologyItem.name,
					highlighted: false,
				}]
			}
		},
		__shouldBeOpen() {
			return this.shouldBeOpen()
		},
		selectable() {
			return !(this.isFolder && this.selectOnlyLeafs) && !this.explicitlyDisabled
		},
		explicitlyDisabled() {
			return this.disableItem && this.disableItem.processId === this.ontologyItem.id
		},
	},
	watch: {
		openResetCounter() {
			if (this.resetOnReset) {
				this.isOpen = this.shouldBeOpen()
			}
		},
	},
	methods: {
		toggle() {
			this.isOpen = !this.isOpen
		},
		select() {
			if (!this.isFolder || !this.selectOnlyLeafs) {
				this.$emit('select', this.selectableItem)
			}
		},
		calculatedHasUninformativeChildren(item) {
			return !item.realChildren || item.realChildren.length === 1 && item.realChildren[0].name === '-' && this.calculatedHasUninformativeChildren(item.realChildren[0])
		},
		calculateNumberOfSystems() {
			return (this.systemsByProcessId[this.ontologyItem.id] || []).length
		},
		calculateSelectableItem() {
			return this.hasUninformativeChildren ? this.calculateSpecificChild(this.ontologyItem) : this.ontologyItem.leaf || this.ontologyItem
		},
		calculateSpecificChild(item) {
			return item.realChildren ? this.calculateSpecificChild(item.realChildren[0]) : item
		},
		shouldBeOpen() {
			return Boolean(this.highlight) || !(this.closed || this.pruneEmpty && this.calculateNumberOfSystems() === 0)
		},
	},
}
</script>

<style lang="scss">
body {
	font-family: Menlo, Consolas, monospace;
	color: #444;
}
.item {
	&.disabled {
		color: grey;
		pointer-events: none;
	}
}
.selectable {
	cursor: pointer;
	&:hover {
		color : #0F5187;
	}
}
.element {
	position: relative;
	padding-left: 1.5em;
}
.folder {
	padding-left: 1.5em;
}
.highlighted {
	font-weight: bold;
}
.non-empty {
	font-weight: bold;
}
.folder .item {
	font-style: italic;
}
ul {
	padding-left: 1.5em;
	line-height: 1.5em;
	list-style-type: none;
}
.toggler {
	display: inline-block;
	line-height: 0.8em;
	position: absolute;
	left: 0.2em;
	top: 0.35em;
	width: 0.8em;
	height: 0.8em;
	box-sizing: border-box;
	border: 1px solid black;
	cursor: pointer;
	&::before {
		content: "+";
		display: inline-block;
		height: 0,8em;
		width: 100%;
		line-height: 0,8em;
		text-align: center;
		position: relative;
		top: -1px;
	}
	&.open::before {
		content: "-";
	}
}
.explicitlyDisabled {
	cursor: not-allowed;
}
.help-icon {
	cursor: pointer;
	margin-left: 10px;
}
</style>
