<template>
	<div :class="`ontology-browser-${size}`">
		<div><v-text-field v-model="search" type="text" placeholder="Search business capability levels" /></div>
		<div v-if="search" v-bar class="matches">
			<div>
				<v-btn
					v-for="item in matches"
					:key="item.processId"
					class="ontology-search-result"
					@click="selectOntologyItem(item, true)"
				>
					{{ item.name }}{{ countFor(item) }}
				</v-btn>
			</div>
		</div>
		<v-layout row class="ontology-browser">
			<v-flex v-for="(level, i) in levels" :key="i" class="ontology-browser-column">
				<h2 class="ontology-browser-column-title">
					{{ level.label }}
				</h2>
				<div v-bar class="ontology-options-wrapper">
					<ul>
						<li
							v-for="item in level.options"
							:id="`ontology-item-${item.processId}`"
							:key="item.processId"
							:class="{ selected: item === level.selected, selectable: item.children && item.children.length }"
							class="ontology-item"
							@click="selectOntologyItem(item, false)"
						>
							<div class="item-content">
								<span>{{ item.name }}{{ countFor(item) }}</span>
								<v-tooltip bottom>
									<v-icon 
										small 
										class="help-icon" 
										slot="activator"
									>
										help
								</v-icon>
									<span>{{item.description}}</span>
								</v-tooltip>
							</div>
						</li>
					</ul>
				</div>
			</v-flex>
		</v-layout>
	</div>
</template>

<script>
export default {
	props: {
		ontology: {
			type: Object,
			required: true,
		},
		systemsByProcessId: {
			type: Object,
			default: null,
		},
		closed: {
			type: Boolean,
			required: true,
		},
		size: {
			type: String,
			required: true,
			validator(value) {
				return ['medium', 'large'].indexOf(value) >= 0
			},
		},
	},
	data() {
		return {
			selectedOntologyItem: null,
			search: '',
		}
	},
	computed: {
		levels() {
			return this.path.filter((item) => !item || item.children && item.children.length).map((item, i) => {
				const options = item ? item.children : this.ontology.roots
				const selected = this.path[i + 1]

				return {
					label: `Business capability level ${i + 1}`,
					options,
					selected,
				}
			})
		},
		path() {
			let currentItem = this.selectedOntologyItem
			const path = [currentItem]

			while (currentItem) {
				currentItem = this.ontology.index[currentItem.parentId]

				path.push(currentItem)
			}

			return path.reverse()
		},
		matches() {
			return this.ontology.allOntologyItems.filter(({ name }) => this.searchMatches(name))
		},
	},
	watch: {
		closed(val) {
			if (val) {
				this.selectedOntologyItem = null
				this.search = ''
			}
		},
	},
	methods: {
		searchMatches(string) {
			return string.toLowerCase().indexOf(this.search.toLowerCase()) >= 0
		},
		countFor(item) {
			if (this.systemsByProcessId) {
				const systems = this.systemsByProcessId[item.processId]

				return ` (${systems ? systems.length : 0})`
			} else {
				return ''
			}
		},
		selectOntologyItem(item, fromSearch) {
			if (fromSearch || item.children && item.children.length) {
				this.selectedOntologyItem = item

				if (fromSearch) {
					setTimeout(() => {
						this.path.forEach((pathItem) => {
							if (pathItem) {
								const el = document.getElementById(`ontology-item-${pathItem.processId}`)

								if (el) {
									el.scrollIntoView()
								}
							}
						})
					})
				}
			}
		},
	},
}
</script>

<style scoped>
.selected {
	font-weight: bold;
}
.ontology-item {
	line-height: 1.5em;
	padding: 0.5em;
	padding-right: 15px;
}
.selectable {
	cursor: pointer;
}
.matches span {
	display: inline-block;
	padding: 0.5em 1em;
	background: red;
	margin: 0.5em;
}
.ontology-browser {
	padding-top: 20px;
	overflow-x: auto;
}
.ontology-browser-column {
	border-right: 1px solid grey;
	padding: 0 20px !important;
	min-width: 280px;
	max-width: 280px;
}
.ontology-browser-column:last-child {
	border-right: 0;
}
ul {
	margin-left: 0;
	padding-left: 0;
}
.ontology-browser-column-title {
	color: #105187;
	text-align: center;
	margin-bottom: 20px;
	font-size: 1.3em;
}
.ontology-browser-medium .matches {
	max-height: calc(30vh - 110px);
	height: calc(30vh - 110px);
}
.ontology-browser-medium .ontology-options-wrapper {
	max-height: calc(60vh - 220px);
}
.ontology-browser-large .matches {
	max-height: calc(33.3vh - 107px);
	height: calc(33.3vh - 107px);
}
.ontology-browser-large .ontology-options-wrapper {
	max-height: calc(66.6vh - 214px);
}
.ontology-options-wrapper {
	overflow-y: auto !important;
}
.help-icon {
	cursor: pointer;
}
.item-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
</style>
