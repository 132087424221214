import distinctColors from 'distinct-colors'

const MAX_COLORS = 25

export class ColorCache {
	constructor(dataset) {
		this.dataset = dataset
		this.cache = {}
	}

	clear() {
		this.cache = {}
	}

	colorFor(property, value) {
		if (!this.cache[property]) {
			const values = this.dataset.uniqueValuesFor(property)

			if (values.length) {
				const count = Math.min(values.length, MAX_COLORS)
				const colors = distinctColors({
					count,
					chromaMin: 30,
					chromaMax: 80,
					lightMin: 35,
					lightMax: 80,
				}).map((chroma) => chroma.hex())

				this.cache[property] = values.reduce((map, val, i) => {
					map[val] = colors[i % count]

					return map
				}, {})
			} else {
				this.cache[property] = {}
			}
		}

		return this.cache[property][value]
	}
}
