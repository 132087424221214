import { mapActions, mapGetters, mapState } from 'vuex'

/**
 * @template {any} State
 * @template {GettersFor<State>} Getters
 * @template {MutationsFor<State>} Mutations
 * @template {ActionsFor<Mutations, State>} Actions
 * @param {string} path
 * @param {{state: State, getters: Getters, mutations: Mutations, actions: Actions}} store
 */
export function createStoreUtils(path, store) {
  return {
    ...store,
    /**
     * @template {Record<string, (state: State) => any>} Mappings
     * @param {Mappings} mappings
     * @returns {{[K in keyof Mappings]: ReturnType<Mappings[K]>}}
     */
    mapState(mappings) {
      return mapState(path, mappings)
    },
    /**
     * @template {Record<string, keyof Getters>} Mappings
     * @param {Mappings} mappings
     * @returns {{[K in keyof Mappings]: ReturnType<Getters[Mappings[K]]>}}
     */
    mapGetters(mappings) {
      return mapGetters(path, mappings)
    },
    /**
     * @template {Record<string, keyof Actions>} Mappings
     * @param {Mappings} mappings
     */
    mapActions(mappings) {
      return mapActions(path, mappings)
    },
    /**
     * @template {Record<string, AccessorsFor<Getters, Actions>>} Mappings
     * @param {Mappings} mappings
     * @returns {{[K in keyof Mappings]: {get(): ReturnType<Getters[Mappings[K]]>, set(newValue: Parameters<Actions[Mappings[K]]>[0]): void}}}
     */
    mapAccessors(mappings) {
      return Object.fromEntries(
        Object.entries(mappings).map(([key, variableName]) => {
          return [
            key,
            {
              get() {
                return this.$store.getters[`${path}/${variableName}`]
              },

              set(newValue) {
                this.$store.commit(
                  `${path}/set${capitalizeFirstLetter(variableName)}`,
                  newValue,
                )
              },
            },
          ]
        }),
      )
    },
  }
}

/**
 * @param {string} string
 */
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
