<template>
	<div class="survey">
		<survey-header :cant-save="Boolean(cantSave)" />
		<div v-if="!isLoadingSurveyData" :class="`column-count-${columnCount} survey-content`">
			<survey-actions
				:name-values="surveyTemplate.keyField.allowedValues"
				:width="width"
				:height="175"
				:selected-systems="selectedSystems"
				:editing-system="editingSystem"
				@cancelEditingSystem="cancelEditingSystem()"
				@filteredSystems="setFilteredSystems($event)"
			/>
			<survey-table
				:width="width"
				:selected-systems="selectedSystems"
				:column-count="columnCount"
				:left-disabled="leftDisabled"
				:right-disabled="rightDisabled"
				@left="goLeft()"
				@right="goRight()"
				@editSystem="editSystem($event)"
			/>
		</div>
		<div v-else class="survey-content-loading">
			<v-progress-circular
				:size="70"
				:width="7"
				color="primary"
				indeterminate
				class="survey-loading"
			/>
		</div>
		<div class="alert-fixed-container">
			<v-alert
				v-if="activeUsers.length"
				dense
				border="left"
				type="warning"
				:value="true"
				:dismissible="true"
				style="margin: 0"
			>
				<p style="color: black">
					<strong>Warning:</strong> These other users have the editor open and
					will overwrite any changes you make when they save:
				</p>
				<ul style="list-style-type: initial; color: black">
					<li v-for="u in activeUsers" :key="u.email">
						{{ `${u.firstName} ${u.lastName} (${u.email})` }}
					</li>
				</ul>
			</v-alert>
		</div>
	</div>
</template>

<script>
import { debounce } from 'lodash/fp'
import { mapActions, mapGetters, mapState } from 'vuex'
import SurveyActions from '../../components/user/survey/SurveyActions.vue'
import SurveyHeader from '../../components/user/survey/SurveyHeader.vue'
import SurveyTable from '../../components/user/survey/SurveyTable.vue'

const SIDE_WIDTH = 150
const ARROW_WIDTH = 36
const MIN_COLUMN_WIDTH = 200
const MARGIN_WIDTH = 20
const RESIZE_DEBOUNCE_TIME = 100 /* Ms */
const MAX_COLUMNS = 10
const ARROW_COUNT = 2
const ARROW_MEANING = {
	left: -1,
	right: 1,
}

// From https://stackoverflow.com/questions/13382516/getting-scroll-bar-width-using-javascript
// eslint-disable-next-line max-statements
const getScrollbarWidth = () => {
	const outer = document.createElement('div')

	outer.style.visibility = 'hidden'
	outer.style.width = '100px'
	outer.style.msOverflowStyle = 'scrollbar' // Needed for WinJS apps

	document.body.appendChild(outer)

	const widthNoScroll = outer.offsetWidth

	// Force scrollbars
	outer.style.overflow = 'scroll'

	// Add inner div
	const inner = document.createElement('div')

	inner.style.width = '100%'
	outer.appendChild(inner)

	const widthWithScroll = inner.offsetWidth

	// Remove divs
	outer.parentNode.removeChild(outer)

	return widthNoScroll - widthWithScroll
}

export default {
	name: 'SurveyItem',
	components: { SurveyHeader, SurveyActions, SurveyTable },
	props: {
		id: {
			type: String,
			required: true,
		},
		selectedBusinessUnitId: {
			type: String,
			require: false,
			default: null,
		},
	},
	data() {
		return {
			isLoadingSurveyData: true,
			columnCount: 1,
			currentPage: 0,
			filteredSystems: [],
			width: 1,
			scrollbarWidth: getScrollbarWidth(),
			editingSystem: null,
			intervalId: null
		}
	},
	computed: {
		...mapState({
			survey: (state) => state.userSurveys.survey,
			surveyTemplate: (state) => state.userSurveys.surveyTemplate,
			editorOpenUsers: (state) => state.userSurveys.survey ? state.userSurveys.survey.activeUsers : [],
			currentTaxonomyVersionId: (state) => state.userSurveys.currentTaxonomyVersionId,
		}),
		...mapGetters('userSurveys', {
			isReadOnly: 'isReadOnly',
			cantSave: 'cantSave',
		}),
		...mapGetters('login', {
			user: 'currentUser',
		}),
		activeUsers() {
			return this.editorOpenUsers.filter((u) => u.email !== this.user.email)
		},
		selectedSystems() {
			const startColumn = this.currentPage * this.columnCount

			return this.filteredSystems.slice(
				startColumn,
				startColumn + this.columnCount,
			)
		},
		pageCount() {
			return Math.ceil(this.filteredSystems.length / this.columnCount)
		},
		leftDisabled() {
			return (
				this.clampPage(this.currentPage + ARROW_MEANING.left) ===
				this.currentPage
			)
		},
		rightDisabled() {
			return (
				this.clampPage(this.currentPage + ARROW_MEANING.right) ===
				this.currentPage
			)
		},
	},
	watch: {
		cantSave() {
			this.$emit('updateCantSave', this.cantSave)
		},
	},
	created() {
		// This is to make it execute after the destroyed of the survey it replaces
		setTimeout(() => {
			window.addEventListener('beforeunload', this.beforeUnloading);
			this.loadSurveyData();
		});
	},
	destroyed() {
		window.removeEventListener('resize', this.setSizeDebounced)
		window.removeEventListener('beforeunload', this.beforeUnloading)
		document.documentElement.classList.remove('for-survey')
	},
	mounted() {
		this.saveEditingStatusInDb()
		this.intervalId = setInterval(() => this.saveEditingStatusInDb(), 60000);
	},
	beforeDestroy() {
		clearInterval(this.intervalId)
	},
	methods: {
		...mapActions('ontology', {
			userEditingSurvey: 'userEditingSurvey',
		}),
		beforeUnloading(ev) {
			if (!this.cantSave) {
				ev.preventDefault()
				ev.returnValue = 'You have unsaved changes'
			}
		},
		/* eslint-disable no-magic-numbers */
		setSize() {
			this.columnCount = Math.min(
				Math.floor(
					(window.innerWidth -
						SIDE_WIDTH -
						ARROW_COUNT * ARROW_WIDTH -
						2 * MARGIN_WIDTH) /
						MIN_COLUMN_WIDTH,
				),
				MAX_COLUMNS,
			)
			this.width = window.innerWidth - 2 * MARGIN_WIDTH
			this.currentPage = 0
		},
		// eslint-disable-next-line prefer-arrow-callback
		setSizeDebounced: debounce(RESIZE_DEBOUNCE_TIME, function () {
			// eslint-disable-next-line no-invalid-this
			this.setSize()
		}),
		goLeft() {
			this.currentPage = this.clampPage(this.currentPage + ARROW_MEANING.left)
		},
		goRight() {
			this.currentPage = this.clampPage(this.currentPage + ARROW_MEANING.right)
		},
		setFilteredSystems({ list, goToLastPage, stayOnPage }) {
			this.filteredSystems = list
			this.currentPage = this.clampPage(
				stayOnPage ? this.currentPage : goToLastPage ? list.length : 0,
			)
		},
		clampPage(page) {
			return Math.min(Math.max(page, 0), this.pageCount - 1)
		},
		editSystem(system) {
			this.editingSystem = system
		},
		cancelEditingSystem() {
			this.editingSystem = null
		},
		async loadSurveyData() {
			try {
				await this.$store.dispatch('userSurveys/loadTaxonomyVersionForSurvey', {
					id: this.id,
				});
				await this.$store.dispatch('userSurveys/loadSurvey', {
					id: this.id,
					businessUnitId: parseInt(this.selectedBusinessUnitId, 10),
					taxonomyVersionId: this.currentTaxonomyVersionId,
				});
			} catch (error) {
				console.error("Failed to load survey data:", error);
			} finally {
				window.addEventListener('resize', this.setSizeDebounced);
				this.setSize();
				document.documentElement.classList.add('for-survey');
				this.isLoadingSurveyData = false;
			}
		},
		saveEditingStatusInDb () {
			this.userEditingSurvey({
				surveyTemplateId: this.id,
				businessUnitId: this.selectedBusinessUnitId,
			})
		}
	},
}
</script>

<style lang="scss">
@import '../../assets/scss/pages/user/survey/index';

.alert-fixed-container {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 1000;
	margin: 0;
}
</style>
