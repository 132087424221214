<template>
	<div ref="autocompleteField" :class="{ active: active }" class="autocomplete">
		<input
			v-model="search"
			:class="{ header: header }"
			type="text"
			class="autocomplete-input"
			@input="onChange"
			@focus="onFocus"
			@blur="(e) => onBlur(e)"
			@keydown.down="onArrowDown"
			@keydown.up="onArrowUp"
			@keydown.enter="onEnter"
		>
		<ul
			v-show="isOpen"
			id="autocomplete-results"
			:class="{ 'drop-up': offScreen }"
			class="autocomplete-results"
		>
			<li v-if="isLoading" class="loading">
				Loading results...
			</li>
			<li
				v-for="(result, i) in results"
				v-else
				:key="i"
				:class="{ 'is-active': i === arrowCounter }"
				class="autocomplete-result"
				@click="setResult(result)"
			>
				{{ result }}
			</li>
		</ul>
		<div v-if="isStrict && !active" class="arrow-down" />
		<div v-if="isStrict && active" class="arrow-up" />
	</div>
</template>

<script>
export default {
	name: 'AutoComplete',

	props: {
		items: {
			type: Array,
			required: false,
			default: () => [],
		},
		isAsync: {
			type: Boolean,
			required: false,
			default: false,
		},
		value: {
			type: String,
			required: false,
			default: '',
		},
		isStrict: {
			type: Boolean,
			required: true,
			default: false,
		},
		header: {
			type: Boolean,
			required: true,
			default: false,
		},
	},

	data() {
		return {
			isOpen: false,
			isLoading: false,
			search: this.value,
			results: [],
			arrowCounter: 0,
			active: false,
			windowHeight: 0,
			offScreen: false,
		}
	},
	watch: {
		items(val, oldValue) {
			// actually compare them
			if (val.length !== oldValue.length) {
				this.results = val
				this.isLoading = false
			}
		},
		value(value) {
			this.search = value
		},
	},
	mounted() {
		document.addEventListener('click', this.handleClickOutside)
		this.handleResize()
	},
	destroyed() {
		document.removeEventListener('click', this.handleClickOutside)
		window.removeEventListener('resize', this.handleResize)
	},
	created() {
		window.addEventListener('resize', this.handleResize)
	},

	methods: {
		onChange() {
			// Let's warn the parent that a change was made
			// this.$emit('input', this.search)

			// Is the data given by an outside ajax request?
			if (this.isAsync) {
				this.isLoading = true
			} else {
				// Let's  our flat array
				this.filterResults()
				this.isOpen = true
			}
		},

		onFocus() {
			this.$emit('focus')
			this.isOffScreen()
			this.active = true

			if (!this.isStrict) {
				this.filterResults()
				this.isOpen = true
			} else {
				this.results = this.items
				this.isOpen = true
			}
		},

		filterResults() {
			// first uncapitalize all the things
			this.results = this.items.filter((item) => {
				return item.toLowerCase().indexOf(this.search.toLowerCase()) > -1
			})
		},
		setResult(result) {
			this.search = result

			this.isOpen = false
			this.active = false
			this.$emit('select', this.search)
		},
		onArrowDown() {
			if (this.arrowCounter < this.results.length) {
				this.arrowCounter += 1
			}
		},
		onArrowUp() {
			if (this.arrowCounter > 0) {
				this.arrowCounter -= 1
			}
		},
		onEnter() {
			this.handleStrictInput()

			if (this.arrowCounter > -1) {
				this.search = this.results[this.arrowCounter]
			}

			this.isOpen = false
			this.arrowCounter = -1
			this.$emit('select', this.search)
		},
		handleClickOutside(evt) {
			if (!this.$el.contains(evt.target)) {
				this.isOpen = false
				this.active = false
				this.arrowCounter = -1
			}
		},
		onBlur(e) {
			if (
				Boolean(e.relatedTarget)
				&& !this.$refs.autocompleteField.contains(e.relatedTarget)
			) {
				this.active = false
				this.isOpen = false
			}

			this.handleStrictInput()
			this.$emit('select', this.search)
		},
		handleStrictInput() {
			if (this.isStrict) {
				if (!this.items.includes(this.search) && this.search !== '') {
					this.search = this.value
				}
			}
		},
		isOffScreen() {
			const offScreenHeight = 240

			this.offScreen = this.windowHeight
				- this.$el.getBoundingClientRect().bottom < offScreenHeight
		},
		handleResize() {
			this.windowHeight = window.innerHeight
		},
	},
}
</script>

<style lang="scss">
.autocomplete {
	position: absolute;
}

.arrow-down {
	width: 0px;
	height: 0px;
	border-left: 4px solid transparent;
	border-right: 4px solid transparent;

	border-top: 4px solid $blue-light;
	margin-left: 90%;
	margin-top: -25px;
}

.arrow-up {
	width: 0;
	height: 0;
	border-left: 4px solid transparent;
	border-right: 4px solid transparent;

	border-bottom: 4px solid $blue-light;
	margin-left: 90%;
	margin-top: -25px;
}

.autocomplete-input {
	height: 95%;
	padding-left: 10px;
}

.autocomplete-input.header {
	width: 90% !important;
	height: 95%;
	padding-left: 10px;
}

.autocomplete {
	border: 2px solid transparent;
}

.autocomplete.active {
	border: 2px solid $blue-light;
}
.autocomplete-results {
	max-height: 200px;
	padding: 0;
	font-size: 16px;
	border: 1px solid #eeeeee;
	max-height: 200px;
	overflow: auto;
	width: 100%;
	position: absolute;
	background-color: white;
	z-index: 100;
	bottom: -5px;
	transform: translateY(100%);
	&.drop-up {
		bottom: auto;
		top: -5px;
		transform: translateY(-100%);
	}
}

.autocomplete-result {
	list-style: none;
	text-align: left;
	padding: 4px 2px;
	cursor: pointer;
}

.autocomplete-result.is-active,
.autocomplete-result:hover {
	background-color: #f0f0f0;
	color: #0f5187;
}
</style>
