<template>
	<div :style="{ width: `${width}px` }" class="survey-table">
		<v-container fluid class="survey-table-header">
			<v-layout>
				<v-flex
					:class="{ mandatory: keyField.isMandatory }"
					justify-center
					class="user-survey-side survey-table-header-side"
				>
					<v-tooltip :disabled="keyField.isMandatory ? false : true" right>
						<span slot="activator">
							{{ keyField.label }} {{ keyField.isMandatory ? '*' : '' }}
						</span>
						<span v-if="keyField.isMandatory"> Mandatory field </span>
					</v-tooltip>
				</v-flex>
				<v-flex
					justify-center
					align-center
					class="user-survey-arrow survey-table-header-arrow"
				>
					<div text-xs-center>
						<v-icon :disabled="leftDisabled" large @click.left="$emit('left')">
							mdi-chevron-left
						</v-icon>
					</div>
				</v-flex>
				<v-flex
					v-for="(system, column) in systems"
					:key="`sys-id-head-${column}`"
					:style="column === 0 ? 'border-left: none;' : ''"
					:class="{
						'read-only-cell': system.readOnly,
						'survey-warning': systemWarning(system),
						blocked: system.dummy,
					}"
					class="user-survey-column survey-table-header-column"
				>
					<div v-if="!system.dummy" class="survey-table-header-column-content">
						<div v-if="!system.valid" class="survey-table-header-column-flag" />
						<input-field
							:read-only="isReadOnly || system.readOnly"
							:value="system.values[keyField.fieldName]"
							:valid="true"
							:warning="false"
							:field="keyField"
							:with-side="true"
							:header="true"
							class="survey-table-header-column-input"
							@input="changeValue(system, keyField, $event)"
						/>
						<button
							v-if="!(isReadOnly || system.readOnly)"
							class="survey-table-header-column-delete-button"
							@click.left="deleteSurveyItem(system)"
						>
							&times;
						</button>
					</div>
				</v-flex>
				<v-flex
					justify-center
					align-center
					class="user-survey-arrow survey-table-header-arrow"
				>
					<div text-xs-center>
						<v-icon
							:disabled="rightDisabled"
							large
							@click.left="$emit('right')"
						>
							mdi-chevron-right
						</v-icon>
					</div>
				</v-flex>
			</v-layout>
		</v-container>
		<div class="survey-table-content">
			<div :style="{ width: `${width}px` }">
				<v-container
					v-for="level in ontologyDepth"
					:key="`lvl-${level}`"
					style="padding: 0"
					fluid
					class="survey-table-content-row"
				>
					<v-layout>
						<v-flex
							v-if="level > 1"
							class="user-survey-side survey-table-content-side"
						>
							Business capability level {{ level }}
						</v-flex>
						<v-flex v-else class="user-survey-side survey-table-content-side">
							Business capability level 1
						</v-flex>
						<v-flex
							class="user-survey-arrow-placeholder survey-table-content-arrow-placeholder"
						/>
						<v-flex
							v-for="(system, column) in systems"
							:key="`sys-id-tab-${column}`"
							:class="{
								'read-only-cell': system.readOnly,
								first: column === 0,
								blocked: system.dummy,
								'survey-warning': !system.ontologyItem && level > 1,
							}"
							class="user-survey-column survey-table-content-column"
						>
							<div :class="{ 'with-pencil': !system.dummy }">
								<input-field
									v-if="!system.dummy"
									:valid="true"
									:read-only="isReadOnly || system.readOnly"
									:header="false"
									:field="{ isFreeText: true }"
									:value="ontologyLevelFor(system, level)"
									@focus="editSystem(system)"
								/>
							</div>
							<v-icon
								v-if="!system.dummy && !isReadOnly"
								color="rgba(0,0,0,.54) !important"
								@click="editSystem(system)"
							>
								mdi-pencil
							</v-icon>
						</v-flex>
						<v-flex
							class="user-survey-arrow-placeholder survey-table-content-arrow-placeholder"
						/>
					</v-layout>
				</v-container>
				<v-container
					v-for="field in fields"
					:key="field.fieldName"
					fluid
					style="padding: 0"
					class="survey-table-content-row"
				>
					<v-layout>
						<v-flex
							slot="activator"
							:class="{ mandatory: field.isMandatory }"
							justify-center
							class="user-survey-side survey-table-content-side"
						>
							<v-tooltip :disabled="field.isMandatory ? false : true" right>
								<span slot="activator">
									{{ field.label }} {{ field.isMandatory ? '*' : '' }}
								</span>
								<span v-if="field.isMandatory"> Mandatory field </span>
							</v-tooltip>
						</v-flex>
						<v-flex
							class="user-survey-arrow-placeholder survey-table-content-arrow-placeholder"
						/>
						<v-flex
							v-for="(system, column) in systems"
							:key="`tab-col-${column}`"
							:class="{
								'read-only-cell': system.readOnly,
								first: column === 0,
								'survey-warning':
									!(system.validValue || {})[field.fieldName] && !system.dummy,
								blocked: system.dummy,
							}"
							class="user-survey-column survey-table-content-column"
						>
							<input-field
								v-if="!system.dummy"
								:read-only="isReadOnly || system.readOnly"
								:valid="system.validValue[field.fieldName]"
								:value="system.values[field.fieldName]"
								:field="field"
								:header="false"
								@input="changeValue(system, field, $event)"
							/>
						</v-flex>
						<v-flex
							class="user-survey-arrow-placeholder survey-table-content-arrow-placeholder"
						/>
					</v-layout>
				</v-container>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import InputField from '../InputField.vue'

export default {
	components: { InputField },
	props: {
		columnCount: {
			type: Number,
			required: true,
		},
		leftDisabled: {
			type: Boolean,
			required: true,
		},
		rightDisabled: {
			type: Boolean,
			required: true,
		},
		selectedSystems: {
			type: Array,
			required: true,
		},
		width: {
			type: Number,
			required: true,
		},
	},
	computed: {
		...mapState({
			surveyTemplate: (state) => state.userSurveys.surveyTemplate,
			survey: (state) => state.userSurveys.survey,
			ontologyDepth: (state) => state.userSurveys.ontology.depth,
			ontologyIndex: (state) => state.userSurveys.ontology.index,
		}),
		...mapGetters('userSurveys', {
			isReadOnly: 'isReadOnly',
		}),
		keyField() {
			return this.surveyTemplate.keyField
		},
		fields() {
			return this.surveyTemplate.fields
		},
		systems() {
			const result = this.selectedSystems.slice(0)

			while (result.length < this.columnCount) {
				result.push({ dummy: true, id: `dummy-${result.length}` })
			}

			return result
		},
	},
	methods: {
		deleteSurveyItem(surveyItem) {
			if (confirm('Are you sure you want to remove this system?')) {
				this.$store.dispatch('userSurveys/deleteSurveyItem', surveyItem)
			}
		},
		changeValue(surveyItem, field, value) {
			this.$store.dispatch('userSurveys/changeValue', {
				surveyItem,
				field,
				value,
			})
		},
		editSystem(system) {
			this.$emit('editSystem', system)
		},
		systemWarning(system) {
			return (
				(!system.valid ||
					!(system.validValue || [])[this.keyField.fieldName] ||
					!system.ontologyItem) &&
				!system.dummy
			)
		},
		ontologyLevelFor(system, level) {
			if (system.ontologyItem) {
				// Ontology is not always of fixed depth so sometimes a level will be missing
				return system.ontologyItem.path[level - 1] ? system.ontologyItem.path[level - 1].name : ''
			} else if (level === 1) {
				return this.ontologyIndex[system.businessUnitId].name
			} else {
				return ''
			}
		},
	},
}
</script>

<style lang="scss">
.read-only-cell {
	opacity: 0.7;
}
.user-survey-column i {
	position: absolute;
	right: 26px;
	top: 50%;
	transform: translateX(50%) translateY(-50%);
	cursor: pointer;
}
.with-pencil {
	position: relative;
	left: -10px;
	width: calc(100% - 20px);
	height: 100%;
}
.with-pencil .survey-input {
	padding-left: 10px;
}
</style>
