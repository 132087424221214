import graphqlClient from '../../api/db'
import gql from 'graphql-tag'
import router from '@/router'

// Initial state
const initialState = {
	currentUser: null,
	loading: true,
}

// Getters
const getters = {
	isAdmin(state) {
		return state.currentUser && state.currentUser.isAdmin
	},
	currentUser(state) {
		return state.currentUser
	},
}

// Actions
const actions = {
	async loginWithPassword({ commit }, { email, password }) {
		const response = await graphqlClient.mutate({
			mutation: gql`
				mutation LogInUser($email: String!, $password: String!) {
					logInUser(email: $email, password: $password) {
						email
						firstName
						lastName
						id
						hasPassword
						isAdmin
						company {
							name
							id
						}
					}
				}
			`,
			variables: {
				email,
				password,
			},
		})

		if (response.data.logInUser) {
			commit('setCurrentUser', response.data.logInUser)

			return true
		} else {
			commit('setCurrentUser', null)

			return false
		}
	},
	async sendLoginEmail(_context, email) {
		const response = await graphqlClient.mutate({
			mutation: gql`
				mutation SendLoginEmail($email: String!) {
					sendLoginEmail(email: $email)
				}
			`,
			variables: {
				email,
			},
		})

		return response.data.sendLoginEmail
	},
	async setPassword(_context, props) {
		const response = await graphqlClient.mutate({
			mutation: gql`
				mutation updateUserPassword($props: UserPasswordSetProps!) {
					updateUserPassword(props: $props)
					{
						id
					}
				}
			`,
			variables: {
				props,
			},
		})

		return response.data.updateUserPassword
	},
	async sendResetPassword(_context, email) {
		const response = await graphqlClient.mutate({
			mutation: gql`
				mutation SendResetPassword($email: String!) {
					sendPasswordResetEmail(email: $email)
				}
			`,
			variables: {
				email,
			},
		})

		return response.data.sendPasswordResetEmail
	},
	async logout({ commit }) {
		await graphqlClient.mutate({
			mutation: gql`
				mutation LogOutUser {
					logOutUser
				}
			`,
		})

		commit('setCurrentUser', null)
		router.push({ name: 'login' }).catch(()=>{})
	},
	async getCurrentUser({ commit }) {
		const response = await graphqlClient.query({
			query: gql`
				query {
					currentUser {
						email
						firstName
						lastName
						isAdmin
						hasPassword
						id
						company {
							name
							id
						}
					}
				}
			`,
		})

		commit('setCurrentUser', response.data.currentUser)

		return response.data.currentUser
	},
	async validateToken(_context, token) {
		const response = await graphqlClient.mutate({
			mutation: gql`
				mutation ValidatePasswordToken($token: String!) {
					validatePasswordToken(token: $token) {
						email
						firstName
						lastName
						isAdmin
						company {
							name
							id
						}
					}
				}
			`,
			variables: {
				token,
			},
		})

		return response.data.validatePasswordToken
	},
	async resetPassword({ commit }, { token, password }) {
		const response = await graphqlClient.mutate({
			mutation: gql`
				mutation SetPassword($token: String!, $password: String!) {
					setPassword(token: $token, password: $password) {
						email
						firstName
						lastName
						isAdmin
						company {
							name
							id
						}
					}
				}
			`,
			variables: {
				token,
				password,
			},
		})

		if (response.data.setPassword) {
			commit('setCurrentUser', response.data.setPassword)

			return true
		} else {
			commit('setCurrentUser', null)

			return false
		}
	},
	async loginByEmail({ commit }, token) {
		const response = await graphqlClient.mutate({
			mutation: gql`
				mutation LoginWithToken($token: String!) {
					logInWithToken(token: $token) {
						email
						firstName
						lastName
						id
						hasPassword
						isAdmin
						company {
							name
							id
						}
					}
				}
			`,
			variables: {
				token,
			},
		})

		if (response.data.logInWithToken) {
			commit('setCurrentUser', response.data.logInWithToken)

			return true
		} else {
			commit('setCurrentUser', null)

			return false
		}
	},
}

// Mutations
const mutations = {
	setCurrentUser(state, user) {
		state.currentUser = user
		state.loading = false
	},
}

export default {
	namespaced: true,
	actions,
	getters,
	mutations,
	state: initialState,
}
