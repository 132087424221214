<template>
	<div class="combobox">
		<dataset-dropdown-filter
			:dataset="dataset"
			:color-cache="colorCache"
			:filter="filter"
			:sort="sort"
			property="Property"
			wide
			@update="update"
		/>
	</div>
</template>

<script>
import DatasetDropdownFilter from './DatasetDropdownFilter'
import { Dataset } from '../../../lib/dataset'
import { ColorCache } from '../../../lib/color_cache'

export default {
	components: { DatasetDropdownFilter },
	props: {
		values: {
			type: Array,
			required: true,
		},
		value: {
			type: String,
			default: null,
		},
		sort: {
			type: Function,
			default: null,
		},
	},
	computed: {
		dataset() {
			return new Dataset(this.values.map((value) => ({ Property: value })))
		},
		colorCache() {
			return new ColorCache(this.dataset)
		},
		filter() {
			return {
				[this.value]: true,
			}
		},
	},
	methods: {
		colorFor(value) {
			return this.colorCache.colorFor(this.property, value)
		},
		update(filter) {
			this.$emit('input', Object.keys(filter)[0])
		},
	},
}
</script>

<style lang="scss" scoped>
.combobox {
	display: inline-block;
	margin-right: 25px;
}
</style>
