var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"survey-actions",style:({ height: `${_vm.height}px`, width: `${_vm.width}px` })},[_c('div',{staticClass:"survey-actions-top"},[_c('p',{staticClass:"user-survey-side survey-actions-top-side"},[_vm._v(" Showing "+_vm._s(_vm.filteredSystems().length)+" of "+_vm._s(_vm.survey.surveyItems.length)+" systems ")]),_c('v-flex',{staticClass:"line-tabs survey-actions-top-tabs",staticStyle:{"text-align":"center","left":"50%"},style:({ width: `calc(100% - ${2 * _vm.sideWidth}px)` })},[_c('v-tabs',{attrs:{"fixed-tabs":"","slider-color":"#105187"},on:{"input":function($event){return _vm.selectTab($event)}},model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},[_c('v-tab',[_vm._v("Filter by business capability")]),_c('v-tab',[_vm._v("Search")]),_c('v-tab',[_c('span',{class:{ incomplete_hl: _vm.incompleteSystemCount }},[_vm._v(" "+_vm._s(_vm.incompleteSystemCount)+" incomplete ("+_vm._s(_vm.incompleteSystemTotalCount)+" total) ")])])],1)],1),(_vm.surveyTemplate.status === 'started')?_c('div',{staticClass:"survey-actions-top-buttons",staticStyle:{"text-align":"right"},style:({ width: `${_vm.sideWidth}px` })},[_c('ontology-browser-dialog',{attrs:{"ontology":_vm.ontology,"systems-by-process-id":_vm.activeProcessIdIndex}}),_c('import-dialog',{attrs:{"ontology":_vm.fullExtendedOntology,"business-unit":_vm.fullExtendedOntology.businessUnit,"survey-template":_vm.surveyTemplate},on:{"import":function($event){return _vm.importSystems($event)}}}),_c('new-system-dialog',{attrs:{"name-values":_vm.nameValues,"selected-ontology-item":_vm.selectedOntologyItem,"ontology":_vm.fullExtendedOntology,"systems-by-process-id":_vm.activeProcessIdIndex,"business-unit":_vm.fullExtendedOntology.businessUnit},on:{"new":function($event){return _vm.addNewSystem($event)}}}),_c('button',{staticClass:"button",class:{
          disabled: _vm.cantSave,
          loading: _vm.isSaving && !_vm.isSavingSubmittedStatus,
        },staticStyle:{"padding":"0 15px","margin-left":"20px"},attrs:{"disabled":_vm.cantSave || !_vm.online},on:{"click":_vm.saveSurvey}},[_vm._v(" Save ")]),_c('button',{staticClass:"button",class:{ loading: _vm.isSavingSubmittedStatus },staticStyle:{"padding":"0 15px","margin-left":"20px"},attrs:{"disabled":_vm.cantChangeSubmittedStatus},on:{"click":_vm.toggleSurvey}},[(_vm.survey.hasBeenSubmitted)?[_vm._v(" Reopen ")]:[_vm._v(" Submit ")]],2)],1):_vm._e()],1),(!_vm.cantSave && _vm.online)?_c('div',{staticClass:"survey-actions-unsaved-message"},[_vm._v(" You have unsaved changes ")]):_vm._e(),(!_vm.online)?_c('div',{staticClass:"survey-actions-unsaved-message"},[_vm._v(" Cannot save changes as you are offline ")]):_vm._e(),_c('v-tabs-items',{staticClass:"survey-actions-tabs",style:({ height: `${_vm.height - _vm.topHeight}px` }),model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},[_c('v-tab-item',{staticClass:"survey-actions-tab"},[_c('v-layout',{staticStyle:{"height":"100%"}},[_c('v-flex',{staticClass:"user-survey-side",staticStyle:{"height":"100%"}}),_c('div',{staticClass:"survey-actions-tab-content"},[_vm._l((_vm.ontologySelects),function(select,i){return [(i > 0)?_c('p',{key:'select-p' + i,staticClass:"survey-actions-tab-slash"},[_vm._v(" / ")]):_vm._e(),_c('v-flex',{key:'select-' + i,staticClass:"survey-actions-tab-select"},[_c('v-select',{attrs:{"items":select.options,"value":select.selected,"item-text":"name","item-value":"id","return-object":""},on:{"input":function($event){return _vm.selectOntologyItem($event)}}})],1)]})],2)],1)],1),_c('v-tab-item',{staticClass:"survey-actions-tab"},[_c('v-container',{style:({
          height: `${_vm.height - _vm.topHeight}px`,
          padding: `${(_vm.height - _vm.topHeight - 40) / 2}px 0`,
        }),attrs:{"fluid":""}},[_c('v-layout',{staticStyle:{"height":"100%"}},[_c('v-flex',{staticClass:"user-survey-side",staticStyle:{"height":"100%"}}),_c('v-flex',{staticStyle:{"text-align":"center"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],staticStyle:{"border":"1px solid grey","padding":"0px 10px","height":"40px","width":"300px","position":"absolute","top":"50%","left":"50%","transform":"translate(-50%, -50%)"},attrs:{"type":"text","placeholder":"Type to search"},domProps:{"value":(_vm.search)},on:{"input":[function($event){if($event.target.composing)return;_vm.search=$event.target.value},function($event){return _vm.updateSearch($event.target.value)}]}})])],1)],1)],1),_c('v-tab-item',{staticClass:"survey-actions-tab"},[_c('v-container',{style:({
          height: `${_vm.height - _vm.topHeight}px`,
          padding: `${(_vm.height - _vm.topHeight - 40) / 2}px 0`,
        }),attrs:{"fluid":""}},[_c('v-layout',{staticStyle:{"height":"100%"}},[_c('v-flex',{staticClass:"user-survey-side",staticStyle:{"height":"100%"}}),_c('div',{staticClass:"survey-actions-tab-incomplete"},[_c('p',{staticClass:"survey-actions-tab-incomplete-text"},[_vm._v(" Below only systems with missing information are shown ")])])],1)],1)],1)],1),_c('validation-errors-dialog',{attrs:{"open":_vm.validationDialogOpen,"invalid-systems":_vm.invalidSystemLabels},on:{"close":function($event){return _vm.closeValidationDialog($event)}}}),_c('submission-validation-dialog',{attrs:{"open":_vm.submissionDialogOpen},on:{"close":function($event){return _vm.closeValidationDialog($event)}}}),_c('business-unit-tree-dialog',{attrs:{"open":_vm.businessUnitTreeDialogOpen,"tree":_vm.extendedOntology.businessUnit,"disabled-trees":_vm.otherBusinessUnits,"ontology-item-index":_vm.extendedOntology.mappedIndex,"systems-by-process-id":_vm.activeProcessIdIndex},on:{"close":_vm.closeBusinessLevelOverview,"selectOntologyItem":function($event){return _vm.goToOntologyItem($event)}}}),_c('edit-system-dialog',{attrs:{"systems-by-process-id":_vm.activeProcessIdIndex,"system":_vm.editingSystem,"ontology":_vm.fullExtendedOntology,"current-business-unit":_vm.ontology.businessUnit,"survey-template":_vm.surveyTemplate},on:{"cancel":function($event){return _vm.cancelEditingSystem()},"update":function($event){return _vm.updateSystemOntologyItem($event)}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }