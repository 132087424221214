<template>
  <v-dialog v-model="dialogIsOpen" max-width="600px" persistent>
    <v-card>
      <v-container grid-list-md>
        <v-layout wrap="">
          <v-card-title>
           <span class="headline">Create mapping file template</span>
          </v-card-title>
          <v-flex xs12>
            <v-text-field
              v-model="depth"
              label="New taxonmy version Depth"
              :min="1"
              :max="10"
              type="number"
              :disabled="!reference"
            ></v-text-field>
            <v-btn
              class="button download-button"
              @click="downloadVersionMappingCsv"
              :disabled="!reference"
            >
              Download mapping file template CSV
            </v-btn>
          </v-flex>
          <v-card-title>
            <span class="headline">Create new taxonomy version</span>
          </v-card-title>
          <v-flex xs12>
            <v-text-field
              v-model="name"
              label="New taxonomy version name"
              :disabled="!reference"
              :error="showNameError && !name"
              :error-messages= "showNameError? nameErrorMessages : []"
            />            
            <input
              ref="fileInput"
              type="file"
              accept=".csv"
              v-on:change="onCsvInputChanged"
              :disabled="!reference"
              class="hidden-file-input"
            />
            <div class="file-upload-container">
              <v-btn
                :disabled="!reference"
                @click="triggerFileInput"
                class="button download-button"
              >
                Upload mapping file CSV
              </v-btn>
              <span v-if="fileName" class="file-name">{{ fileName }}</span>
            </div>
          </v-flex>
          <v-flex>
            <div class="actions">
              <v-btn @click="onCancel()">Cancel</v-btn>
              <v-btn @click="submit" :disabled="!mappings">Create</v-btn>
            </div>
            <div class="feedback" v-if="feedback?.length">
              <h2 style="padding: 0 25px; margin-bottom: 10px">
                File validation
              </h2>
              <div
                class="feedback-record"
                :class="`feedback-${entry.severity}`"
                v-for="entry in feedback"
                :key="`${entry.row}${entry.message}`"
              >
                <div class="feedback-row">
                  <template v-if="entry.row === null">General</template>
                  <template v-else-if="entry.row === 0">Header</template>
                  <template v-else>Row {{ entry.row }}</template>
                </div>
                <div>
                  {{ entry.message }}
                </div>
              </div>
            </div>
            <div class="success" v-if="mappings">
              Valid! {{ mappings.length }} items
            </div>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { createNewTaxonomyVersionStore } from '../../../store/modules/admin/dialog/create-new-taxonomy-version'

export default {
  data() {
    return {
      fileName: null,
      showNameError: false,
    }
  },
  name: 'CreateNewTaxonomyVersionDialog',
  computed: {
    ...createNewTaxonomyVersionStore.mapGetters({}),
    ...createNewTaxonomyVersionStore.mapState({
      reference: (state) => state.reference,
      feedback: (state) => state.feedback,
      mappings: (state) => state.mappings,
    }),
    ...createNewTaxonomyVersionStore.mapAccessors({
      dialogIsOpen: 'dialogIsOpen',
      name: 'name',
      depth: 'depth',
    }),
    nameErrorMessages() {
      return this.name ? [] : ['This field is required'];
    },
  },

  methods: {
    ...createNewTaxonomyVersionStore.mapActions({
      showDialog: 'setDialogIsOpen',
      validateVersionMappingCsv: 'validateVersionMappingCsv',
      downloadVersionMappingCsv: 'downloadVersionMappingCsv',
      createTaxonomyVersion: 'createTaxonomyVersion',
    }),

    /** @param {InputEvent & {target: HTMLInputElement}} event */
    onCsvInputChanged(event) {
      if (!event.target.files?.length) return

      const file = event.target.files[0]
      if (file) {
        this.fileName = file.name;
        const reader = new FileReader()
        reader.onload = (e) => {
          if (!e.target) return
          this.validateVersionMappingCsv({ body: e.target.result })
        }
        reader.readAsText(file)
      }
      else{
        this.fileName = null;
      }
    },
    clearFileInput() {
      if (this.$refs.fileInput) {
        this.$refs.fileInput.value = ''; // Clear the file input
      }
    },
    onCancel() {
      this.clearFileInput()
      this.showDialog(false)
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },

    async submit() {
      this.showNameError = true;
      await this.createTaxonomyVersion({
        name: this.name,
      })
      // Reload the current page if there was no error feedback
      if (!this.feedback?.some(fb => fb.severity == "error")) {
      this.$router.go();
      }
    },
  },
}
</script>

<style>
.download-button {
  margin-top: 15px;
  margin-bottom: 50px !important;
}

.hidden-file-input{
  display:none;
}

.file-name{
  margin-top: 10px;
  margin-left: 10px;
  font-size: 14px;
}

.file-upload-container {
  display: flex;
}

.feedback {
  margin-top: 25px;
  overflow-y: auto;
  margin-bottom: 25px;
}

.success {
  margin-top: 15px;
  margin-bottom: 30px;
}

.feedback-record {
  display: flex;
  padding: 0 10px;
}

.feedback-row {
  font-weight: bold;
  flex-basis: 75px;
  flex-shrink: 0;
  margin-right: 10px;
}

.feedback-error {
  color: red;
}
.feedback-info {
  color: dodgerblue;
}
.feedback-warning {
  color: sienna;
}

.actions {
  display: flex;
  justify-content: space-between;
}
</style>
